import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {
  moreServiceData,
  moreServicePlaygroundData,
  playgroundData
} from "../../interfaces/interfaces";
import {angularClassDecoratorKeys} from "codelyzer/util/utils";

@Component({
  selector: 'app-playground-edit',
  templateUrl: './playground-edit.component.html',
  styleUrls: ['./playground-edit.component.scss']
})
export class PlaygroundEditComponent implements OnInit {

  @Input() id_playground: number = 0;
  @Output() saveAnswer = new EventEmitter<string>();

  public bLoadingData: boolean = false;
  public oPlayground: playgroundData = this.prenotatennisService.getNewPlayground();
  public aService: Array<moreServiceData> = [];
  public aServicePlayground: Array<any> = [];

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    if(this.id_playground !== 0 && this.prenotatennisService.userData.level == 'admin') {
      this.getPLaygroundListData();
    }

    this.getServiceListData();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getPLaygroundListData() {

    this.bLoadingData = true;

    this.prenotatennisService.getPlaygroundListDB(false, this.prenotatennisService.userData.token, this.id_playground).subscribe(get_response => {

        if(get_response.playground && get_response.playground.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i campi' + ': ' + get_response.playground.error);
        } else {
          this.oPlayground = get_response.playground.data[0];
          this.prenotatennisService.getNewPGPrice(this.oPlayground.start_booking, this.oPlayground.end_booking, this.id_playground);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  savePlayground(){
    if(this.oPlayground.id == 0) this.insertPlayground();
    if(this.oPlayground.id !== 0) this.updatePlayground();
  }

  insertPlayground(){

    this.bLoadingData = true;

    this.prenotatennisService.postInsertPlaygroundDB(this.oPlayground, this.prenotatennisService.userData.token).subscribe(insert_response => {

        if(insert_response.playground && insert_response.playground.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il campo: ' + insert_response.playground.error);
        } else {
          this.openDialog('success', 'Campo creato con successo!');
          this.oPlayground.id = insert_response.playground.id;
          this.id_playground = insert_response.playground.id;
          this.saveAnswer.emit('RELOAD');
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  updatePlayground(){

    this.bLoadingData = true;

    this.prenotatennisService.postUpdatePlaygroundDB(this.oPlayground, this.prenotatennisService.userData.token).subscribe(update_response => {

        if(update_response.playground && update_response.playground.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il campo: ' + update_response.playground.error);
        } else {
          this.openDialog('success', 'Campo aggiornato con successo!');
          this.saveAnswer.emit('RELOAD');
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  deletePlayground(){

    this.bLoadingData = true;

    this.prenotatennisService.postDeletePlaygroundDB(this.oPlayground, this.prenotatennisService.userData.token).subscribe(delete_response => {

        if(delete_response.playground && delete_response.playground.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile eliminare il campo: ' + delete_response.playground.error);
        } else {
          this.openDialog('success', 'Campo eliminato con successo!');
          this.saveAnswer.emit('CLOSE');
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  /************************************************/
  /***** MORE SERVICE FUNCTIONS *******************/
  /************************************************/

  getPGServiceListData() {

    this.bLoadingData = true;

    this.prenotatennisService.getMoreServicePGListDB(this.id_playground, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.service && get_response.service.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i servizi attivi' + ': ' + get_response.service.error);
        } else {
          this.aServicePlayground = get_response.service;

          this.aService.map(serv => {

            serv.active = false;
            serv.id_activation = 0;

            let servActive =  this.aServicePlayground.filter(x => x.id_service == serv.id);

            if (servActive && servActive.length > 0){
              serv.active = true;
              serv.id_activation = servActive[0].id;
            }

          });
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  getServiceListData() {

    this.bLoadingData = true;

    this.prenotatennisService.getMoreServiceListDB(false, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.service && get_response.service.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i servizi' + ': ' + get_response.service.error);
          this.bLoadingData = false;
        } else {
          this.aService = get_response.service;
          this.bLoadingData = false;
          if(this.id_playground !== 0) this.getPGServiceListData();
        }

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  changeStatusService(oService: moreServiceData) {

    let oMoreServicePG: moreServicePlaygroundData = {
      id: oService.id_activation,
      id_playground: this.id_playground,
      id_service: oService.id,
      booking_qnt: 1,
      booking_active: false
    }

    if(oService.active) this.deletePGService(oMoreServicePG);
    if(!oService.active) this.insertPGService(oMoreServicePG);

  }

  insertPGService(oMoreServicePG: moreServicePlaygroundData){

    this.bLoadingData = true;

    this.prenotatennisService.postInsertPGServiceDB(oMoreServicePG, this.prenotatennisService.userData.token).subscribe(insert_response => {

        if(insert_response.service && insert_response.service.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile aggiungere il servizio: ' + insert_response.service.error);
          this.bLoadingData = false;
        } else {
          this.getPLaygroundListData();
          this.getServiceListData();
        }

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  deletePGService(oMoreServicePG: moreServicePlaygroundData) {

    this.bLoadingData = true;

    this.prenotatennisService.postDeletePGServiceDB(oMoreServicePG, this.prenotatennisService.userData.token).subscribe(delete_response => {

        if(delete_response.service && delete_response.service.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile eliminare il servizio: ' + delete_response.service.error);
          this.bLoadingData = false;
        } else {
          this.getServiceListData();
        }

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );


  }

}
