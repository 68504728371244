<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header bg-primary text-white">
  <h4>Vincoli sulle prenotazioni</h4>
</div>

<div class="modal-body">

  <div class="row">
    <div class="col-12">
      <p>Un socio nelle date definite e negli orari definiti non può fare più di due prenotazioni in una settimana</p>
    </div>

    <div class="col-12 col-md-6 mt-2">
      <label>Inizio vincoli</label>
      <input type="date" class="form-control" [(ngModel)]="oManagebleSettings.constraints_date_start">
    </div>
    <div class="col-12 col-md-6 mt-2">
      <label>Fine vincoli</label>
      <input type="date" class="form-control" [(ngModel)]="oManagebleSettings.constraints_date_end">
    </div>

    <div class="col-12 col-md-6 mt-3">
      <label>Ora inzio</label>
      <input type="time" class="form-control" [(ngModel)]="oManagebleSettings.constraints_hour_start">
    </div>
    <div class="col-12 col-md-6 mt-3">
      <label>Ora fine</label>
      <input type="time" class="form-control" [(ngModel)]="oManagebleSettings.constraints_hour_end">
    </div>

    <div class="col-12 mt-4">
      <div><label>Giorni della settimana</label></div>
      <div class="form-check form-check-inline" *ngFor="let day of aConstaintDays; let i = index">
        <input class="form-check-input" type="checkbox" id="cb{{i}}" [checked]="day.value" (click)="changeStatusCB(day)">
        <label class="form-check-label" for="cb{{i}}">{{day.description}}</label>
      </div>
    </div>

  </div>

</div>

<div class="modal-footer">
  <button class="btn btn-success" (click)="updateSettings()">Salva modifiche</button>
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('')">CHIUDI</button>
</div>

