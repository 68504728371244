<app-loader *ngIf="bLoadingData"></app-loader>

<div class="row my-3">
  <div class="col-12 col-md-3 m-auto d-flex">
    <button class="btn flex-grow-1 btn-success" (click)="editService(0)"><span class="fa fa-plus me-1"></span>Nuovo servizio</button>
  </div>
</div>

<div class="row mt-4 pb-5">
    <div class="col-12 col-md-4 pointer" *ngFor="let moreserv of aService">
      <div class="alert text-center" (click)="editService(moreserv.id)"
           [class]="{'alert-success': moreserv.status, 'alert-danger': !moreserv.status}"
      >
        <h4>{{ moreserv.title }}</h4>
        {{ moreserv.description }} - {{ moreserv.price | number: '1.2-2' }} €
      </div>
    </div>
</div>

<div class="pb-5">
  <!-- Space for menu bug fix -->
</div>
