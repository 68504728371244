<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header bg-primary text-white">
  <h4>Definizione estate / inverno</h4>
</div>

<div class="modal-body">

  <div class="row mb-3">
    <div class="col-12">
      <h4>Inverno</h4>
    </div>
    <div class="col-12 col-md-6 mt-2">
      <label>Inizio</label>
      <input type="date" placeholder="" class="form-control" [(ngModel)]="oManagebleSettings.winter_start">
    </div>
    <div class="col-12 col-md-6 mt-2">
      <label>Fine</label>
      <input type="date" placeholder="" class="form-control" [(ngModel)]="oManagebleSettings.winter_end">
    </div>
  </div>

  <div class="row mt-2 pt-3 border-top">
    <div class="col-12">
      <h4>Estate</h4>
    </div>
    <div class="col-12 col-md-6 mt-2">
      <label>Inizio</label>
      <input type="date" placeholder="" class="form-control" [(ngModel)]="oManagebleSettings.summer_start">
    </div>
    <div class="col-12 col-md-6 mt-2">
      <label>Fine</label>
      <input type="date" placeholder="" class="form-control" [(ngModel)]="oManagebleSettings.summer_end">
    </div>
  </div>

</div>

<div class="modal-footer">
  <button class="btn btn-success" (click)="updateSettings()">Salva</button>
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('')">CHIUDI</button>
</div>
