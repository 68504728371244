import {Component, Input, OnInit} from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {blacklistData, playerData} from "../../interfaces/interfaces";
import {DatePipe, formatDate} from "@angular/common";
import {PlayerListComponent} from "../player-list/player-list.component";

@Component({
  selector: 'app-blacklist-edit',
  templateUrl: './blacklist-edit.component.html',
  styleUrls: ['./blacklist-edit.component.scss']
})
export class BlacklistEditComponent implements OnInit {

  @Input() id_user: number = 0;

  public bLoadingData: boolean = false;
  public selectedPlayerID: number = 0;

  public aBlackList: Array<blacklistData> = [];
  public aAvailableUser: Array<playerData> = [];

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.getBookingAvailableUser();
    this.getBlackListData();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getBlackListData() {

    this.bLoadingData = true;

    this.prenotatennisService.getBlackListListDB(this.id_user, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.blacklist && get_response.blacklist.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile recuperare la black list' + ': ' + get_response.blacklist.error);
          this.aBlackList = [];
        } else {

          this.aBlackList = get_response.blacklist.map((x: blacklistData) => {
            x.date_ins = x.date_ins? x.date_ins.replace(" ", "T").toString(): "";
            return x;
          });

        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        this.aBlackList = [];
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  getBookingAvailableUser() {

    let todayDate: any = new DatePipe('en-EN').transform(Date.now(), 'yyyy-MM-dd');

    this.prenotatennisService.getBookingAvailableUserDB(todayDate.toString(), this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.users && get_response.users.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare la lista utenti' + ': ' + get_response.users.error);
          this.bLoadingData = false;
        } else {
          this.aAvailableUser = get_response.users;
        }

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto: ' + error);
      });

  }

  openPlayerList(){
    const modalRef = this.modalService.open(PlayerListComponent, { size: 'xl' });
    modalRef.componentInstance.pladate_play = null;
    modalRef.componentInstance.playerIDAnswer.subscribe((confirmation: number) => {
      if(confirmation) {
        this.insertBlackList(confirmation);
      }
    });
  }

  insertBlackList(id_user_block: number) {

    this.bLoadingData = true;

    let oBlackList: blacklistData = {
      id: 0,
      id_user: this.prenotatennisService.userData.id,
      id_user_block: id_user_block,
    }

    this.prenotatennisService.postInsertBlackListDB(oBlackList, this.prenotatennisService.userData.token).subscribe(insert_response => {

        if(insert_response.blacklist && insert_response.blacklist.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile bloccare il giocatore: ' + insert_response.blacklist.error);
        } else {
          this.getBlackListData();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  deleteBlackList(oBlacklist: blacklistData) {

    this.bLoadingData = true;

    this.prenotatennisService.postDeleteBlackListDB(oBlacklist, this.prenotatennisService.userData.token).subscribe(insert_response => {

        if(insert_response.blacklist && insert_response.blacklist.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile rimuovere il blocco al giocatore: ' + insert_response.blacklist.error);
        } else {
          this.getBlackListData();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  getLevelDescription(player_level: number) {
    let findLevel: Array<any> = [];
    findLevel = this.prenotatennisService.settingsData.player_levels.filter((x: any) => x.id === player_level);
    if(findLevel.length > 0) return findLevel[0].description;
    return "n.a."
  }

}
