import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: any): string {
    if(!value) return '';

    // Assume che la stringa abbia sempre il formato "HH:mm:ss"
    const parts = value.split(':');
    if (parts.length !== 3) {
      return value;
    }

    // Estrai solo le ore e i minuti
    const hours = parts[0];
    const minutes = parts[1];

    return `${hours}:${minutes}`;

  }

}
