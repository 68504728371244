import { Component, OnInit } from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-partner-profile',
  templateUrl: './partner-profile.component.html',
  styleUrls: ['./partner-profile.component.scss']
})
export class PartnerProfileComponent implements OnInit {

  constructor(public prenotatennisService: PrenotatennisService,
              private router: Router,) { }

  ngOnInit(): void {
    if(this.prenotatennisService.userData.id == 0) this.router.navigate(['home']);
  }

}
