<div class="text-center pb-5 p-2">
  <div class="small">
    Copyright 2017-{{currentYear | date: 'YYYY'}}
    {{prenotatennisService.settingsData.club_name}}
  </div>
  <div class="small mt-1">
    <!--<a class="link-primary me-2" href="{{prenotatennisService.settingsData.club_web}}" target="_blank">{{prenotatennisService.settingsData.club_name}}</a>-->
    <a class="link-primary me-2" href="{{prenotatennisService.settingsData.privacy_link}}" target="_blank">Privacy Policy</a>
    P.IVA {{prenotatennisService.settingsData.club_vat}}
  </div>
  <p class="small mt-1">
    v{{appVersion}} {{appRevision}}-{{appBuilding}}
  </p>
  <div class="mt-1 text-center">
    <a href="https://www.prenota-tennis.it/" target="_blank"><img class="small_logo" src="./assets/img/madtennis.png" alt="Prenota Tennis by Madsite.eu" /></a>
  </div>
</div>

<div class="pb-5">
  <!-- Space for menu bug fix -->
</div>
