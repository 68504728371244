import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  @Input() type: string = "";
  @Input() message: string = "";
  @Output() questionAnswer = new EventEmitter<boolean>();

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {}

  getConfirmResponse(value: boolean) {
    this.questionAnswer.emit(value);
    this.activeModal.close();
  }

}
