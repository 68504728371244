import { Component, OnInit } from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {Router} from "@angular/router";
import {UserProfileComponent} from "../user-profile/user-profile.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public bLoadingData: boolean = false;

  constructor(public prenotatennisService: PrenotatennisService, private router: Router, private modalService: NgbModal) { }

  ngOnInit(): void {}

  logoutUser() {
    this.bLoadingData = true;
    this.prenotatennisService.bLoggedIn = false;
    this.prenotatennisService.getUserLogoutData();
    this.bLoadingData = false;
    this.router.navigate(['home']);
  }

  editUser() {
    const modalRef = this.modalService.open(UserProfileComponent, { size: 'xl' });
    modalRef.componentInstance.id_user = this.prenotatennisService.userData.id;
    modalRef.componentInstance.edit_type = "profile";
  }

}
