<app-loader *ngIf="bLoadingData"></app-loader>

<div class="row pb-5" >
  <div class="col-12">
    <div class="components mb-4">
      <!--*******************************-->
      <!--**** CALENDAR VIEW ************-->
      <!--*******************************-->
      <div class="direction-arrows mt-3 d-none d-lg-flex justify-content-end">
        <span class="fas fa-circle-chevron-left pointer me-2" (click)="scrollElement('day-list-wrapper', 'left', 300)"></span>
        <span class="fas fa-circle-chevron-right pointer" (click)="scrollElement('day-list-wrapper', 'right', 300)"></span>
      </div>

      <div id="day-list-wrapper" class="day-list-wrapper d-flex mt-3">
        <div class="day-calendar-wrapper me-1" *ngFor="let calendarDay of aCalendarDays">
          <div class="alert alert-primary text-center" [ngClass]="calendarDay.club_closure?'alert-light':calendarDay.play_date==prenotatennisService.memberFilterBooking.date_from?'alert-warning':'alert-primary'" (click)="selectDayToView(calendarDay)">
            {{ getDayDescription(calendarDay.play_date) }}
            <hr>
            <h2>{{calendarDay.play_date | date: 'dd'}}</h2>
            <div>{{calendarDay.play_date | date: 'MMMM'}}</div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <ng-container class="col-12" *ngFor="let pgCalendar of aCalendarData">
          <ng-container>
            <div class="d-flex justify-content-between align-items-center">
              <h3>{{ getDayDescription(prenotatennisService.memberFilterBooking.date_from) }} {{prenotatennisService.memberFilterBooking.date_from | date: 'dd/MM/yyyy'}}</h3>

              <div class="direction-arrows d-none d-lg-flex">
                <span class="fas fa-circle-chevron-left pointer me-2" (click)="scrollElement('playground-list-wrapper', 'left', 300)"></span>
                <span class="fas fa-circle-chevron-right pointer" (click)="scrollElement('playground-list-wrapper', 'right', 300)"></span>
              </div>
            </div>

            <div class="col-12">
              <div id="playground-list-wrapper" class="playground-list-wrapper row flex-nowrap gx-1">

                <div *ngFor="let pg of pgCalendar.playground" class="playground-wrapper col-3 me-1 mb-3">
                  <div class="card-header border p-1">
                    <div class="icon-wrapper">
                      <span>{{pg.pg_name}} </span>
                      <span class="ms-2 small text-muted">{{pg.pg_sport}}</span>
                    </div>
                  </div>
                  <!-- Standard calendar type -->
                  <div class="d-flex flex-row flex-wrap p-2 border">

                    @if (bDayClubClosed) {
                      <div class="btn w-100 flex-grow-1 btn-sm mt-2 me-2" *ngFor="let av_hour of pg.available_hour"
                       [ngbTooltip]="tipContent">
                        <ng-template #tipContent>
                          <div>Dalle {{av_hour.start}} alle {{av_hour.end}}</div>
                        </ng-template>
                        {{av_hour.start}}
                      </div>
                    } @else {
                      <div class="btn w-100 flex-grow-1 btn-sm mt-2 me-2" *ngFor="let av_hour of pg.available_hour"
                           [class]="{'btn-danger': av_hour.close, 'btn-success': av_hour.booked.id_book == 0, 'btn-primary': av_hour.booked.id_book !== 0 && (av_hour.confimed_player == pg.pg_sport_player || av_hour.confimed_player == pg.pg_sport_min_player), 'btn-warning': av_hour.booked.id_book !== 0 && (av_hour.confimed_player !== pg.pg_sport_player && av_hour.confimed_player !== pg.pg_sport_min_player)}"
                           (click)="newBooking(pg.date, pg.pg_id, pg.pg_name, av_hour)"
                           [ngbTooltip]="tipContent">
                        <ng-template #tipContent>
                          <div *ngIf="av_hour.close || av_hour.booked.id_book !== 0">Ora non disponibile</div>
                          <div>Dalle {{av_hour.start}} alle {{av_hour.end}}</div>
                        </ng-template>
                        <i class="fa fa-star text-white" *ngIf="av_hour.booked.id_user == prenotatennisService.userData.id && av_hour.booked.confirmed"></i>
                        <i class="fa fa-triangle-exclamation text-warning" *ngIf="av_hour.booked.id_user == prenotatennisService.userData.id && !av_hour.booked.confirmed"></i>
                        {{av_hour.start}}
                      </div>
                    }
                    </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

    </div>
  </div>
</div>

