import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {managementSettingsData} from "../../interfaces/interfaces";

@Component({
  selector: 'app-settings-season',
  templateUrl: './settings-season.component.html',
  styleUrls: ['./settings-season.component.scss']
})
export class SettingsSeasonComponent implements OnInit {

  @Output() saveAnswer = new EventEmitter<boolean>();

  public bLoadingData: boolean = false;
  public oManagebleSettings: managementSettingsData = this.prenotatennisService.getNewSettingsData();

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.getManagebleSettingsData();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getManagebleSettingsData() {

    this.bLoadingData = true;

    this.prenotatennisService.getManagebleSettingsDB(this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.settings && get_response.settings.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare le impostazioni' + ': ' + get_response.settings.error);
        } else {
          this.oManagebleSettings = get_response.settings[0];
          //this.prenotatennisService.settingsData = get_response.settings[0];
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  updateSettings() {

    this.bLoadingData = true;

    this.prenotatennisService.postUpdateSettingsDB(this.oManagebleSettings, this.prenotatennisService.userData.token).subscribe(update_response => {

        if(update_response.settings && update_response.settings.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile modificare le date: ' + update_response.settings.error);
        } else {
          this.getManagebleSettingsData();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );
  }

}
