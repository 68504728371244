import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-settings-active-constraints',
  templateUrl: './settings-active-constraints.component.html',
  styleUrls: ['./settings-active-constraints.component.scss']
})
export class SettingsActiveConstraintsComponent implements OnInit {

  @Output() saveAnswer = new EventEmitter<boolean>();

  public bLoadingData: boolean = false;
  public oSettings: any = null;

  constructor(public prenotatennisService: PrenotatennisService, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.oSettings = this.prenotatennisService.settingsData;
  }

}
