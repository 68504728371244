import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {moreServiceData, playgroundData} from "../../interfaces/interfaces";
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-more-service-edit',
  templateUrl: './more-service-edit.component.html',
  styleUrls: ['./more-service-edit.component.scss']
})
export class MoreServiceEditComponent implements OnInit {

  @Input() id_service: number = 0;
  @Output() saveAnswer = new EventEmitter<boolean>();

  public bLoadingData: boolean = false;
  public oService: moreServiceData = this.prenotatennisService.getNewMoreService();

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    if(this.id_service !== 0 && this.prenotatennisService.userData.level == 'admin') this.getServiceListData()
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;

    modalRef.componentInstance.questionAnswer.subscribe((reciveEntry: boolean) => {
      if(reciveEntry){
        this.deleteService();
      }
    });

  }

  getServiceListData() {

    this.bLoadingData = true;

    this.prenotatennisService.getMoreServiceListDB(false, this.prenotatennisService.userData.token, this.id_service).subscribe(get_response => {

        if(get_response.service && get_response.service.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare il servizio' + ': ' + get_response.service.error);
        } else {
          this.oService = get_response.service[0];
          console.log(this.oService);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  saveService(){
    if(this.oService.id == 0) this.insertService();
    if(this.oService.id !== 0) this.updateService();
  }

  insertService(){

    this.bLoadingData = true;

    this.prenotatennisService.postInsertServiceDB(this.oService, this.prenotatennisService.userData.token).subscribe(insert_response => {

        if(insert_response.service && insert_response.service.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il servizio: ' + insert_response.service.error);
        } else {
          this.openDialog('success', 'Servizio creato con successo!');
          this.oService.id = insert_response.service.id;
          this.saveAnswer.emit(true);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  updateService(){

    this.bLoadingData = true;

    this.prenotatennisService.postUpdateServiceDB(this.oService, this.prenotatennisService.userData.token).subscribe(update_response => {

        if(update_response.service && update_response.service.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il servizio: ' + update_response.service.error);
        } else {
          this.openDialog('success', 'Servizio aggiornato con successo!');
          this.saveAnswer.emit(true);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  deleteServiceConfirm(){
    this.openDialog('confirm', "Vuoi eliminare in modo definito il servizio?");
  }

  deleteService(){

    this.bLoadingData = true;

    this.prenotatennisService.postDeleteServiceDB(this.oService, this.prenotatennisService.userData.token).subscribe(delete_response => {

        if(delete_response.service && delete_response.service.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile eliminare il servizio: ' + delete_response.service.error);
        } else {
          this.saveAnswer.emit(true);
          this.activeModal.close();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

}
