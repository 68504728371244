import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {closureData, managementSettingsData} from "../../interfaces/interfaces";

@Component({
  selector: 'app-settings-constraints',
  templateUrl: './settings-constraints.component.html',
  styleUrls: ['./settings-constraints.component.scss']
})
export class SettingsConstraintsComponent implements OnInit {

  @Output() saveAnswer = new EventEmitter<boolean>();

  public aConstaintDays: Array<any> = [];
  public bLoadingData: boolean = false;
  public oManagebleSettings: managementSettingsData = this.prenotatennisService.getNewSettingsData();

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.getManagebleSettingsData();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getManagebleSettingsData() {

    this.bLoadingData = true;

    this.prenotatennisService.getManagebleSettingsDB(this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.settings && get_response.settings.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare le impostazioni' + ': ' + get_response.settings.error);
        } else {
          this.oManagebleSettings = get_response.settings[0];
          //this.prenotatennisService.settingsData = get_response.settings[0];
          this.aConstaintDays = [];
          // Get Constraint days
          let aDays = this.oManagebleSettings.constraints_days.split(";");
          let index: number = 0;
          aDays.map((x: string) => {
            if(x !== '') {
              this.aConstaintDays.push({description: this.prenotatennisService.getWeekDayDescription(index), value: x == 'true'});
            }
            index++;
          });
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  changeStatusCB(oItem: any) {
    oItem.value = !oItem.value;
  }

  convertArrayToString(aData: Array<any>) {

    let sValues: string = "";

    aData.map(x => {
      sValues = sValues + x.value.toString() + ";";
    });

    return sValues
  }

  updateSettings() {

    this.bLoadingData = true;

    this.oManagebleSettings.constraints_days = this.convertArrayToString(this.aConstaintDays);

    this.prenotatennisService.postUpdateSettingsDB(this.oManagebleSettings, this.prenotatennisService.userData.token).subscribe(update_response => {

        if(update_response.settings && update_response.settings.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile modificare le date: ' + update_response.settings.error);
        } else {
          this.getManagebleSettingsData();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );
  }

}
