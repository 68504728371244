<app-loader *ngIf="bLoadingData"></app-loader>

<div class="row mt-3">
  <div class="col-12 text-center mt-2">
    <h3>Prenotazioni condivise</h3>
  </div>
  <div class="col-12 text-center">
    <div class="small text-muted">
      Dal {{filter.date_from | date: 'dd/MM/yyyy' }} al {{filter.date_to | date: 'dd/MM/yyyy' }}
    </div>

    <div class="direction-arrows mt-3 d-none d-lg-flex justify-content-end">
      <span class="fas fa-circle-chevron-left pointer me-2" (click)="scrollElement('day-list-wrapper', 'left', 300)"></span>
      <span class="fas fa-circle-chevron-right pointer" (click)="scrollElement('day-list-wrapper', 'right', 300)"></span>
    </div>

    <!-- Day wrapper -->
    <div id="day-list-wrapper" class="day-list-wrapper d-flex mt-3 pointer">
      <div class="day-calendar-wrapper me-1" *ngFor="let selectedDate of aDateList">
        <div class="alert alert-primary text-center" (click)="selectFilterDay(selectedDate)" [ngClass]="selectedDate == selectedDay?'alert-warning':'alert-primary'">
          {{ getDayDescription(selectedDate) }}
          <hr>
          <h2>{{selectedDate | date: 'dd'}}</h2>
          <div>{{selectedDate | date: 'MMMM'}}</div>
        </div>
      </div>
    </div>

  </div>
</div>

<div class="row mt-2 pb-5">
  <div class="col-12 col-md-6">

    <div class="text-secondary text-center mt-3" *ngIf="aBookingList.length == 0">
      <div class="mb-3">
        <i class="fa fa-face-grin-beam-sweat fa-3x"></i>
      </div>
      <span class="fw-bold">Spiacente, ma non ci sono prenotazioni condivise!</span>
    </div>

    <div class="border p-2 col-12 bg-light mb-2" *ngFor="let book of filterBooking(aBookingList)">
      <div class="mt-1 mb-2">
        <img class="plaground_icon me-2" [src]="'assets/img/sport-ico/' + getSportIco(book.id_sport)">
        <span class="text-muted ms-1">Campo: {{ book.playground_name }}</span>
      </div>

      <strong>{{getDayDescription(book.date_play)}} {{ book.date_play | date: 'dd/MM/yyyy' }}</strong>
      dalle {{book.hour_start | timeFormat }} alle {{book.hour_end | timeFormat }}
      <div>
        <div class="d-flex flex-wrap">
          <div class="me-3 mt-3 text-center" *ngFor="let player of book.players">
            <div class="prev-prof-img-small border border border-3" [class]="{'border-warning': player.status == 'INS', 'border-success': player.status == 'CONFIRMED', 'border-danger': player.status == 'REJECT'}"  [ngStyle]="{'background-image' : 'url(data:image/jpeg;base64,'+ player.profile_image +')'}"></div>
            <div *ngIf="player.nickname">{{player.nickname}}</div>
            <div *ngIf="!player.nickname">{{player.name}}</div>
            <div class="small text-muted">{{getPlayLevelDescription(player.game_level)}}</div>
          </div>
        </div>
      </div>
      <div class="mt-2 text-end">
        <span *ngIf="book.user_booked"><i class="fa fa-check-circle text-success"></i> Partecipi già</span>
        <button class="btn btn-success btn-block" *ngIf="!book.user_booked" (click)="insertCandidature(book)">CANDIDATI</button>
      </div>
    </div>
  </div>
</div>

<div class="pb-5">
  <!-- Space for menu bug fix -->
</div>
