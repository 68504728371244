<div class="row mt-2 mb-2">
  <div class="col-12 text-center">
    <div class="alert alert-danger">
      <div>
        <i class="fa fa-2x fa-check-circle mb-3"></i>
        <h3>Pagamento annullato</h3>
        Transazione annullata come richiesto.
      </div>
    </div>
    <button class="btn btn-primary btn-lg mt-4" routerLink="/home">
      <span class="fa fa-home"></span> Torna alla home
    </button>
  </div>
</div>
