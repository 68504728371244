import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {logData} from "../interfaces/interfaces";
import {PrenotatennisService} from "./prenotatennis.service";

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private apiService: ApiService, private http: HttpClient,
              private prenotatennisService: PrenotatennisService) { }

  callInsertLog(oLog: logData,) {

    this.insertLog(oLog, this.prenotatennisService.userData.token).subscribe(insert_response => {
        // Do nothing
      },
      error => {
        // API call error
        console.log(error);
      }
    );

  }

  private insertLog(oLog: logData, token: string): Observable<any> {

    const completedUrl = environment.apiUrl + "log_insert";
    let headers = new HttpHeaders({
      'Authorization': token
    });

    let options = {
      headers: headers
    }

    return this.http.post(completedUrl, oLog, options);

  }

}
