<app-loader *ngIf="bLoadingData"></app-loader>

<div class="row mt-3">

  <div class="col-12 text-center mt-2">
    <h3>Le mie prenotazioni</h3>
  </div>
  <div class="col-12 text-center">
    <div class="accordion" id="">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingOne">
          <button class="accordion-button collapsed rounded" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
            <span class="fas fa-filter me-1"></span>Filtri
          </button>
        </h2>
        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            <div class="row">

              <div class="col-12 col-md-4 mt-2">
                <label>Dal</label>
                <input type="date" class="form-control" placeholder="10" [(ngModel)]="prenotatennisService.memberFilterBooking.date_from">
              </div>

              <div class="col-12 col-md-4 mt-2">
                <label>Al</label>
                <input type="date" class="form-control" [(ngModel)]="prenotatennisService.memberFilterBooking.date_to">
              </div>

              <div class="col-12 col-md-4 mt-2">
                <label>Codice</label>
                <input type="text" placegolder="Inserisci codice qui" class="form-control" [(ngModel)]="filter.code">
              </div>

              <div class="col-12 text-center mt-2">
                <button class="btn btn-sm btn-primary mt-3" (click)="getBookingListData()"><i class="fa fa-refresh"></i> CARICA</button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-2 g-2 pb-5">
  <div class="col-12 col-lg-4" *ngFor="let book of filterList(aBookingList)">

    <div class="border p-2 col-12 bg-light rounded mb-3" >
      <i class="fa fa-triangle-exclamation text-warning me-2" *ngIf="!book.confirmed" [ngbTooltip]="tipNotConfirmed"></i>
      <i class="fa fa-lock text-secondary me-2" [ngbTooltip]="'Prenotazione Riservata'" *ngIf="!book.public"></i>
      <i class="fa fa-lock-open text-success me-2" [ngbTooltip]="'Prenotazione Condivisa'" *ngIf="book.public"></i>
      <ng-template #tipNotConfirmed>
        Prenotazioni non confermata
      </ng-template>
      <strong>{{ book.date_play | date: 'dd/MM/yyyy' }}</strong>
      dalle {{ book.hour_start | timeFormat}} alle {{ book.hour_end | timeFormat }}
      <div>
        <strong>{{ book.playground_name }}</strong>
      </div>

      <div class="d-flex flex-wrap">
        <div class="small text-muted mt-1 me-2">
          {{book.code}}
        </div>
        <div class="me-2" *ngIf="prenotatennisService.settingsData.version_level == 'delux'">
          <span class="badge bg-primary me-2" *ngIf="!book.quote_payed">{{book.price | number: '1.2-2'}}€</span>
          <span class="badge bg-primary" *ngIf="book.quote_payed && (book.price - book.quote_payed) > 0">Saldato {{(book.quote_payed) | number: '1.2-2'}}€ su {{book.price | number: '1.2-2'}}€</span>
          <span class="badge bg-primary" *ngIf="book.quote_payed && (book.price - book.quote_payed) == 0">Pagato {{(book.quote_payed) | number: '1.2-2'}}€</span>
        </div>
        <div class="me-2" *ngIf="book.id_guest && book.player_status">
          <div class="text-warning" *ngIf="book.player_status == 'INS'">
            Candidatura in attesa
          </div>
          <div class="text-success" *ngIf="book.player_status == 'CONFIRMED'">
            Si gioca!
          </div>
          <div class="text-danger" *ngIf="book.player_status == 'REJECT'">
            Candidatura respinta
          </div>
        </div>
      </div>
        <div class="row">
          <div class="col-3 mt-3" *ngFor="let player of book.players">
            <div class="text-center d-flex flex-column align-items-center" >
              <div class="prev-prof-img-small border border border-3" [class]="{'border-warning': player.status == 'INS', 'border-success': player.status == 'CONFIRMED', 'border-danger': player.status == 'REJECT'}"  [ngStyle]="{'background-image' : 'url(data:image/jpeg;base64,'+ player.profile_image +')'}"></div>
              <div *ngIf="player.nickname" class="user-name text-center" [ngbTooltip]="player.surname + ' ' + player.name">{{player.nickname.length>10?(player.nickname | slice:0:10)+'...' : player.nickname}}</div>
              <div *ngIf="!player.nickname"  class="user-name text-center" [ngbTooltip]="player.surname + ' ' + player.name">{{player.name.length>10?(player.name | slice:0:10)+'...' : player.name}}</div>
            </div>
          </div>
        </div>

      <div class="col-12 mt-3 mb-2 d-flex justify-content-end">
        <button class="btn btn-primary ms-2 d-flex align-items-center" (click)="gotoBookDetails(book)" *ngIf="!book.id_guest || (book.id_guest && book.player_status == 'CONFIRMED')">
          <span class="fa fa-circle-info"></span>
          <span class="ms-2 d-none d-lg-block">Info</span>
        </button>
        <button class="btn btn-danger ms-2 d-flex align-items-center" (click)="confirmDeleteBooking(book.id)" *ngIf="check24h(book.date_play, book.hour_start) && !book.id_guest">
          <span class="fa fa-trash"></span>
          <span class="ms-2 d-none d-lg-block">Elimina</span>
        </button>
      </div>
    </div>


</div>

<div class="pb-5">
  <!-- Space for menu bug fix -->
</div>
