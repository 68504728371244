import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {closureData, managementSettingsData} from "../../interfaces/interfaces";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-settings-closure',
  templateUrl: './settings-closure.component.html',
  styleUrls: ['./settings-closure.component.scss']
})
export class SettingsClosureComponent implements OnInit {

  @Output() saveAnswer = new EventEmitter<boolean>();

  public aClosure: Array<closureData> = [];
  public oClosure: closureData = this.prenotatennisService.getNewClosureData();
  public oManagebleSettings: managementSettingsData = this.prenotatennisService.getNewSettingsData();
  public bLoadingData: boolean = false;

  public aClosedDays: Array<any> = [];
  public aSecretaryClosedSays: Array<any> = [];

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.getClosureListData();
    this.getManagebleSettingsData();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getClosureListData() {

    this.bLoadingData = true;

    this.prenotatennisService.getClosureListDB(this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.closure && get_response.closure.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare le chiusure' + ': ' + get_response.closure.error);
        } else {
          this.aClosure = get_response.closure;
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  getManagebleSettingsData() {

    this.bLoadingData = true;

    this.prenotatennisService.getManagebleSettingsDB(this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.settings && get_response.settings.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare le impostazioni' + ': ' + get_response.settings.error);
        } else {
          this.oManagebleSettings = get_response.settings[0];
          //this.prenotatennisService.settingsData = get_response.settings[0];

          this.aSecretaryClosedSays = [];
          this.aClosedDays = [];

          // Get Secretary closure days
          let aDays = this.oManagebleSettings.secretary_closed_week_days.split(";");
          let index: number = 0;
          aDays.map((x: string) => {
            if(x !== '') {
              this.aSecretaryClosedSays.push({description: this.prenotatennisService.getWeekDayDescription(index), value: x == 'true'});
            }
            index++;
          });

          // Get constraint days
          aDays = this.oManagebleSettings.closed_days.split(";");
          index = 0;
          aDays.map((x: string) => {
            if(x !== '') {
              this.aClosedDays.push({description: this.prenotatennisService.getWeekDayDescription(index), value: x == 'true'});
            }
            index++;
          });
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  insertClosure() {

    this.bLoadingData = true;

    this.prenotatennisService.postInsertClosureDB(this.oClosure, this.prenotatennisService.userData.token).subscribe(insert_response => {

        if(insert_response.closure && insert_response.closure.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare la chiusura: ' + insert_response.closure.error);
        } else {
          this.getClosureListData();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  changeStatus(closure: closureData) {

    closure.status = !closure.status;

    this.bLoadingData = true;

    this.prenotatennisService.postUpdateClosureDB(closure, this.prenotatennisService.userData.token).subscribe(update_response => {

        if(update_response.closure && update_response.closure.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile modificare la data: ' + update_response.closure.error);
        } else {
          this.getClosureListData();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );
  }

  deleteClosure(closure: closureData){

    this.bLoadingData = true;

    this.prenotatennisService.postDeleteClosureDB(closure, this.prenotatennisService.userData.token).subscribe(delete_response => {

        if(delete_response.closure && delete_response.closure.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile eliminare la data: ' + delete_response.closure.error);
        } else {
          this.getClosureListData();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  convertArrayToString(aData: Array<any>) {

    let sValues: string = "";

    aData.map(x => {
      sValues = sValues + x.value.toString() + ";";
    });

    return sValues
  }

  updateSettings() {

    this.bLoadingData = true;

    this.oManagebleSettings.closed_days = this.convertArrayToString(this.aClosedDays);
    this.oManagebleSettings.secretary_closed_week_days = this.convertArrayToString(this.aSecretaryClosedSays);

    this.prenotatennisService.postUpdateSettingsDB(this.oManagebleSettings, this.prenotatennisService.userData.token).subscribe(update_response => {

        if(update_response.settings && update_response.settings.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile modificare le date: ' + update_response.settings.error);
        } else {
          this.getManagebleSettingsData();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );
  }

  changeStatusCB(oItem: any) {
    oItem.value = !oItem.value;
  }

}
