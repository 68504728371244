import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomepageComponent} from "./pages/homepage/homepage.component";
import {Error404Component} from "./components/error404/error404.component";
import {UserProfileComponent} from "./components/user-profile/user-profile.component";
import {PartnerComponent} from "./pages/partner/partner.component";
import {PlaygroundComponent} from "./pages/playground/playground.component";
import {SettingsComponent} from "./pages/settings/settings.component";
import {MoreServiceComponent} from "./pages/more-service/more-service.component";
import {PartnerProfileComponent} from "./pages/partner-profile/partner-profile.component";
import {BookingComponent} from "./components/booking/booking.component";
import {BookingPayComponent} from "./pages/booking-pay/booking-pay.component";
import {BuyCreditComponent} from "./components/buy-credit/buy-credit.component";
import {PaymentCanceledComponent} from "./pages/payment-canceled/payment-canceled.component";
import {PaymentCompleteComponent} from "./pages/payment-complete/payment-complete.component";
import {BookingListComponent} from "./components/booking-list/booking-list.component";
import {SharedBookingComponent} from "./pages/shared-booking/shared-booking.component";
import {
  ShareBookingFastConfirmComponent
} from "./pages/share-booking-fast-confirm/share-booking-fast-confirm.component";
import {BlacklistComponent} from "./pages/blacklist/blacklist.component";
import {CalendarPublicComponent} from "./pages/calendar-public/calendar-public.component";

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch:'full' },
  { path: 'home', component: HomepageComponent },
  { path: 'il-mio-profilo', component: PartnerProfileComponent },
  { path: 'soci', component: PartnerComponent },
  { path: 'gestione-campi', component: PlaygroundComponent} ,
  { path: 'impostazioni', component: SettingsComponent } ,
  { path: 'servizi-aggiuntivi', component: MoreServiceComponent } ,
  { path: 'prenotazione', component: BookingComponent } ,
  { path: 'paga-prenotazione', component: BookingPayComponent } ,
  { path: 'ricarica-crediti', component: BuyCreditComponent } ,
  { path: 'pagamento-annullato', component: PaymentCanceledComponent },
  { path: 'pagamento-completato', component: PaymentCompleteComponent },
  { path: 'le-mie-prenotazioni', component: BookingListComponent },
  { path: 'prenotazioni-condivise', component: SharedBookingComponent },
  { path: 'conferma-partecipazione/:type/:id_bookplayer', component: ShareBookingFastConfirmComponent },
  { path: 'black-list', component: BlacklistComponent },
  { path: 'public-calendar', component: CalendarPublicComponent },
  { path: '**', component:  Error404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
