import { Component, OnInit } from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {playgroundListData} from "../../interfaces/interfaces";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../../components/alert-dialog/alert-dialog.component";
import {PlaygroundEditComponent} from "../../components/playground-edit/playground-edit.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.scss']
})
export class PlaygroundComponent implements OnInit {

  public aPlayground: Array<playgroundListData> = [];
  public bLoadingData: boolean = false;

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              private router: Router,) { }

  ngOnInit(): void {
    if(this.prenotatennisService.userData.id == 0) this.router.navigate(['home']);
    if(this.prenotatennisService.userData.id !== 0) this.getPLaygroundListData();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getPLaygroundListData() {

    this.bLoadingData = true;

    this.prenotatennisService.getPlaygroundListDB(false, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.playground && get_response.playground.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i campi' + ': ' + get_response.playground.error);
        } else {
          this.aPlayground = get_response.playground.data;
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  editPlayground(id_playground: number) {
    const modalRef = this.modalService.open(PlaygroundEditComponent, { size: 'xl' });
    modalRef.componentInstance.id_playground = id_playground;
    modalRef.componentInstance.saveAnswer.subscribe((confirmation: string) => {

      if(confirmation) {
        this.getPLaygroundListData();
        if(confirmation == 'CLOSE') modalRef.close();
      }

    });

  }

}
