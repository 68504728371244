<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header bg-primary text-white">
  <h4 class="modal-title">{{prenotatennisService.settingsData.club_name}} - registrazione</h4>
  <button type="button" class="btn btn-default" aria-label="Close" (click)="closePupup()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 mt-2">
      <label class="mb-1"><i class="fa" [ngClass]="oMember.name !== ''?'fa-check-circle text-success':'fa-exclamation-triangle text-warning'"></i> Nome</label>
      <input type="text" placeholder="Il tuo nome" class="form-control mt-1" [(ngModel)]="oMember.name">
    </div>
    <div class="col-12 mt-2">
      <label class="mb-1"><i class="fa" [ngClass]="oMember.surname !== ''?'fa-check-circle text-success':'fa-exclamation-triangle text-warning'"></i> Cognome</label>
      <input type="text" placeholder="Il tuo cognome" class="form-control mt-1" [(ngModel)]="oMember.surname">
    </div>
    <div class="col-12 mt-2">
      <label class="mb-1"><i class="fa" [ngClass]="oMember.email !== ''?'fa-check-circle text-success':'fa-exclamation-triangle text-warning'"></i> Email</label>
      <input type="text" placeholder="La tua email" class="form-control mt-1" [(ngModel)]="oMember.email">
    </div>
    <div class="col-12 mt-2">
      <label class="mb-1"><i class="fa" [ngClass]="oMember.psw !== ''?'fa-check-circle text-success':'fa-exclamation-triangle text-warning'"></i> Password</label>
      <input type="password" placeholder="password" class="form-control mt-1" [(ngModel)]="oMember.psw">
      <!--<password-strength-meter [password]="oMember.psw"></password-strength-meter>-->
    </div>
    <div class="col-12 mt-2">
      <label class="mb-1"><i class="fa" [ngClass]="sCheckPsw !== '' && sCheckPsw == oMember.psw?'fa-check-circle text-success':'fa-exclamation-triangle text-warning'"></i> Conferma password</label>
      <input type="password" placeholder="Conferma password" class="form-control" [(ngModel)]="sCheckPsw">
    </div>
    <div class="col-12 mt-2 text-muted small">
      <div class="form-check mb-2 mt-4">
        <input class="form-check-input" type="checkbox" id="readed" [(ngModel)]="bCheckPrivacy">
        <label class="form-check-label" for="readed">
          Registrandoti alla piattaforma confermi di aver letto e di accettare il trattamento dei tuoi dati come da informativa
          del centro sportivo: <a class="link-primary me-2" href="{{prenotatennisService.settingsData.privacy_link}}" target="_blank">Privacy Policy</a>
        </label>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success" [disabled]="disableSaveButton()" (click)="getGuestToken()">REGISTRAMI!</button>
  <button type="button" class="btn btn-secondary" (click)="closePupup()">CHIUDI</button>
</div>

