<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header bg-primary text-white">
  <h4>Gestione dati campo</h4>
  <span class="badge bg-black">Prenotazioni: {{oPlayground.booking_nr}}</span>
</div>

<div class="modal-body">
  <div class="row">

    <div class="col-12" *ngIf="oPlayground.booking_nr > 0">
      <div class="alert alert-warning">
        Impossibile eliminare il campo prenotazioni presenti
      </div>
    </div>

    <div class="col-12 col-md-6">
      <label>Nome del campo</label>
      <input type="text" class="form-control" placeholder="nome del campo" [(ngModel)]="oPlayground.name">
    </div>
    <div class="col-12 col-md-5 mt-2">
      <label>Sport</label>
      <select class="form-control" [(ngModel)]="oPlayground.id_sport">
        <option value="0">-- seleziona sport --</option>
        <option *ngFor="let sport of prenotatennisService.settingsData.sports" [value]="sport.id">{{sport.description}}</option>
      </select>
    </div>
    <div class="col-12 mt-2">
      <label>Descrizione</label>
      <input type="text" maxlength="150" class="form-control" placeholder="Descrizione del campo" [(ngModel)]="oPlayground.description">
    </div>
    <div class="col-12 col-md-2 mt-2">
      <label>Inizio prenotazione</label>
      <input type="time" class="form-control" [(ngModel)]="oPlayground.start_booking">
    </div>
    <div class="col-12 col-md-2 mt-2">
      <label>Fine prenotazione</label>
      <input type="time" class="form-control" [(ngModel)]="oPlayground.end_booking">
    </div>
    <div class="col-12 col-md-2 mt-2">
      <label>Gap (in minuti)</label>
      <input type="number" class="form-control" [(ngModel)]="oPlayground.gap">
    </div>
    <div class="col-12 col-md-2 mt-2">&nbsp;</div>
    <div class="col-12 col-md-2 mt-2">
      <label>Inizio chiusura campo</label>
      <input type="time" class="form-control" [(ngModel)]="oPlayground.start_close">
    </div>
    <div class="col-12 col-md-2 mt-2">
      <label>Fine chiusura campo</label>
      <input type="time" class="form-control" [(ngModel)]="oPlayground.end_close">
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-12 col-md-6 mt-3">
      <h5>Impostazioni</h5>
      <div class="form-check">
        <input class="form-check-input" id="reservedCheck" type="checkbox" [(ngModel)]="oPlayground.reserved">
        <label class="form-check-label" for="reservedCheck">
          Campo riservato alla segreteria
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="statusCheck" [(ngModel)]="oPlayground.status">
        <label class="form-check-label" for="statusCheck">
          Campo aperto
        </label>
      </div>
    </div>
    <div class="col-12 col-md-6 mt-3" *ngIf="oPlayground.id">
      <h5>Servizi aggiuntivi</h5>

      <div class="form-check" *ngFor="let serv of aService" (click)="changeStatusService(serv)">
        <input class="form-check-input" type="checkbox" value="" id="service{{serv.id}}" [checked]="serv.active">
        <label class="form-check-label" for="service{{serv.id}}">
          {{serv.title}}
          <span class="small text-muted">
            ({{serv.price | number: '1.2-2' }} € / <span *ngIf="serv.unatantum">partita</span>
            <span *ngIf="!serv.unatantum">persona</span>)
          </span>
        </label>
      </div>

    </div>
  </div>

  <div class="row mt-5" *ngIf="oPlayground.id">
    <div *ngIf="prenotatennisService.settingsData.version_level == 'delux'">
      <app-price-list-edit
        [id_playground]="id_playground"
        [start_hour] = "oPlayground.start_booking"
        [end_hour] = "oPlayground.end_booking"
      ></app-price-list-edit>
    </div>
  </div>

</div>

<div class="modal-footer">
  <button class="btn btn-danger me-4" (click)="deletePlayground()" *ngIf="oPlayground.booking_nr == 0 && oPlayground.id !== 0">Elimina campo</button>
  <button class="btn btn-success" (click)="savePlayground()">Salva modifiche</button>
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('')">CHIUDI</button>
</div>
