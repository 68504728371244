<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header bg-primary text-white">
  <h4>Chiusure centro e segreteria</h4>
</div>

<div class="modal-body">

  <!-- Giorni festivi -->
  <div class="row">
    <div class="col-12">
      <h4>Giorni festivi</h4>
    </div>

    <div class="col-12 col-md-6 mt-2">
      <label>Giorno di chiusura</label>
      <input type="date" class="form-control" placeholder="Data della chiusura" [(ngModel)]="oClosure.date">
    </div>

    <div class="col-12 col-md-6 text-center mt-2">
      <button class="btn btn-success mt-4" (click)="insertClosure()" [disabled]="!oClosure.date"><i class="fa fa-plus-circle"></i> Aggiungi</button>
    </div>

    <div class="col-12 mt-2">
      <div class="d-flex flex-row justify-content-center flex-wrap">
        <div class="alert me-2 mt-2 text-center" *ngFor="let closure of aClosure" [ngClass]="{'alert-success': closure.status, 'alert-warning': !closure.status}">
          {{closure.date}}
          <div class="text-danger pointer" (click)="deleteClosure(closure)">Elimina</div>
          <div class="text-danger pointer" (click)="changeStatus(closure)">Stato</div>
        </div>
      </div>
    </div>

  </div>

  <!-- Chiusure segreteria -->
  <div class="row mt-2 pt-3 border-top">
    <div class="col-12">
      <h4>Chiusure segreteria</h4>
      <p class="text-justify">
        Nei giorni di chiusura della segreteria i soci potranno prenotare il campo solo se dispongono di "Crediti".
        La quota sarà pagata interamente dal socio che prenota e sarà scalata in automatico dai crediti disponibili.
        <br />
        <strong>Funzionalità attiva solo con la versione DELUXE</strong>
      </p>
    </div>
    <div class="col-12 mt-2">
      <h5>Giorni della settimana di chiusura segreteria</h5>
      <div class="form-check form-check-inline" *ngFor="let day of aSecretaryClosedSays; let i = index">
        <input class="form-check-input" type="checkbox" id="cb{{i}}" [checked]="day.value" (click)="changeStatusCB(day)">
        <label class="form-check-label" for="cb{{i}}">{{day.description}}</label>
      </div>
    </div>

    <div class="col-12 mt-4">
      <h5>Fasce orarie di chiusura segreteria</h5>
      Per non usare vincoli orari impostare tutti gli orari a 00:00
    </div>

    <div class="col-12 col-md-6 mt-2">
      <label>Ora inizio</label>
      <input type="time" class="form-control" [(ngModel)]="oManagebleSettings.secretary_hour_start">
    </div>

    <div class="col-12 col-md-6 mt-2">
      <label>Ora fine</label>
      <input type="time" class="form-control" [(ngModel)]="oManagebleSettings.secretary_hour_end">
    </div>

  </div>

  <!-- Chiusure settimanli struttura -->
  <div class="row mt-2 pt-3 border-top">
    <div class="col-12">
      <h4>Chiusure settimanali struttura</h4>
      <p class="text-justify">
        Selezionare i giorni della settimana in cui la struttura è completamente chiusa, in questi giorni
        non sarà possibile prenotare.
      </p>
    </div>
    <div class="col-12 mt-2">
      <div class="form-check form-check-inline" *ngFor="let day of aClosedDays; let i = index">
        <input class="form-check-input" type="checkbox" id="cb{{i}}" [checked]="day.value" (click)="changeStatusCB(day)">
        <label class="form-check-label" for="cb{{i}}">{{day.description}}</label>
      </div>
    </div>
  </div>

</div>

<div class="modal-footer">
  <button class="btn btn-success" (click)="updateSettings()">Salva modifiche</button>
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('')">CHIUDI</button>
</div>

