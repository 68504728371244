import { Component, OnInit } from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {moreServiceData, playgroundListData} from "../../interfaces/interfaces";
import {AlertDialogComponent} from "../../components/alert-dialog/alert-dialog.component";
import {MoreServiceEditComponent} from "../../components/more-service-edit/more-service-edit.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-more-service',
  templateUrl: './more-service.component.html',
  styleUrls: ['./more-service.component.scss']
})
export class MoreServiceComponent implements OnInit {

  public aService: Array<moreServiceData> = [];
  public bLoadingData: boolean = false;

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              private router: Router) { }

  ngOnInit(): void {
    if(this.prenotatennisService.userData.id == 0) this.router.navigate(['home']);
    if(this.prenotatennisService.userData.id !== 0) this.getServiceListData();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getServiceListData() {

    this.bLoadingData = true;

    this.prenotatennisService.getMoreServiceListDB(false, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.service && get_response.service.error) {
          //Error
          this.bLoadingData = false;
          if(get_response.booking.error == 'Token not found') this.router.navigate(['home']);
          if(get_response.booking.error !== 'Token not found') {
            this.openDialog('danger', 'Attenzione, impossibile caricare i servizi' + ': ' + get_response.service.error);
          }
        } else {
          this.aService = get_response.service;
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  editService(id_service: number) {
    const modalRef = this.modalService.open(MoreServiceEditComponent, { size: 'xl' });
    modalRef.componentInstance.id_service = id_service;
    modalRef.componentInstance.saveAnswer.subscribe((confirmation: boolean) => {

      if(confirmation) {
        this.getServiceListData();
      }

    });

  }

}
