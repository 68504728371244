<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header bg-primary text-white">
  <h4>Prenotazione multipla - {{oMultipleBooking.playground_name}}</h4>
</div>

<div class="modal-body">

  <section id="menuMultipleBooking mb-3">
    <div class="row mt-3 mb-4">
      <div class="col-12 col-md-6 pointer mt-2 text-center" *ngIf="oViewType.menu">
        <div class="p-1 mb-3 text-center border rounded" (click)="setViewSection('INS')">
          <i class="fa fa-lock fa-3x mt-3"></i>
          <h5 class="mt-3">Nuova</h5>
          <p>
            Crea una nuova prenotazione multipla sul campo "{{oMultipleBooking.playground_name}}"
          </p>
        </div>
      </div>
      <div class="col-12 col-md-6 pointer mt-2 text-center" *ngIf="oViewType.menu">
        <div class="p-1 mb-3 text-center border rounded" (click)="setViewSection('SEARCH')">
          <i class="fa fa-lock fa-3x mt-3"></i>
          <h5 class="mt-3">Cerca</h5>
          <p>
            Cerca e gestisci una prenotazione multipla
          </p>
        </div>
      </div>
    </div>
  </section>

  <section id="newMultipleBooking" *ngIf="oViewType.new_booking">
    <section id="settingsBooking" *ngIf="!oCreatingResponse">
      <div class="row">
        <div class="col-12 col-md-6 mt-2">
          <label>Dal</label>
          <input type="date" class="form-control" [(ngModel)]="oMultipleBooking.from_dt">
        </div>
        <div class="col-12 col-md-6 mt-2">
          <label>Al</label>
          <input type="date" class="form-control" [(ngModel)]="oMultipleBooking.to_dt">
        </div>
        <div class="col-12 col-md-6 mt-2">
          <label>Ora inizio</label>
          <input type="time" class="form-control" [(ngModel)]="oMultipleBooking.start_hour">
        </div>
        <div class="col-12 col-md-6 mt-2">
          <label>Ora fine (opzionale)</label>
          <input type="time" class="form-control" [(ngModel)]="oMultipleBooking.end_hour">
          <div class="small text-muted">
            L'ora fine deve essere inserita considerando il GAP orario di prenotazione del campo.
          </div>
        </div>

        <div class="col-12 mt-3">
          <label>Giorno della settimana</label>
          <select class="form-control" [(ngModel)]="oMultipleBooking.day_of_week">
            <option [value]="0">Domenica</option>
            <option [value]="1">Lunedì</option>
            <option [value]="2">Martedì</option>
            <option [value]="3">Mercoledì</option>
            <option [value]="4">Giovedì</option>
            <option [value]="5">Venerdì</option>
            <option [value]="6">Sabato</option>
          </select>

        </div>


        <section id="bookingUser" class=" mt-3 border rounded p-2 bg-light">
          <div class="row mt-2">
            <div class="col-12 col-md-6 mb-1">
              <h3>Giocatore di riferimento</h3>
              <div class="text-muted">
                Il giocatore selezionato sarà il responsabile delle prenotazioni che saranno create.<br />
                Dovrà aver cura di completare le prenotazioni aggiungendo i giocatori che parteciperanno alla partita.
              </div>

              <div class="mt-3 d-flex flex-wrap justify-content-center align-items-center">
                <div class="flex-fill mt-2">
                  <button class="btn btn-success" (click)="openPlayerList(true)">
                    <i class="fa fa-user"></i> SELEZIONA GIOCATORE
                  </button>
                </div>
                <div class="flex-fill mt-2">
                  <button class="btn btn-success" (click)="openPlayerList()">
                    <i class="fa fa-user"></i> PRENOTAZIONE SEGRETERIA
                  </button>
                </div>
              </div>

            </div>

            <div class="col-12 col-md-6 mt-2 text-center">
              <div class="mt-2" *ngIf="aSelectedUsers.length == 0">
                <div class="alert alert-info text-center">
                  <strong>Nessun giocatore confermato</strong>
                </div>
              </div>
              <div *ngIf="aSelectedUsers.length > 0">
                <div class="row py-4">
                  <div class="col-6 col-lg-3 text-center flex-fill" *ngFor="let player of aSelectedUsers">
                    <div class="pointer"> <!-- (click)="showDetails(player)"-->
                      <div class="prev-prof-img border border border-3"
                           [ngStyle]="{'background-image' : 'url(data:image/jpeg;base64,'+ player.profile_image +')'}">
                      </div>
                      <div *ngIf="player.nickname" class="user-name text-center" [ngbTooltip]="player.nickname">{{player.nickname.length>10?(player.nickname | slice:0:10)+'...' : player.nickname}}</div>
                      <div *ngIf="!player.nickname"  class="user-name text-center" [ngbTooltip]="player.name">{{player.name!.length>10?(player.name | slice:0:10)+'...' : player.name}}</div>

                      <div class="p-2 bg-light text-center">

                        <div class="btn-group">
                          <div type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-ellipsis-h"></i>
                          </div>
                          <ul class="dropdown-menu">
                            <li><a class="dropdown-item">{{player.surname}} {{player.name}}</a></li>
                            <li><hr class="dropdown-divider"></li>
                            <a class="dropdown-item text-danger" (click)="removePlayerSelected(player.id)"><i class="fa fa-trash me-1"></i>Elimina</a>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <label>Note prenotazione</label>
                <input type="text" placeholder="Note" class="form-control" [(ngModel)]="oMultipleBooking.notes" maxlength="200">
              </div>

            </div>
          </div>
        </section>
      </div>
    </section>
    <section id="responseBooking" *ngIf="oCreatingResponse">
      <div class="row">
        <div class="col-12 mt-3 mb-3 text-center">
          <h3>CODICE: {{oCreatingResponse?.code}}</h3>
        </div>
        <div class="col-12 mt-3 mb-3">
          <h4>Giorni prenotati</h4>
          <span class="text-success me-2" *ngFor="let day_book of oCreatingResponse.insert">{{day_book | date: 'dd/MM/yyyy'}}</span>
        </div>
        <div class="col-12 mt-3 mb-3">
          <h4>Giorni NON prenotati</h4>
          <span class="text-danger me-2" *ngFor="let day_book of oCreatingResponse.not_insert">{{day_book | date: 'dd/MM/yyyy'}}</span>
        </div>
      </div>
    </section>
  </section>

  <section id="searchMultipleBooking" *ngIf="oViewType.search_booking">
    <section id="searchBooking">
      <div class="row">
        <div class="col-6 mt-2">
          <input type="text" class="form-control" placeholder="Codice prenotazione multipla" [(ngModel)]="bookingCode">
        </div>
        <div class="col-3 mt-2">
          <button class="btn btn-primary" (click)="searchMultiBooking()">CERCA</button>
        </div>
        <div class="col-3 mt-2">
          <button class="btn btn-danger" (click)="deleteAllBooking()">ELIMINA TUTTI</button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <table class="table table-responsive table-hover table-striped table-bordered">
            <thead>
            <tr>
              <td>Prenotazione</td>
              <td>Data / ora</td>
              <td>#</td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let mBook of aMultipleBooking">
              <td>
                {{mBook.code}}
                <div class="small text-muted">{{mBook.multiple_code}}</div>
                <div class="small text-muted">{{mBook.surname}} {{mBook.name}} <span *ngIf="mBook.member_code">[{{mBook.member_code}}]</span></div>
              </td>
              <td>
                {{mBook.date_play | date: 'dd/MM/yyyy'}}
                <div>
                  {{mBook.hour_start | timeFormat}} - {{mBook.hour_end | timeFormat}}
                </div>
              </td>
              <td><button class="btn btn-sm btn-danger" (click)="deleteBooking(mBook)"><i class="fa fa-trash"></i></button></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </section>

</div>

<div class="modal-footer">
  <button class="btn btn-primary" *ngIf="!oViewType.menu" (click)="setViewSection('MENU')">MENU</button>
  <button class="btn btn-success" *ngIf="!oCreatingResponse && oViewType.new_booking" (click)="runMultipleBooking()">ELABORA PRENOTAZIONI</button>
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('')">CHIUDI</button>
</div>
