<app-loader *ngIf="bLoadingData"></app-loader>

<div class="components mb-lg-4" [ngSwitch]="prenotatennisService.userData.level">

  <div class="alert alert-warning alert-dismissible fade show"
       *ngIf="prenotatennisService.settingsData.check_cert_med && prenotatennisService.userData.medical_expired && prenotatennisService.userData.level !== 'admin'">
    <strong>Attenzione: certificato medico scaduto</strong>
  </div>

  <div class="alert alert-warning alert-dismissible fade show"
       *ngIf="prenotatennisService.settingsData.check_member_valid && prenotatennisService.userData.member_expired && prenotatennisService.userData.level !== 'admin'">
    <strong>Attenzione: tessera socio scaduta</strong>
  </div>


  <!--------------------------------------------------------->
  <!------------ AMMINISTRATORE ----------------------------->
  <!--------------------------------------------------------->
  <div *ngSwitchCase="'admin'" class="button-wrapper">

    <div class="d-flex flex-row justify-content-between justify-content-lg-center">

      <div class="menu-button border rounded p-2 me-2 pointer" routerLinkActive="active" routerLink="/home">
        <i class="fa fa-home fa-2x"></i>
        <div class="small">Home</div>
      </div>

 <!--     <div class="menu-button border rounded p-2 me-2 pointer" routerLinkActive="active" routerLink="/il-mio-profilo">
        <i class="fa fa-user fa-2x"></i>
        <div class="small">Profilo</div>
      </div>-->

      <div class="menu-button border rounded p-2 me-2 pointer" routerLinkActive="active" routerLink="/soci">
        <i class="fa fa-users fa-2x"></i>
        <div class="small">Soci</div>
      </div>

      <div class="menu-button border rounded p-2 me-2 pointer" routerLinkActive="active" routerLink="/gestione-campi">
        <i class="fa fa-layer-group fa-2x"></i>
        <div class="small">Campi</div>
      </div>

      <div class="menu-button border rounded p-2 me-2 pointer" routerLinkActive="active" routerLink="/servizi-aggiuntivi">
        <i class="fa fa-plus-circle fa-2x"></i>
        <div class="small">Servizi</div>
      </div>

      <div class="menu-button border rounded p-2 me-2 pointer" routerLinkActive="active" routerLink="/impostazioni">
        <i class="fa fa-screwdriver-wrench fa-2x"></i>
        <div class="small">Impos.</div>
      </div>

    </div>

  </div>

  <!--------------------------------------------------------->
  <!------------ SOCIO/GIOCATORE ---------------------------->
  <!--------------------------------------------------------->
  <div *ngSwitchCase="'member'" class="button-wrapper">

    <div class="d-flex flex-row justify-content-between justify-content-lg-center">

      <div class="menu-button border rounded p-2 me-2 pointer" routerLinkActive="active" routerLink="/home">
        <i class="fa fa-home fa-2x"></i>
        <div class="small">Home</div>
      </div>

<!--      <div class="menu-button border rounded p-2 me-2 pointer" routerLinkActive="active" routerLink="/il-mio-profilo">
        <i class="fa fa-user fa-2x"></i>
        <div class="small">Profilo</div>
      </div>-->

      <div class="menu-button border rounded p-2 me-2 pointer" routerLinkActive="active" routerLink="/le-mie-prenotazioni">
        <i class="fa fa-list fa-2x"></i>
        <div class="small">Prenotazioni</div>
      </div>

      <div class="menu-button border rounded p-2 me-2 pointer" routerLinkActive="active" routerLink="/prenotazioni-condivise">
        <i class="fa fa-list fa-2x"></i>
        <div class="small">Share</div>
      </div>

      <div class="menu-button border rounded p-2 me-2 pointer" routerLinkActive="active" routerLink="/black-list">
        <i class="fa fa-skull fa-2x"></i>
        <div class="small">Black List</div>
      </div>

      <div class="menu-button border rounded p-2 me-2 pointer" routerLinkActive="active" routerLink="/ricarica-crediti" *ngIf="prenotatennisService.settingsData.version_level == 'delux' && prenotatennisService.settingsData.paypal=='yes'">
        <i class="fa fa-credit-card fa-2x"></i>
        <div class="small">Ricarica</div>
      </div>

    </div>

  </div>

</div>


