import {Component, OnInit} from '@angular/core';
import {
  bookingData, bookingMultipleData,
  bookingPlayerData, bookingServiceData, bookingShareMailData,
  checkUserBookingData, moreServicePlaygroundData, paymentData, playerData,
  playgroundData, sportData
} from "../../interfaces/interfaces";
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {DatePipe} from "@angular/common";
import {Router} from "@angular/router";
import {BookingMultipleComponent} from "../booking-multiple/booking-multiple.component";
import {PlayerListComponent} from "../player-list/player-list.component";
import {LogService} from "../../services/log.service";

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {

  public oBook: any = this.prenotatennisService.oBookSelected;
  public iBookID: number = this.prenotatennisService.oBookIDSelected;

  public bLoadingData: boolean = false;
  public bBookingBlock: boolean = false;
  public bFewCredits: boolean = false;
  public bLoadingBookPlayerList: boolean = false;
  public bLoadingPlayground: boolean = false;
  public bLoadingPGCost: boolean = false;
  public bLoadingHours: boolean = false;
  public bTypeBooking: string = 'MINE'; // values: MINE, OLD, GUEST
  public bPlayerDetails: boolean = false;
  public oPlayerClick: bookingPlayerData = this.prenotatennisService.getNewBookingPlayerData(0, 0, '');

  public oBooking: bookingData = this.prenotatennisService.getNewBookingData();
  public oCheckBooking: checkUserBookingData = this.prenotatennisService.getNewCheckUserBookingData();
  public oPlayground: playgroundData = this.prenotatennisService.getNewPlayground();
  public oSport: sportData = {
    id: 0,
    description: "",
    icon: "",
    payment_type: "",
    player_min_nr: 1,
    player_nr: 1,
    status: false
  };
  //public selectedPlayerID: number = 0;
  public playgroundCost: any = null;
  public iStepper: number = 1;
  public player_game_level: any = "";
  public quota_payed: number = 0;
  public confirmedPLayer: number = 0;

  public aBookingPlayers: Array<bookingPlayerData> = [];
  public aBookingServices: Array<bookingServiceData> = [];
  public aAvailableUser: Array<playerData> = [];
  public aServicePlayground: Array<moreServicePlaygroundData> = [];
  public aAvailableHours: Array<string> = [];
  public aPayment: Array<paymentData> = [];

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              private datepipe: DatePipe, private router: Router, private logService: LogService) { }

  ngOnInit(): void {
    if(!this.oBook && this.iBookID == 0) this.router.navigate(['home']);
    this.initComponentData();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  initComponentData() {

    this.bLoadingData = true;

    if(this.oBook) {

      this.oBooking.id = this.oBook.hour.booked.id_book;
      this.oBooking.date_play = this.oBook.day;
      this.oBooking.hour_start = this.oBook.hour.start;
      this.oBooking.hour_end = this.oBook.hour.end;
      this.oBooking.id_playground = this.oBook.pg;
      this.oBooking.playground_name = this.oBook.pg_name;

      if(this.oBooking.id !== 0) this.initEditBook();
      if(this.oBooking.id == 0) this.initNewBook();

      this.bLoadingData = false;

    }

    if(this.iBookID !== 0) {
      this.oBooking.id = this.iBookID;
      this.initEditBook();
    }

    // PLayer level
    if(this.prenotatennisService.settingsData && this.prenotatennisService.settingsData.player_levels) this.player_game_level = this.prenotatennisService.settingsData.player_levels.filter((x:any) => x.id == this.prenotatennisService.userData.game_level)[0];

  }

  initNewBook() {
    this.getPlaygroundData();
    this.getCheckPlayCondition();
  }

  initEditBook() {
    this.iStepper = 2;
    this.getBookingDetails();
  }

  /*****************************************/
  /**** PLAYGROUND *************************/
  /*****************************************/

  getPlaygroundData() {

    this.bLoadingPlayground = true;

    this.prenotatennisService.getPlaygroundListDB(false, this.prenotatennisService.userData.token, this.oBooking.id_playground).subscribe(get_response => {

        if(get_response.playground && get_response.playground.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i dati del campo' + ': ' + get_response.playground.error);
        } else {
          this.oPlayground = get_response.playground.data[0];
          if(!this.prenotatennisService.settingsData.sports) this.router.navigate(['home']);
          if (this.prenotatennisService.settingsData.sports) this.oSport = this.prenotatennisService.settingsData.sports.filter((x: any) => x.id == this.oPlayground.id_sport)[0];
          this.getPGServiceListData();
        }
      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingPlayground = false;
      });

  }

  getPGServiceListData() {

    this.prenotatennisService.getMoreServicePGListDB(this.oBooking.id_playground, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.service && get_response.service.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i servizi attivi' + ': ' + get_response.service.error);
        } else {
          this.aServicePlayground = get_response.service;
          this.aServicePlayground.map(service => {
            service.booking_active = false;
            service.booking_price = 0;
            service.booking_qnt = 1;
            service.booking_service_table_id = 0;
            return service;
          });
          if(this.oBooking.id !== 0) this.getBookingServiceData();
        }

        this.bLoadingPlayground = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingPlayground = false;
      });

  }

  getBookingServiceData() {

    this.bLoadingPlayground = true;

    this.prenotatennisService.getBookingServiceDB(this.oBooking.id, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.booking && get_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare la lista dei servizi prenotati' + ': ' + get_response.booking.error);
        } else {
          this.aBookingServices = get_response.booking;

          this.aServicePlayground.map(service => {

            let aFound: Array<bookingServiceData> = [];
            aFound = this.aBookingServices.filter(x => x.id_service == service.id_service);

            service.booking_active = false;
            service.booking_price = 0;
            service.booking_qnt = 1;
            service.booking_service_table_id = 0;

            if(aFound && aFound.length > 0){
              service.booking_active = true;
              service.booking_price = aFound[0].price;
              service.booking_qnt = aFound[0].quantity;
              service.booking_service_table_id = aFound[0].id;
            }

            return service;
          });

        }

        this.bLoadingPlayground = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingPlayground = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto: ' + error);
      });

  }

  /*****************************************/
  /**** BOOK CHECKS ************************/
  /*****************************************/

  getCheckPlayCondition() {

    this.bLoadingData = true;

    this.prenotatennisService.getCheckBookingDateDB(this.datepipe.transform(this.oBooking.date_play, "yyyy-MM-dd"), this.oBooking.hour_start, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.check && get_response.check.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile controllare la prenotazione' + ': ' + get_response.check.error);
          this.bBookingBlock = true;
          this.bLoadingData = false;
        } else {
          this.oCheckBooking = get_response.check;
          this.userCanBooking();
        }

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto: ' + error);
      });
  }

  userCanBooking() {

    this.bBookingBlock = false;

    if(this.oCheckBooking.block.medical_expiration) this.bBookingBlock = true;
    if(this.oCheckBooking.block.member_expiration) this.bBookingBlock = true;
    if (!this.oCheckBooking.free.free_booking_24h) {
      // Se l'ora non è libera da controlli controllo il nr di prenotazioni e stesso giorno
      if(this.oCheckBooking.block.nr_booking_week) this.bBookingBlock = true;
      if(this.oCheckBooking.block.same_day) this.bBookingBlock = true;
    }

    this.bLoadingData = false;

    if(!this.bBookingBlock) this.getBookingAvailableHours();
    if(!this.bBookingBlock) this.getBookingCostData();

  }

  getBookingAvailableHours() {

    this.bLoadingHours = true;

    this.prenotatennisService.getBookingAvailableHoursDB(this.oBooking.date_play, this.oBooking.hour_start, this.oBooking.id_playground, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.booking && get_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i dati della prenotazione' + ': ' + get_response.booking.error);
        } else {
          this.aAvailableHours = get_response.booking;
        }

        this.bLoadingHours = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto: ' + error);
        this.bLoadingHours = false;
      });
  }

  checkTypeBooking() {

    this.bTypeBooking = "MINE";

    let play_date = new Date(this.oBooking.date_play + "T" + this.oBooking.hour_start + ".000");

    let nowDate: Date = new Date();
    let diff = (play_date.getTime() - nowDate.getTime()) / 1000;
    diff /= (60 * 60);

    if(diff < 0) this.bTypeBooking = "OLD";
    if(Number(this.oBooking.id_user) !== Number(this.prenotatennisService.userData.id)) this.bTypeBooking = "GUEST";
    if(this.prenotatennisService.userData.level == 'admin') this.bTypeBooking = "MINE";

    console.log(this.bTypeBooking);

  }

  getLevelDescription(player_level: number) {
    let findLevel: Array<any> = [];
    findLevel = this.prenotatennisService.settingsData.player_levels.filter((x: any) => x.id === player_level);
    if(findLevel.length > 0) return findLevel[0].description;
    return "n.a."
  }

  /*****************************************/
  /***** COMMANDS **************************/
  /*****************************************/

  changeHourSelection(hour: string){
    this.oBooking.hour_end = hour;
    this.confirmStep(2, true);
    this.getBookingCostData();
  }

  confirmStep(stepNr: number, insBooking?: boolean) {
    this.iStepper = stepNr;
    if (insBooking) this.insertBooking();
  }

  changePlayerStatus(oPlayer: bookingPlayerData, status: string) {

    oPlayer.status = status;
    this.updatePlayerToBooking(oPlayer);
    this.oPlayerClick = this.prenotatennisService.getNewBookingPlayerData(0, 0, '');
    this.bPlayerDetails = false;

  }

  shareBooking() {
    this.oBooking.public = !this.oBooking.public;
    this.oBooking.teacher = false;
    this.updateBooking(false);
  }

  teacherBooking() {
    this.oBooking.public = false;
    this.oBooking.teacher = !this.oBooking.teacher;
    this.updateBooking(false);
  }

  openPlayerList(){
    const modalRef = this.modalService.open(PlayerListComponent, { size: 'xl' });
    modalRef.componentInstance.pladate_play = this.oBooking.date_play;
    modalRef.componentInstance.playerIDAnswer.subscribe((confirmation: number) => {
      if(confirmation) {
        this.addPlayer(confirmation);
      }
    });
  }

  addPlayer(player_id: number) {

    if(this.oSport.player_nr && this.aBookingPlayers.length == this.oSport.player_nr) {
      this.openDialog('danger', 'Raggiunto il numero massimo di giocatori');
      return;
    }

    if(this.aBookingPlayers.filter(player => player.id_user == player_id).length > 0) {
      this.openDialog('danger', 'Giocatore già presente nella prenotazione');
      return;
    };

    if(player_id !== 0) this.insertPlayerToBooking(player_id, 'CONFIRMED');

  }

  saveBookingService(service: moreServicePlaygroundData){
    if(!service.booking_active) this.insertServiceToBooking(service);
    if(service.booking_active) this.deleteServiceToBooking(service);
  }

  /*confirmBooking() {
    this.oBooking.confirmed = 1;
    this.updateBooking(true, true);
  }*/

  gotoPayments() {
    this.prenotatennisService.oBooking = this.oBooking;
    this.router.navigate(['/paga-prenotazione', {id: this.oBooking.id}]);
    // paga-prenotazione;id=40
  }

  closeDetails() {
    this.router.navigate(['home']);
  }

  showDetails(player: bookingPlayerData){
    this.bPlayerDetails = !this.bPlayerDetails;
    this.oPlayerClick = player;
    if(!this.bPlayerDetails) this.oPlayerClick = this.prenotatennisService.getNewBookingPlayerData(0, 0, '');
  }

  /******************************************/
  /****** Booking service *******************/
  /******************************************/

  getBookingCostData() {

    this.bLoadingPGCost = true;

    this.prenotatennisService.getBookingPriceDB(this.oBooking.id_playground, this.oBooking.id, this.prenotatennisService.settingsData.season, this.oBooking.hour_start, this.oBooking.hour_end, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.list && get_response.list.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile calcolare il prezzo' + ': ' + get_response.list.error);
          this.bBookingBlock = true;
        } else {

          this.playgroundCost = get_response.list;

          // Aggiorno il totale sulla prenotazione
          this.oBooking.price = this.playgroundCost.book_total_to_pay;
          if(this.oBooking.id !== 0) this.updateBooking(false);

          // Check credits for secretary closed hour
          if(this.oCheckBooking.secretary.secretary_closed) {
            // Secretary closed - check user credits
            if(this.prenotatennisService.userData.credits - this.oBooking.price < 0) {
              this.bBookingBlock = true;
              this.bFewCredits = true;
            }
          }

        }

        this.bLoadingPGCost = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingPGCost = false;
        this.bBookingBlock = true;
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
      });

  }

  getBookingPaymentListData() {

    this.bLoadingData = true;

    this.prenotatennisService.getBookingPaymentDB(this.oBooking.id, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.payment && get_response.payment.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile calcolare la lista dei pagamenti' + ': ' + get_response.payment.error);
        } else {
          this.aPayment = get_response.payment;

          this.quota_payed = 0;
          this.aPayment.map(pay => {
            this.quota_payed = this.quota_payed + pay.quote;
          });

        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
      });

  }

  getBookingDetails() {

    this.bLoadingData = true;

    this.prenotatennisService.getBookingDetailsDB(this.oBooking.id, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.booking && get_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i dati della prenotazione' + ': ' + get_response.booking.error);
          this.bLoadingData = false;
        } else {
          this.oBooking = get_response.booking[0];

          this.getPlaygroundData();
          if(this.prenotatennisService.settingsData.version_level == 'delux') this.getBookingCostData();
          this.getBookingAvailableUser();
          this.getBookingPaymentListData();

          // Check old booking
          this.checkTypeBooking();

        }

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto: ' + error);
      });


  }

  getBookingAvailableUser() {

    this.prenotatennisService.getBookingAvailableUserDB(this.oBooking.date_play, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.users && get_response.users.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare la lista utenti' + ': ' + get_response.users.error);
          this.bLoadingData = false;
        } else {
          this.aAvailableUser = get_response.users;
          // if check member code expiration
          if (this.prenotatennisService.settingsData.check_member_valid) this.aAvailableUser = this.aAvailableUser.filter(player => !player.member_expired);
          if (this.prenotatennisService.settingsData.check_cert_med) this.aAvailableUser = this.aAvailableUser.filter(player => !player.medical_expired);
          this.getBookingPLayers();
        }

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto: ' + error);
      });

  }

  getBookingPLayers() {

    this. bLoadingBookPlayerList = true;

    this.prenotatennisService.getBookingPlayerDB(this.oBooking.id, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.booking && get_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare la lista dei giocatori' + ': ' + get_response.booking.error);
        } else {

          this.aBookingPlayers = get_response.booking;
          let confirmedPLayer: Array<bookingPlayerData> = [];
          confirmedPLayer = this.aBookingPlayers.filter(x => x.status == 'CONFIRMED');
          this.confirmedPLayer = confirmedPLayer.length;

        }

        this. bLoadingBookPlayerList = false;
        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this. bLoadingBookPlayerList = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto: ' + error);
      });

  }

  insertBooking() {

    this.bLoadingData = true;

    this.oBooking.id_sport = this.oSport.id;
    this.oBooking.playground_name = this.oPlayground.name;
    this.oBooking.id_user = this.prenotatennisService.userData.id;
    this.oBooking.player_nr = this.oSport.player_nr;
    this.oBooking.price = this.playgroundCost && this.playgroundCost.book_total_to_pay ? this.playgroundCost.book_total_to_pay : 0;
    //if(this.oCheckBooking.secretary.secretary_closed) this.oBooking.confirmed = 1;
    this.oBooking.confirmed = 1;

    this.prenotatennisService.postInsertBookingDB(this.oBooking, this.prenotatennisService.userData.token).subscribe(insert_response => {

        if(insert_response.booking && insert_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile inserire la prenotazione: ' + insert_response.booking.error);
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Insert error: " + insert_response.booking.error));
        } else {

          this.oBooking.id = insert_response.booking.id;
          this.oBooking.code = insert_response.booking.code;

          // if user is member add to booking automatically
          if(this.prenotatennisService.userData.level == 'member') this.insertPlayerToBooking(this.prenotatennisService.userData.id, 'CONFIRMED');
          if(this.oCheckBooking.secretary.secretary_closed) this.insertPayment();
          if(!this.oCheckBooking.secretary.secretary_closed) this.getBookingDetails();

          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Insert success ID: " + insert_response.booking.id));

        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Insert server error: " + error));
      }
    );
  }

  insertPayment(quote_to_pay?: number) {

    this.bLoadingData = true;
    let quote: number = 0;
    quote = quote_to_pay ? quote_to_pay : this.playgroundCost.book_total_to_pay;

    let oPayment: paymentData = this.prenotatennisService.getNewPaymentData(this.oBooking.id);
    oPayment.payment_method = "CREDITS";
    oPayment.quote = quote;
    oPayment.id_user = this.prenotatennisService.userData.id;
    oPayment.total = quote;

    this.prenotatennisService.postInsertBookingPaymentDB(oPayment, this.prenotatennisService.userData.token).subscribe(insert_response => {

        if(insert_response.payment && insert_response.payment.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile registrare il pagamento' + ': ' + insert_response.payment.error);
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Insert payment error [quote "+ oPayment.quote +"]: " + insert_response.payment.error));
        } else {
          this.oCheckBooking = this.prenotatennisService.getNewCheckUserBookingData();
          this.getBookingDetails();
          this.getBookingPaymentListData();
          this.prenotatennisService.getUserLoginData();
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Insert payment success - quote "+ oPayment.quote +" - user " + oPayment.id_user));
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Insert payment server error [quote "+ oPayment.quote +"]: " + error));
      });
  }

  updateBooking(send_mail: boolean, redirectCommand?: boolean) {

    this.bLoadingData = true;

    this.prenotatennisService.postUpdateBookingDB(this.oBooking, send_mail, this.prenotatennisService.userData.token).subscribe(update_response => {

        if(update_response.booking && update_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile modificare la prenotazione: ' + update_response.booking.error);
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Update booking error ID " + this.oBooking.code + ": " + update_response.booking.error));
        } else {
          // Do nothing
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Update booking success ID " + this.oBooking.code));
        }

        if(redirectCommand) {
          this.prenotatennisService.oBooking = this.oBooking;
          if(this.prenotatennisService.settingsData.version_level == 'delux') this.router.navigate(['paga-prenotazione']);
          if(this.prenotatennisService.settingsData.version_level !== 'delux') this.router.navigate(['home']);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Update booking server error ID: " + this.oBooking.code + " - " + error));
      }
    );
  }

  confirmDeleteBooking(){

    const modalRef = this.modalService.open(AlertDialogComponent, { size: "xl" });
    modalRef.componentInstance.type = "confirm";
    modalRef.componentInstance.message = "Attenzione, annullando la prenotazione l'ora di gioco tornerà a disposizione per gli altri giocatori. Procedere?";

    modalRef.componentInstance.questionAnswer.subscribe((reciveEntry: boolean) => {
      if(reciveEntry){
        this.deleteBooking();
      }
    });

  }

  deleteBooking() {

    this.bLoadingData = true;

    this.prenotatennisService.postDeleteBookingDB(this.oBooking.id, '', this.prenotatennisService.userData.token).subscribe(update_response => {

        if(update_response.booking && update_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile eliminare la prenotazione: ' + update_response.booking.error);
          this.bLoadingData = false;
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Delete booking error ID " + this.oBooking.code + " - error: " + update_response.booking.error));
        } else {
          // All ok, redirect to home
          this.bLoadingData = false;
          this.router.navigate(['home']);
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Delete booking success ID " + this.oBooking.code));
        }

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Delete server error ID " + this.oBooking.code + " - error: " + error));
      }
    );

  }

  insertPlayerToBooking(player_id: number, status: string){

    this.bLoadingData = true;

    let oBookingPlayer: bookingPlayerData = this.prenotatennisService.getNewBookingPlayerData(this.oBooking.id, player_id, status);

    this.prenotatennisService.postInsertBookingPlayerDB(oBookingPlayer, this.prenotatennisService.userData.token).subscribe(insert_response => {

        if(insert_response.booking && insert_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile inserire il giocatore: ' + insert_response.booking.error);
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Insert player error code " + this.oBooking.code + " - usr ID " + oBookingPlayer.id_user));
        } else {
          this.getBookingPLayers();
          this.sendMail("CONFIRMED", oBookingPlayer);
          this.openDialog('success', 'Giocatore aggiunto con successo');
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Insert player success code " + this.oBooking.code + " - usr ID " + oBookingPlayer.id_user));
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Insert player server error code " + this.oBooking.code + " - usr ID " + oBookingPlayer.id_user + " - error: " + error));
      }
    );

  }

  updatePlayerToBooking(oBookingPlayer: bookingPlayerData){

    this.bLoadingData = true;

    this.prenotatennisService.postUpdateBookingPlayerDB(oBookingPlayer, this.prenotatennisService.userData.token).subscribe(update_response => {

        if(update_response.booking && update_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile aggiornare il giocatore: ' + update_response.booking.error);
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Update player code " + this.oBooking.code + " - usr ID " + oBookingPlayer.id_user));
        } else {
          this.getBookingPLayers();
          this.sendMail(oBookingPlayer.status, oBookingPlayer);
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Update player success code " + this.oBooking.code + " - usr ID " + oBookingPlayer.id_user));
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Update player server error code " + this.oBooking.code + " - usr ID " + oBookingPlayer.id_user + " - error: " + error));
      }
    );

  }

  deletePlayerToBooking(oBookingPlayer: bookingPlayerData){

    this.bLoadingData = true;

    this.prenotatennisService.postDeleteBookingPlayerDB(oBookingPlayer, this.prenotatennisService.userData.token).subscribe(delete_response => {

        if(delete_response.booking && delete_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile eliminare il giocatore: ' + delete_response.booking.error);
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Delete player code " + this.oBooking.code + " - usr ID " + oBookingPlayer.id_user));
        } else {
          this.getBookingPLayers();
          this.sendMail("REJECT", oBookingPlayer);
          this.oPlayerClick = this.prenotatennisService.getNewBookingPlayerData(0, 0, '');
          this.bPlayerDetails = false;
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Delete player success code " + this.oBooking.code + " - usr ID " + oBookingPlayer.id_user));
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Delete player server error code " + this.oBooking.code + " - usr ID " + oBookingPlayer.id_user + " - error: " + error));
      }
    );

  }

  insertServiceToBooking(service: moreServicePlaygroundData) {

    this.bLoadingData = true;

    let oBookingService: bookingServiceData = this.prenotatennisService.getNewBookingServiceData(this.oBooking.id, service.id_service);
    oBookingService.price = service.service_price ? service.service_price : 0;
    oBookingService.unatantum = service.service_unatantum ? service.service_unatantum : 0;
    oBookingService.quantity = 1;
    if(service.service_unatantum == 0) oBookingService.quantity = service.booking_qnt;

    // Se c'è già un pagamento verifico i crediti per un pagamento automatico
    if(this.quota_payed && this.prenotatennisService.userData.level !=='admin'){
      // Controllo che i crediti siano sufficienti
      if(this.prenotatennisService.userData.credits - (oBookingService.quantity * oBookingService.price) < 0) {
        this.openDialog('danger', 'Attenzione, crediti insufficienti per attivare il servizio');
        this.bLoadingData = false;
        return
      }
    }

    this.prenotatennisService.postInsertBookingServiceDB(oBookingService, this.prenotatennisService.userData.token).subscribe(insert_response => {

        if(insert_response.booking && insert_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile inserire il servizio: ' + insert_response.booking.error);
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Insert service code " + this.oBooking.code + " - service ID " + service.id_service));
        } else {

          if(this.quota_payed && this.prenotatennisService.userData.level !=='admin'){
            // Aggiorno il costo della prenotazione
            this.updateBooking(true);
            this.insertPayment(oBookingService.quantity * oBookingService.price);
          }

          this.getBookingServiceData();
          this.getBookingCostData();
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Insert service success code " + this.oBooking.code + " - service ID " + service.id_service));
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Insert service server error code " + this.oBooking.code + " - service ID " + service.id_service + " - error: " + error));
      }
    );

  }

  deleteServiceToBooking(service: moreServicePlaygroundData) {

    if(this.quota_payed > 0 && this.prenotatennisService.userData.level !=='admin') return;

    this.bLoadingData = true;

    let oBookingService: bookingServiceData = this.prenotatennisService.getNewBookingServiceData(this.oBooking.id, service.id_service);
    oBookingService.id = service.booking_service_table_id ? service.booking_service_table_id : 0;

    this.prenotatennisService.postDeleteBookingServiceDB(oBookingService, this.prenotatennisService.userData.token).subscribe(insert_response => {

        if(insert_response.booking && insert_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile eliminare il servizio: ' + insert_response.booking.error);
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Delete service code " + this.oBooking.code + " - service ID " + service.id_service));
        } else {
          this.getBookingServiceData();
          this.getBookingCostData();
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Delete service success code " + this.oBooking.code + " - service ID " + service.id_service));
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Delete service server error code " + this.oBooking.code + " - service ID " + service.id_service + " - error: " + error));
      }
    );


  }

  getPlayLevelDescription(user_play_level: number){
    let aLevel: Array<any> = [];
    aLevel = this.prenotatennisService.settingsData.player_levels.filter((x: any)=> x.id == user_play_level);
    if(aLevel && aLevel.length > 0) return aLevel[0].description;
    return "";
  }

  sendMail(type: string, oBookingPlayer: bookingPlayerData) {

    this.bLoadingData = true;

    let oMail: bookingShareMailData = {
      dest_mail: oBookingPlayer.email,
      owner_name: this.oBooking.name ? this.oBooking.name : '',
      player_surname: oBookingPlayer.player_surname?oBookingPlayer.player_surname:'',
      player_name: oBookingPlayer.player_name?oBookingPlayer.player_name:'',
      player_game_level: this.getPlayLevelDescription(oBookingPlayer.player_game_level),
      data_play: this.oBooking.date_play,
      hour_start: this.oBooking.hour_start,
      hour_end: this.oBooking.hour_end,
      playground_name: this.oBooking.playground_name,
      type: type
    }

    this.prenotatennisService.postShareBookingEmailDB(oMail, this.prenotatennisService.userData.token).subscribe(insert_response => {

        if(insert_response.booking && insert_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile inviare la mail automatica: ' + insert_response.booking.error);
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Send Mail error " + type + " code " + this.oBooking.code + " - destination " + oMail.dest_mail));
        } else {
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Send Mail success " + type + " code " + this.oBooking.code + " - destination " + oMail.dest_mail));
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.logService.callInsertLog(this.prenotatennisService.getNewLogData("booking", "Send Mail server error " + type + " code " + this.oBooking.code + " - destination " + oMail.dest_mail + " error: " + error));
      }
    );

  }

  newMultipleBooking(multiplecode: string) {

    let dayOfWeek: number = 0;
    dayOfWeek = new Date(this.oBooking.date_play).getDay();

    let oMultipleBooking: bookingMultipleData = this.prenotatennisService.getNewBookingMultipleData();
    oMultipleBooking.id_playground = this.oBooking.id_playground;
    oMultipleBooking.day_of_week = dayOfWeek;
    oMultipleBooking.playground_name = this.oBooking.playground_name;
    oMultipleBooking.from_dt = this.oBooking.date_play;
    oMultipleBooking.code = "";

    const modalRef = this.modalService.open(BookingMultipleComponent, { size: 'xl' });
    modalRef.componentInstance.oMultipleBooking = oMultipleBooking;
    modalRef.componentInstance.bookingCode = multiplecode;
    modalRef.componentInstance.saveAnswer.subscribe((confirmation: boolean) => {
      if(confirmation) {

      }
    });

  }

}
