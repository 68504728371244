import { Component, OnInit } from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {userData} from "../../interfaces/interfaces";
import {Router} from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {RecoveryPasswordComponent} from "../recovery-password/recovery-password.component";
import {RegisterUserComponent} from "../register-user/register-user.component";
import {LogService} from "../../services/log.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public userData: userData = this.prenotatennisService.getNewUserData();
  public oUserLogin = {
    user_name: "",
    password: ""
  }
  public bLoadingData: boolean = false;

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              private router:Router, private logService: LogService) { }

  ngOnInit(): void {}

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  login() {

    this.bLoadingData = true;

    if(this.oUserLogin.user_name == '' || this.oUserLogin.password == '') {
      this.openDialog('danger', 'Attenzione tutti i campi sono obbligatori');
      this.bLoadingData = false;
    } else {
      // Login call
      this.prenotatennisService.postLoginUserDB(this.oUserLogin).subscribe(login_response => {

          if(login_response.usr && login_response.usr.error) {
            //Error
            this.prenotatennisService.bLoggedIn = false;
            this.openDialog('danger', 'Attenzione, impossibile accedere alla App' + ': ' + login_response.usr.error);
            this.logService.callInsertLog(this.prenotatennisService.getNewLogData("login user", "Login error: " + this.oUserLogin.user_name));
          } else {

            this.userData = login_response.usr[0];
            this.prenotatennisService.setUserLoginData(login_response.usr[0]);
            this.logService.callInsertLog(this.prenotatennisService.getNewLogData("login user", "Login success: " + this.oUserLogin.user_name));
            this.prenotatennisService.bLoggedIn = true;
            this.oUserLogin = {
              user_name: "",
              password: ""
            };
            window.scroll(0,0);
          }

          this.bLoadingData = false;

        },
        error => {
          // API call error
          console.log(error);
          this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
          this.bLoadingData = false;
        });

    }
  }

  logoutUser() {
    this.bLoadingData = true;
    this.logService.callInsertLog(this.prenotatennisService.getNewLogData("logout user", "Logout user: " + this.prenotatennisService.userData.username));
    this.prenotatennisService.bLoggedIn = false;
    this.prenotatennisService.getUserLogoutData();
    this.router.navigate(['/home']);
  }

  recoveryPassword() {
    const modalRef = this.modalService.open(RecoveryPasswordComponent, {size: 'md'});
  }

  registerNewUser() {
    const modalRef = this.modalService.open(RegisterUserComponent, {size: 'xl'});
  }

}
