<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header bg-primary text-white">
  <h4>Prenota tutto il giorno</h4>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <h4>Campo di riferimento: {{oAllDayBooking.playground_name}}</h4>
    </div>
    <div class="col-12">
      Confermando saranno prenotate tutte le ore libera della giornata a nome della segreteria.
    </div>
    <div class="col-12 mt-2">
      <div><strong>Data: </strong> {{oAllDayBooking.play_date | date: "dd/MM/yyyy"}}</div>
      <div><strong>Campo: </strong> {{oAllDayBooking.playground_name}}</div>
      <div class="mt-3">
        <label>Motivazione (*)</label>
        <input type="text" placeholder="Breve motivazione" class="form-control" [(ngModel)]="oAllDayBooking.notes">
      </div>
    </div>
    <div class="col-12 mt-2" *ngIf="oAllDayBooking.hours.length == 0">
      <div class="alert alert-warning">
        Attenzione, campo senza ore disponibili.
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-success" (click)="runAllDayBooking()" [disabled]="oAllDayBooking.hours.length == 0 || oAllDayBooking.notes == ''">CONFERMA</button>
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('')">CHIUDI</button>
</div>
