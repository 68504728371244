<app-loader *ngIf="bLoadingData || bLoadingPlayground"></app-loader>

<div class="pb-5">
  <div>
    <div class="components mb-4">

      <!--------------------------------------------------------->
      <!------------ BOOKING DETAILS ---------------------------->
      <!--------------------------------------------------------->
      <!-- General data for booking -->
      <section id="bookDetails" >
        <div class="row m-0 p-2 border rounded bg-light">
          <div class="col-12 border-bottom pb-1">

            <div class="d-flex align-items-center justify-content-center">
              <div class="flex-fill">
                <label>Prenotazione: </label>
                <span *ngIf="oBooking && oBooking.id !== 0">
                  <span>{{oBooking.code}}</span>
                  <div class="small" *ngIf="oBooking.multiple_code && prenotatennisService.userData.level=='admin'">
                    <label>Multipla</label>: <a class="link-primary pointer" (click)="newMultipleBooking(oBooking.multiple_code)">{{oBooking.multiple_code}}</a>
                  </div>
                </span>
                <div *ngIf="oBooking && oBooking.id == 0">Nuova prenotazione</div>
              </div>
              <div class="flex-fill text-end" *ngIf="oBooking.id !== 0">
                <button class="btn btn-sm me-1" *ngIf="bTypeBooking == 'MINE'" [class]="{'btn-success': oBooking.public, 'btn-secondary': !oBooking.public}" (click)="shareBooking()">CONDIVISA</button>
                <button class="btn btn-sm" *ngIf="prenotatennisService.userData.level == 'admin'"
                        [class]="{'btn-success': oBooking.teacher, 'btn-secondary': !oBooking.teacher}"
                        (click)="teacherBooking()">MAESTRO</button>
              </div>
            </div>

          </div>
          <div class="col-12 pt-1">
            <strong>{{oBooking.playground_name}}</strong> (<span class="small">{{oSport.description}}</span>)
          </div>
          <div class="col-6">
            <div class="p-1">
              <i class="fa fa-calendar-alt me-2"></i>
              <span>{{oBooking.date_play | date: 'dd/MM/yyyy'}}</span>
            </div>
            <div class="p-1">
              <i class="fa fa-clock-four me-2"></i>
              <span>{{oBooking.hour_start | timeFormat}} - {{oBooking.hour_end | timeFormat}}</span>
            </div>
          </div>
          <div class="col-6">
            <!-- Check conferma -->
            <div class="p-1">
              <span *ngIf="oBooking && oBooking.confirmed !== 0"><i class="fa fa-check-circle text-success"></i> CONFERMATA</span>
              <span *ngIf="oBooking && oBooking.confirmed == 0"><i class="fa fa-triangle-exclamation text-warning"></i> DA CONFERMARE</span>
            </div>
            <!-- Check completa -->
            <div class="p-1">
                <span *ngIf="oSport && (confirmedPLayer == oSport.player_min_nr || confirmedPLayer == oSport.player_nr)">
                  <i class="fa fa-check-circle text-success"></i> COMPLETA
                </span>
                <span *ngIf="oSport && (confirmedPLayer !== oSport.player_min_nr && confirmedPLayer !== oSport.player_nr)">
                  <i class="fa fa-triangle-exclamation text-warning"></i> GIOCATORI
                </span>
              <div class="small text-muted ms-2">({{oSport.player_min_nr}} o {{oSport.player_nr}} giocatori)</div>
            </div>
          </div>
          <div class="col-12 mt-2 border-top py-2" *ngIf="prenotatennisService.userData.level == 'admin'">
            <label>Note</label>
            <div class="d-flex">
              <div class="me-1 flex-fill"><input type="text" class="form-control" [(ngModel)]="oBooking.note"></div>
              <div class="col-1 text-center">
                <button class="btn btn-success" (click)="updateBooking(false)" *ngIf="oBooking.id !== 0"><i class="fa fa-save"></i></button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- CHECK BOOKING CONSTRAINTS -->
      <div class="alert alert-danger p-2 mt-3" *ngIf="bBookingBlock && bTypeBooking=='MINE'">
        <h4>Attenzione impossibile prenotare</h4>
        Non ti è possibile prenotare quest'ora di gioco per un errore di sistema oppure perché ci sono dei vincoli.
        <ul>
          <li *ngIf="oCheckBooking.block.medical_expiration">Il tuo certificato medico risulta scaduto per questa ora di gioco</li>
          <li *ngIf="oCheckBooking.block.member_expiration">La tua tessera socio risulta scaduta per questa ora di gioco</li>
          <li *ngIf="oCheckBooking.block.same_day">Puoi prenotare solo da domani in poi</li>
          <li *ngIf="oCheckBooking.block.nr_booking_week">
            Non puoi fare più di 2 prenotazioni a settimana
            dal {{prenotatennisService.settingsData.constraints.constraints_date_start | date: "dd/MM/yyyy"}}
            al {{prenotatennisService.settingsData.constraints.constraints_date_end | date: "dd/MM/yyyy"}}
            dalle {{prenotatennisService.settingsData.constraints.constraints_hour_start}}
            alle {{prenotatennisService.settingsData.constraints.constraints_hour_end}}
          </li>
          <li *ngIf="bFewCredits">
            L'ora può essere prenotata solo con pagamento automatico in crediti e purtroppo non disponi di abbastanza crediti per procedere
          </li>
        </ul>
      </div>

      <!-- Hour end play -->
      <section id="bookingCreate" *ngIf="!bBookingBlock">
        <div class="row m-0 p-2 border mt-2" *ngIf="oBooking && oBooking.id == 0">
          <div class="col-12" *ngIf="oCheckBooking.secretary.secretary_closed">
            <!-- ***************************** -->
            <!-- *** CHECK SECRETARY CLOSED ** -->
            <!-- ***************************** -->
            <div class="alert alert-warning">
              <h3>Attenzione, segreteria chiusa</h3>
              Questa ora di gioco è prenotabile solo con crediti che saranno scaricati in modo automatico alla conferma della prenotazione
            </div>
          </div>
          <div [ngSwitch]="prenotatennisService.settingsData.hour_selection">
            <!--------------------------------------------------------->
            <!------------ MORE SELECTION ----------------------------->
            <!--------------------------------------------------------->
            <div *ngSwitchCase="'more'">
              <div class="col-12 text-center">
                <h2>Fino a che ora vuoi giocare?</h2>

                <div class="d-flex flex-wrap justify-content-center">
                  <div *ngIf="bLoadingHours"><i class="fa fa-spinner fa-spin"></i> Calcolo ore libere in corso..</div>
                  <div class="p-2 me-2 mt-2 bg-success text-white pointer" *ngFor="let hour of aAvailableHours" (click)="changeHourSelection(hour)">
                    <h5>{{hour}}</h5>
                  </div>
                </div>

                <!--<div class="d-flex flex-column justify-content-center align-items-center">
                  <div class="col-6 col-lg-3 text-center">
                    <select class="form-control text-center" [(ngModel)]="oBooking && oBooking.hour_end" (change)="changeHourSelection()">
                      <option *ngFor="let hour of aAvailableHours">{{hour}}</option>
                    </select>
                  </div>
                  <div class="col-6 col-lg-3 mt-2 text-center">
                    <button class="btn btn-lg btn-success" (click)="confirmStep(2, true)">PRENOTA ORA</button>
                  </div>
                </div>-->
              </div>
            </div>
            <!--------------------------------------------------------->
            <!------------ STANDARD SELECTION ------------------------->
            <!--------------------------------------------------------->
            <div *ngSwitchCase="'standard'">
              <div class="col-12 text-center">
                <h2>Confermi di voler prenotare l'ora?</h2>

                <div class="d-flex justify-content-center align-items-center">
                  <div class="col-12">
                    <span class="fs-1 fw_bold">{{ oBooking.hour_start }} - {{ oBooking.hour_end }}</span>
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                  <div class="mt-2 text-center">
                    <button class="btn btn-lg btn-success" (click)="confirmStep(2, true)">PRENOTA ORA</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Player list & More services -->
      <div class="row g-2 border rounded p-2 bg-light mt-2" *ngIf="iStepper == 2 && oBooking && oBooking.id !== 0">
        <!-- PLAYER LIST -->
        <div class="col-12" [class]="aServicePlayground.length > 0 ? 'col-lg-6': ''">
          <section id="bookingUser" class=" mt-3">
            <div class="row mt-2">
              <div class="text-center">
                <h2>Giocatori</h2>
              </div>

              <div class="mt-2 d-flex justify-content-center align-items-center" *ngIf="bTypeBooking == 'MINE'">
                <div class="col-6 p-1">
                  <div class="d-grid gap-2">
                    <button class="btn btn-success" (click)="openPlayerList()">
                      <i class="fa fa-plus"></i> Nuovo giocatore
                    </button>
                  </div>
                </div>
              </div>

              <div class="mt-3" *ngIf="aBookingPlayers.length == 0 && !bLoadingBookPlayerList">
                <div class="text-center mt-2">
                  <h5>Nessun giocatore confermato</h5>
                </div>
              </div>

              <div class="fs-4 text-center mt-2" *ngIf="bLoadingBookPlayerList">
                <i class="fa fa-spin fa-spinner"></i> Caricamento in corso ...
              </div>

              <div class="row py-4">
                <div class="col-6 col-lg-3 text-center flex-fill" *ngFor="let player of aBookingPlayers">
                  <div class="pointer"> <!-- (click)="showDetails(player)"-->
                    <div class="prev-prof-img border border border-3"
                         [class]="{'border-warning': player.status == 'INS', 'border-success': player.status == 'CONFIRMED', 'border-danger': player.status == 'REJECT'}"
                         [ngStyle]="{'background-image' : 'url(data:image/jpeg;base64,'+ player.profile_image +')'}">

                    </div>
                    <div *ngIf="prenotatennisService.userData.level !== 'admin'">
                      <div *ngIf="player.nickname" class="user-name text-center" [ngbTooltip]="player.player_surname + ' ' + player.player_name">{{player.nickname.length>10?(player.nickname | slice:0:10)+'...' : player.nickname}}</div>
                      <div *ngIf="!player.nickname"  class="user-name text-center" [ngbTooltip]="player.player_name">{{player.player_name!.length>10?(player.player_name | slice:0:10)+'...' : player.player_name}}</div>
                    </div>
                    <div *ngIf="prenotatennisService.userData.level == 'admin'">
                      <div class="user-name text-center">{{player.player_surname + ' ' + player.player_name}}</div>
                    </div>

                    <div class="p-2 bg-light text-center">

                      <div class="btn-group">
                        <div type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="fa fa-ellipsis-h"></i>
                        </div>
                        <ul class="dropdown-menu">
                          <li><a class="dropdown-item">{{getPlayLevelDescription(player.player_game_level)}}</a></li>
                          <li><hr class="dropdown-divider"></li>
                          <a class="dropdown-item text-danger" *ngIf="player.status !== 'INS' && bTypeBooking == 'MINE'" (click)="deletePlayerToBooking(player)"><i class="fa fa-trash me-1"></i>Elimina</a>
                          <a class="dropdown-item" *ngIf="(player.status == 'INS' || player.status == 'REJECT') && bTypeBooking == 'MINE'" (click)="changePlayerStatus(player, 'CONFIRMED')"><i class="fa fa-check-circle me-1"></i>Approva</a>
                          <a class="dropdown-item" *ngIf="(player.status == 'INS' || player.status == 'CONFIRMED') && bTypeBooking == 'MINE'" (click)="changePlayerStatus(player, 'REJECT')"><i class="fa fa-ban me-1"></i>Respingi</a>
                          <a class="dropdown-item" [href]="'https://wa.me/'+ player.player_phone +'?text=partita%20del%20' + oBooking.date_play + '%20ore%20' + oBooking.hour_start" *ngIf="player.whatsapp" target="_blank"><i class="fa-brands fa-whatsapp me-1"></i>Chatta</a>
                        </ul>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>
        </div>
        <!-- MORE SERVICE -->
        <div class="col-12 col-lg-6">
          <section id="bookingService" class="mt-3" *ngIf="aServicePlayground.length > 0">
            <!-- Playground Services -->

            <div class="mt-2 text-center">
              <h2>Servizi aggiuntivi</h2>
            </div>

            <div class="row mt-2">
              <div class="col-12 mt-2 px-4">
                <div class="d-flex flex-wrap">
                  <div class="alert col-12 me-2 pointer" [class]="{'alert-secondary': !service.booking_active, 'alert-success': service.booking_active}" *ngFor="let service of aServicePlayground">

                    <div class="row">
                      <div class="col-8">
                        <strong><span *ngIf="service.booking_active">{{service.booking_qnt}} X </span>{{service.service_title}}</strong>
                        <div>
                          <span *ngIf="!service.booking_price">[{{service.service_price | number : '1.2-2'}} €<span *ngIf="service.service_unatantum == 0">/persona</span>]</span>
                          <span *ngIf="service.booking_price">[{{service.booking_price | number : '1.2-2'}} €<span *ngIf="service.service_unatantum == 0">/persona</span>]</span>
                        </div>
                        <div class="small text-muted">{{service.service_description}}</div>
                      </div>
                      <div class="col-4 d-flex flex-column justify-content-center align-items-center">
                        <div class="form-group row text-center" *ngIf="bTypeBooking == 'MINE'">
                          <div class="col-12 mb-2 d-flex align-items-center" *ngIf="service.service_unatantum == 0 && !service.booking_active">
                            <span class="fas fa-circle-chevron-left fa-2x mx-3" [ngClass]="service.booking_qnt<=1?'opacity-50 disabled':''" (click)="service.booking_qnt>1 ? service.booking_qnt=service.booking_qnt-1 : false"></span>
                            <div type="number" class="form-control text-center" >{{service.booking_qnt}}</div>
                            <span class="fas fa-circle-chevron-right fa-2x mx-3" (click)="service.booking_qnt=service.booking_qnt+1"></span>

                          </div>
                          <div class="col-12">
                            <button class="btn btn-sm" [class]="{'btn-success': !service.booking_active, 'btn-danger': service.booking_active}" (click)="saveBookingService(service)">
                              <span *ngIf="!service.booking_active">AGGIUNGI</span>
                              @if(service.booking_active && (!quota_payed || quota_payed < 0 || prenotatennisService.userData.level == 'admin')) {
                                <span>RIMUOVI</span>
                              } @else if(service.booking_active && quota_payed > 0) {
                                PER RIMUOVERE CONTATTA SEGRETERIA
                              }
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </section>
        </div>
      </div>


      <!-- Payment info for DELUXE version -->
      <section id="paymentSection">
        <div class="row mt-3"  *ngIf="prenotatennisService.settingsData.version_level == 'delux' && playgroundCost">
          <div class="col-12">
            <div class="d-flex align-items-stretch align-items-center">
              <div class="p-2 border border-success text-center me-2 flex-fill">
                <div class="fs-2 text-success mt-3">
                  <span *ngIf="bLoadingPGCost"><i class="fa fa-spin fa-spinner"></i> Caricamento costi...</span>
                  {{playgroundCost?.book_total_to_pay | number: '1.2-2'}} €
                </div>
                <span class="small">Costo</span>
                <div class="small text-muted"> 1 ora: {{playgroundCost?.one_hour_price | number: '1.2-2'}}€</div>
              </div>
              <div class="p-2 border text-left flex-fill" *ngIf="playgroundCost">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>Quota campo:</td>
                      <td class="text-end"><span class="fw-bold">{{playgroundCost.total_price | number: '1.2-2'}}€</span></td>
                    </tr>
                    <tr>
                      <td>Servizi aggiuntivi:</td>
                      <td class="text-end"><span class="fw-bold">{{playgroundCost.total_service | number: '1.2-2'}}€</span></td>
                    </tr>
                    <tr>
                      <td>Quota pagata: </td>
                      <td class="text-end"><span class="fw-bold">{{quota_payed | number: '1.2-2'}}€</span></td>
                    </tr>
                  </tbody>
                </table>
                <div class="text-center mt-2">
                  <button class="btn btn-sm btn-primary" (click)="gotoPayments()" *ngIf="oBooking && oBooking.confirmed == 1">PAGAMENTI</button>
                </div>
              </div>
            </div>
            <div class="alert alert-warning" *ngIf="quota_payed > 0">
              Attenzione, sono presenti dei pagamenti tutte le prossime modifiche saranno addebitate direttamente.
            </div>
          </div>
        </div>
      </section>

      <div class="mt-3 p-2 text-center">
        <button class="btn btn-sm btn-danger col-4 me-3" *ngIf="oBooking.id !== 0 && prenotatennisService.userData.level == 'admin'" (click)="confirmDeleteBooking()">CANCELLA PRENOTAZIONE</button>
        <button class="btn btn-primary col-4" (click)="closeDetails()">CALENDARIO</button>
        <!--<button class="btn btn-success col-4" *ngIf="!oBooking.confirmed && oBooking.id !== 0" (click)="confirmBooking()">CONFERMA</button>-->
      </div>

    </div>
  </div>
</div>

<div class="pb-5">
  <!-- Space for menu bug fix -->
</div>
