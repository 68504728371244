import { Component, OnInit } from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public appVersion = environment.version;
  public appBuilding = environment.building;
  public appRevision = environment.revision;
  public currentYear: Date = new Date();

  constructor(public prenotatennisService: PrenotatennisService) { }

  ngOnInit(): void {
  }

}
