<div class="main-container container-fluid d-flex flex-column align-items-center p-0 ">
  <div class="row w-100 mb-2" [class]="route=='/public-calendar'?'d-none': ''">
    <div class="col-12 p-0 text-center">
      <app-header></app-header>
    </div>
  </div>
  <div class="row container p-0 flex-grow-1">
    <div class="col-12">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="footer-wrapper row mt-3" [class]="route=='/public-calendar'?'d-none': ''">
    <div class="col-12">
      <app-footer></app-footer>
    </div>
  </div>
</div>
