import { Component, OnInit } from '@angular/core';
import {calendarData, calendarDaysData} from "../../interfaces/interfaces";
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../../components/alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-calendar-public',
  templateUrl: './calendar-public.component.html',
  styleUrls: ['./calendar-public.component.scss']
})
export class CalendarPublicComponent implements OnInit {

  public bLoadingData: boolean = false;

  public aCalendarDays: Array<calendarDaysData> = [];
  public aCalendarData: Array<calendarData> = [];

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.prenotatennisService.oBookSelected = null;
    this.getCalendarDaysData();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getDayDescription(play_date: string) {
    let pd: Date = new Date(play_date);
    return this.prenotatennisService.getWeekDayDescription(pd.getDay());
  }

  getCalendarDaysData() {

    let from: Date = new Date();
    this.prenotatennisService.memberFilterBooking.date_from = from.toISOString().slice(0, 10);

    this.bLoadingData = true;

    this.prenotatennisService.getCalendarPublicDaysListDB(this.prenotatennisService.memberFilterBooking.date_from, this.prenotatennisService.settingsData.user_day_to_view, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.dashboard && get_response.dashboard.error) {
          //Error
          this.aCalendarDays = [];
          this.openDialog('danger', 'Attenzione, impossibile caricare il calendario' + ': ' + get_response.dashboard.error);
        } else {

          this.aCalendarDays = get_response.dashboard;

          this.getCalendarData();

        }

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  getCalendarData() {

    this.bLoadingData = true;

    this.prenotatennisService.getCalendarPublicListDB(this.prenotatennisService.memberFilterBooking.date_from, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.dashboard && get_response.dashboard.error) {
          //Error
          this.aCalendarData = [];
          this.openDialog('danger', 'Attenzione, impossibile caricare il calendario' + ': ' + get_response.dashboard.error);
        } else {
          this.aCalendarData = get_response.dashboard;

          this.aCalendarData.map(x=> {
            x.playground.map(y => {
              let date_format: Date = new Date(y.date);
              y.date = date_format.toISOString()
            });
          });

        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  scrollElement(element:string, direction:string, amount:number){

    const el = document.getElementById(element)
    if(direction=='right') el!.scrollLeft += amount;
    if(direction=='left') el!.scrollLeft -= amount;
  }

  selectDayToView(calendarDay: calendarDaysData) {
    if(calendarDay.club_closure) return;
    this.prenotatennisService.memberFilterBooking.date_from = calendarDay.play_date;
    this.getCalendarData();
  }

}
