import {APP_INITIALIZER, NgModule} from '@angular/core';
import {DatePipe} from "@angular/common";
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {FormsModule} from "@angular/forms";
import {PrenotatennisService} from "./services/prenotatennis.service";
import {environment} from "../environments/environment";
import { HomepageComponent } from './pages/homepage/homepage.component';
import { Error404Component } from './components/error404/error404.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { RecoveryPasswordComponent } from './components/recovery-password/recovery-password.component';
import { RegisterUserComponent } from './components/register-user/register-user.component';
import { MenuComponent } from './components/menu/menu.component';
import { LoaderComponent } from './components/loader/loader.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { PartnerComponent } from './pages/partner/partner.component';
import { PlaygroundComponent } from './pages/playground/playground.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { PlaygroundEditComponent } from './components/playground-edit/playground-edit.component';
import { MoreServiceComponent } from './pages/more-service/more-service.component';
import { MoreServiceEditComponent } from './components/more-service-edit/more-service-edit.component';
import { PriceListEditComponent } from './components/price-list-edit/price-list-edit.component';
import { SettingsClosureComponent } from './components/settings-closure/settings-closure.component';
import { SettingsSeasonComponent } from './components/settings-season/settings-season.component';
import { SettingsConstraintsComponent } from './components/settings-constraints/settings-constraints.component';
import { SettingsActiveConstraintsComponent } from './components/settings-active-constraints/settings-active-constraints.component';
import { PartnerProfileComponent } from './pages/partner-profile/partner-profile.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BookingComponent } from './components/booking/booking.component';
import { PlayerListComponent } from './components/player-list/player-list.component';
import { BookingPayComponent } from './pages/booking-pay/booking-pay.component';
import { BookingMultipleComponent } from './components/booking-multiple/booking-multiple.component';
import { BuyCreditComponent } from './components/buy-credit/buy-credit.component';
import { PaymentCompleteComponent } from './pages/payment-complete/payment-complete.component';
import { PaymentCanceledComponent } from './pages/payment-canceled/payment-canceled.component';
import { BookingListComponent } from './components/booking-list/booking-list.component';
import { SharedBookingComponent } from './pages/shared-booking/shared-booking.component';
import { ShareBookingFastConfirmComponent } from './pages/share-booking-fast-confirm/share-booking-fast-confirm.component';
import { BookingAlldayComponent } from './components/booking-allday/booking-allday.component';
import { BlacklistComponent } from './pages/blacklist/blacklist.component';
import { BlacklistEditComponent } from './components/blacklist-edit/blacklist-edit.component';
import { CalendarMemberComponent } from './components/calendar-member/calendar-member.component';
import {CalendarPublicComponent} from "./pages/calendar-public/calendar-public.component";
import { TimeFormatPipe } from './pipe/time-format.pipe';

@NgModule({ declarations: [
        AppComponent,
        HomepageComponent,
        Error404Component,
        HeaderComponent,
        FooterComponent,
        LoginComponent,
        AlertDialogComponent,
        RecoveryPasswordComponent,
        RegisterUserComponent,
        MenuComponent,
        LoaderComponent,
        UserProfileComponent,
        PartnerComponent,
        PlaygroundComponent,
        SettingsComponent,
        CalendarComponent,
        PlaygroundEditComponent,
        MoreServiceComponent,
        MoreServiceEditComponent,
        PriceListEditComponent,
        SettingsClosureComponent,
        SettingsSeasonComponent,
        SettingsConstraintsComponent,
        SettingsActiveConstraintsComponent,
        PartnerProfileComponent,
        BookingComponent,
        PlayerListComponent,
        BookingPayComponent,
        BookingMultipleComponent,
        BuyCreditComponent,
        PaymentCompleteComponent,
        PaymentCanceledComponent,
        BookingListComponent,
        SharedBookingComponent,
        ShareBookingFastConfirmComponent,
        BookingAlldayComponent,
        BlacklistComponent,
        BlacklistEditComponent,
        CalendarMemberComponent,
        CalendarPublicComponent,
        TimeFormatPipe
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
    ], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            multi: true,
            deps: [HttpClient, PrenotatennisService]
        },
        DatePipe,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }

export function initApp(http: HttpClient, prenotatennisService: PrenotatennisService) {
  return () => {
    return http.get(environment.apiUrl + 'get_current_settings')
      .toPromise()
      .then((resp: any) => {
        prenotatennisService.setSettings(resp.settings);
      });
  };
}
