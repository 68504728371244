import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {bookingData, bookingMultipleData, playerData, playgroundData} from "../../interfaces/interfaces";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DatePipe} from "@angular/common";
import {PlayerListComponent} from "../player-list/player-list.component";

@Component({
  selector: 'app-booking-multiple',
  templateUrl: './booking-multiple.component.html',
  styleUrls: ['./booking-multiple.component.scss']
})
export class BookingMultipleComponent implements OnInit {

  @Input() oMultipleBooking: bookingMultipleData =  this.prenotatennisService.getNewBookingMultipleData();
  @Input() bookingCode: string = "";
  @Output() saveAnswer = new EventEmitter<boolean>();

  public bLoadingData: boolean = false;

  public aAvailableUser: Array<playerData> = [];
  public aSelectedUsers: Array<playerData> = [];

  public player_selected: number = 0;
  public oCreatingResponse: any = null;
  public aMultipleBooking: Array<bookingData> = [];
  public oViewType: any = {
    menu: true,
    new_booking: false,
    search_booking: false
  }

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    let date: any = new DatePipe('en-EN').transform(this.oMultipleBooking.from_dt, 'yyyy-MM-dd');
    this.oMultipleBooking.from_dt = date ? date: '';
    this.getBookingAvailableUser();
    if(this.bookingCode !== '') {
      this.setViewSection('SEARCH');
      this.searchMultiBooking();
    }
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getBookingAvailableUser() {

    this.prenotatennisService.getBookingAvailableUserDB(this.oMultipleBooking.from_dt, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.users && get_response.users.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare la lista utenti' + ': ' + get_response.users.error);
          this.bLoadingData = false;
        } else {
          this.aAvailableUser = get_response.users;
        }

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto: ' + error);
      });

  }

  openPlayerList(openPopUp?: boolean){

    if(openPopUp) {

      this.oMultipleBooking.admin_user = false;
      this.aSelectedUsers = [];

      const modalRef = this.modalService.open(PlayerListComponent, { size: 'xl' });
      modalRef.componentInstance.pladate_play = this.oMultipleBooking.from_dt;
      modalRef.componentInstance.playerIDAnswer.subscribe((confirmation: number) => {
        if(confirmation) {
          this.player_selected = confirmation;
          this.addPlayer();
        }
      });
    }
    if(!openPopUp){
      // Utente segreteria
      this.oMultipleBooking.admin_user = true;
      this.aSelectedUsers = [];

      let segreteriaUser: playerData = {
        id: this.prenotatennisService.userData.id,
        name: this.prenotatennisService.userData.name,
        surname: this.prenotatennisService.userData.surname,
        nickname: this.prenotatennisService.userData.nickname,
        profile_image: this.prenotatennisService.userData.profile_image,
        member_code: this.prenotatennisService.userData.member_code,
        member_expiry: '',
        member_expired: false,
        medical_expiry: '',
        medical_expired: false,
        email: this.prenotatennisService.userData.email,
        status: 1,
        level: 'admin',
        game_level: 0
      }

      this.aSelectedUsers.push(segreteriaUser);

    }

  }

  addPlayer() {

    let aFound: Array<playerData> = [];
    let aPlayer: Array<playerData> = [];
    aFound = this.aSelectedUsers.filter(x => x.id == this.player_selected);
    aPlayer = this.aAvailableUser.filter(x => x.id == this.player_selected);

    if(aFound.length > 0) this.aSelectedUsers = this.aSelectedUsers.filter(x => x.id !== this.player_selected);
    if(aFound.length == 0 && aPlayer.length > 0) this.aSelectedUsers.push(aPlayer[0]);

    this.player_selected = 0;

  }

  removePlayerSelected(id_user: number) {
    this.aSelectedUsers = this.aSelectedUsers.filter(x => x.id !== id_user);
  }

  checkMultipleBookingData() {
    if(!this.oMultipleBooking.from_dt) return false;
    if(!this.oMultipleBooking.to_dt) return false;
    if(!this.oMultipleBooking.start_hour) return false;
    if(!this.aSelectedUsers || this.aSelectedUsers.length == 0) return false;

    return true;
  }

  runMultipleBooking() {

    if(!this.checkMultipleBookingData()){
      this.openDialog('danger', 'Attenzione, compilare tutti i dati richiesti');
      return
    }

    this.oMultipleBooking.id_user = this.aSelectedUsers[0].id;

    this.prenotatennisService.postInsertMultipleBookingDB(this.oMultipleBooking, this.prenotatennisService.userData.token).subscribe(insert_response => {

        if(insert_response.booking && insert_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile inserire la prenotazione multipla: ' + insert_response.booking.error);
        } else {
          this.oCreatingResponse = insert_response.booking;
          this.saveAnswer.emit(true);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
      }
    );

  }

  searchMultiBooking() {
    if (!this.bookingCode) return;
    this.prenotatennisService.getMultipleBookingListDB(this.bookingCode, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.booking && get_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare la lista prenotazioni' + ': ' + get_response.booking.error);

        } else {
          this.aMultipleBooking = get_response.booking;
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto: ' + error);
      });


  }

  deleteBooking(oBook: bookingData) {

    this.bLoadingData = true;

    this.prenotatennisService.postDeleteBookingDB(oBook.id, '', this.prenotatennisService.userData.token).subscribe(update_response => {

        if(update_response.booking && update_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile eliminare la prenotazione: ' + update_response.booking.error);
          this.bLoadingData = false;
        } else {
          this.bLoadingData = false;
          this.searchMultiBooking();
          this.saveAnswer.emit(true);
        }

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
      }
    );

  }

  deleteAllBooking() {

    this.bLoadingData = true;

    this.prenotatennisService.postDeleteBookingDB(0, this.bookingCode, this.prenotatennisService.userData.token).subscribe(update_response => {

        if(update_response.booking && update_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile eliminare la prenotazione: ' + update_response.booking.error);
          this.bLoadingData = false;
        } else {
          this.bLoadingData = false;
          this.searchMultiBooking();
          this.saveAnswer.emit(true);
        }

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
      }
    );

  }

  setViewSection(type: string) {

    switch (type){

      case 'INS':{
        this.oViewType.menu = false;
        this.oViewType.new_booking = true;
        this.oViewType.search_booking = false;
        break;
      }

      case 'SEARCH':{
        this.oViewType.menu = false;
        this.oViewType.new_booking = false;
        this.oViewType.search_booking = true;
        break;
      }

      default:{
        this.oViewType.menu = true;
        this.oViewType.new_booking = false;
        this.oViewType.search_booking = false;

        this.oCreatingResponse = null;
        this.bookingCode = "";
        this.aSelectedUsers = [];
        this.aMultipleBooking = [];
        break;
      }

    }

  }

}
