import { Component, OnInit } from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.scss']
})
export class RecoveryPasswordComponent implements OnInit {

  public bLoadingData: boolean = false;
  public UserNameRecovery: string = "";

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getRecoveryPassword() {

    this.bLoadingData = true;

    if (this.UserNameRecovery && this.UserNameRecovery !== '') {

      this.prenotatennisService.getRecoveryPasswordDB(this.UserNameRecovery).subscribe(recovery_response => {

          if(recovery_response.usr && recovery_response.usr.error) {
            // Error
            this.openDialog('danger', 'Attenzione: dati non disponibili per questo nome utente');
          } else {
            this.openDialog('success', 'E-mail inviata con successo al tuo indirizzo e-mail: ' + recovery_response.usr.send_ok);
            this.UserNameRecovery = "";
          }

          this.bLoadingData = false;

        },
        error => {
          // API call error
          console.log(error);
          this.bLoadingData = false;
        });

    } else {
      this.bLoadingData = false;
    }

  }

  closePupup() {
    this.activeModal.close('Close click')
  }

}
