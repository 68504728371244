<div class="header-wrapper">
  <div class="d-flex align-items-center justify-content-between">
    <div class="p-3" class="logo">
      <img src="./assets/img/logo_tennis.png" alt="{{prenotatennisService.settingsData.club_name}}" class="img-fluid">
    </div>
    <div class="flex-grow-1 pe-2 pe-lg-0">
      <div class="text-end text-lg-center" *ngIf="prenotatennisService.bLoggedIn && prenotatennisService.settingsData.version_level == 'delux'">
        <span class="credits_text"
              [ngClass]="prenotatennisService.userData.credits == 0?'text-danger':'text-success'">
          <i class="fa fa-coins"></i> {{prenotatennisService.userData.credits}}
        </span>
      </div>
      <div class="text-end text-lg-center" *ngIf="prenotatennisService.bLoggedIn && prenotatennisService.settingsData.check_cert_med">
        <span [ngClass]="prenotatennisService.userData.medical_expired?'text-danger':'text-success'">
          <i class="fa fa-stethoscope"></i> {{prenotatennisService.userData.medical_expiry | date: 'dd/MM/yyyy'}}
        </span>
      </div>
    </div>
    <div class="d-block " *ngIf="prenotatennisService.bLoggedIn">
      <div class="dropdown">
        <div type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <div class="d-flex align-items-center ">
            <div class="d-none d-lg-block me-2">{{prenotatennisService.userData.name}}</div>
            <div class="prev-prof-img-small"  [ngStyle]="{'background-image' : 'url(data:image/jpeg;base64,'+ prenotatennisService.userData.profile_image +')'}"></div>
          </div>
        </div>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item" (click)="editUser()"><span class="fa fa-user me-1"></span> Profilo</a></li>
          <li><a class="dropdown-item" (click)="logoutUser()"><span class="fa fa-right-from-bracket me-1"></span> Esci</a></li>
        </ul>
      </div>

    </div>
  </div>
</div>

<div *ngIf="prenotatennisService.bLoggedIn" class="menu-wrapper">
  <app-menu></app-menu>
</div>
