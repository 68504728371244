import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {bookingData, bookingPlayerData, paymentData} from "../../interfaces/interfaces";
import {AlertDialogComponent} from "../../components/alert-dialog/alert-dialog.component";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-booking-pay',
  templateUrl: './booking-pay.component.html',
  styleUrls: ['./booking-pay.component.scss']
})
export class BookingPayComponent implements OnInit {

  public bLoadingData: boolean = false;
  public oBooking: bookingData = this.prenotatennisService.oBooking;
  public oSport: any = null;
  public playgroundCost: any = null;
  public aPayment: Array<paymentData> = [];
  public aBookingPlayers: Array<bookingPlayerData> = [];

  public quota_min_player_nr: number = 0;
  public quota_max_player_nr: number = 0;
  public quota_payed: number = 0;
  public quota_to_pay: number = 0;
  public player_selected: number = 0;
  public player_quote: number = 0;
  public player_method: string = 'CREDITS';

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              private router: Router, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    // Check id in param link
    if(this.prenotatennisService.userData && this.prenotatennisService.userData.id == 0) {
      this.router.navigate(['home']);
    } else {
      let bookingId: any = this.route.snapshot.paramMap.get('id');
      if(bookingId) this.getBookingDetails(bookingId);
      if(!bookingId) this.initComponents();
    }
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  initComponents() {
    this.oBooking = this.prenotatennisService.oBooking;
    if(!this.oBooking.code) this.router.navigate(['home']);
    this.oSport = this.prenotatennisService.settingsData.sports.filter((x: any) => x.id == this.oBooking.id_sport)[0];
    this.getBookingCostData();
    if(this.prenotatennisService.userData.level == 'admin') this.getBookingPLayers();
  }

  initValues() {
    this.quota_min_player_nr = this.playgroundCost.book_total_to_pay / this.oSport.player_min_nr;
    this.quota_max_player_nr = this.playgroundCost.book_total_to_pay / this.oSport.player_nr;
  }

  getBookingDetails(bookingId: string) {

    this.bLoadingData = true;

    this.prenotatennisService.getBookingDetailsDB(Number(bookingId), this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.booking && get_response.booking.error) {
          //Error
          if(get_response.booking.error == 'Token not found') this.router.navigate(['home']);
          if(get_response.booking.error !== 'Token not found') {
            this.openDialog('danger', 'Attenzione, impossibile caricare i dati della prenotazione' + ': ' + get_response.booking.error);
          }
          this.bLoadingData = false;

        } else {
          this.prenotatennisService.oBooking = get_response.booking[0];
          this.initComponents();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto: ' + error);
      });


  }

  getBookingCostData() {

    this.bLoadingData = true;

    this.prenotatennisService.getBookingPriceDB(this.oBooking.id_playground, this.oBooking.id, this.prenotatennisService.settingsData.season, this.oBooking.hour_start, this.oBooking.hour_end, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.list && get_response.list.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile calcolare il prezzo' + ': ' + get_response.list.error);
        } else {
          this.playgroundCost = get_response.list;
          this.initValues();
          this.getBookingPaymentListData();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
      });

  }

  getBookingPaymentListData() {

    this.bLoadingData = true;

    this.prenotatennisService.getBookingPaymentDB(this.oBooking.id, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.payment && get_response.payment.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile calcolare la lista dei pagamenti' + ': ' + get_response.payment.error);
        } else {
          this.aPayment = get_response.payment;

          this.quota_payed = 0;
          this.aPayment.map(pay => {
            this.quota_payed = this.quota_payed + pay.quote;
          });

          this.quota_to_pay = 0;
          this.quota_to_pay = this.playgroundCost.book_total_to_pay - this.quota_payed;

        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
      });

  }

  getBookingPLayers() {

    this.prenotatennisService.getBookingPlayerDB(this.oBooking.id, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.booking && get_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare la lista dei giocatori' + ': ' + get_response.booking.error);
        } else {
          this.aBookingPlayers = get_response.booking;
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto: ' + error);
      });

  }

  createAdminPayment() {

    if(this.player_selected == 0) return;
    if(this.player_quote == 0) return;

    this.bLoadingData = true;

    let oPayment: paymentData = this.prenotatennisService.getNewPaymentData(this.oBooking.id);
    oPayment.payment_method = this.player_method;
    oPayment.quote = this.player_quote;
    oPayment.id_user = this.player_selected;
    oPayment.total = this.playgroundCost.book_total_to_pay;

    this.insertPayment(this.player_quote, oPayment);

  }

  insertPayment(quote: number, oPaymentData?: paymentData) {

    this.bLoadingData = true;

    let oPayment: paymentData = this.prenotatennisService.getNewPaymentData(this.oBooking.id);
    oPayment.payment_method = this.player_method;
    oPayment.quote = quote;
    oPayment.id_user = this.prenotatennisService.userData.id;
    oPayment.total = this.playgroundCost.book_total_to_pay;

    if(oPaymentData) oPayment = oPaymentData;

    this.prenotatennisService.postInsertBookingPaymentDB(oPayment, this.prenotatennisService.userData.token).subscribe(insert_response => {

      if(insert_response.payment && insert_response.payment.error) {
        //Error
        this.openDialog('danger', 'Attenzione, impossibile registrare il pagamento' + ': ' + insert_response.payment.error);
      } else {
        this.getBookingPaymentListData();
        this.prenotatennisService.getUserLoginData ();
        this.getBookingPLayers();
      }

      this.bLoadingData = false;

    },
    error => {
      // API call error
      console.log(error);
      this.bLoadingData = false;
      this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
    });
  }

  deletePayment(oPaymentData: paymentData) {

    this.bLoadingData = true;

    this.prenotatennisService.postDeleteBookingPaymentDB(oPaymentData, this.prenotatennisService.userData.token).subscribe(delete_response => {

        if(delete_response.payment && delete_response.payment.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile eliminare il pagamento' + ': ' + delete_response.payment.error);
        } else {
          this.getBookingPaymentListData();
          this.prenotatennisService.getUserLoginData ();
          this.getBookingPLayers();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
      });
  }

  closePayment() {
    this.router.navigate(['home']);
  }

}
