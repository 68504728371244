<app-loader *ngIf="bLoadingData"></app-loader>

<div class="row pb-5" >
  <div class="col-12">
    <div class="components mb-4">
      <!--*******************************-->
      <!--**** FILTERS ******************-->
      <!--*******************************-->
      <!--<div class="row">
        <div class="col-12">
          <div class="accordion" id="filterCalendar">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingOne">
                <button class="accordion-button collapsed rounded" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                  <span class="fas fa-filter me-1"></span>Filtri e impostazioni
                </button>
              </h2>
              <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">

                  <div class="d-flex flax-wrap align-items-center">
                    <div class="me-2 mt-1 flex-fill">
                      <label>Numero di giorni</label>
                      <input type="number" class="form-control" placeholder="10" [(ngModel)]="prenotatennisService.adminFilterCalendar.day_number">
                    </div>
                    <div class="me-2 mt-1 flex-fill">
                      <label>Data partenza</label>
                      <input type="date" class="form-control" placeholder="10" [(ngModel)]="prenotatennisService.adminFilterCalendar.start_date">
                    </div>
                    <div class="me-2 mt-1 flex-fill text-center">
                      <button class="btn btn-sm btn-primary" (click)="getCalendarDaysData()"><i class="fa fa-refresh"></i> IMPOSTA</button>
                    </div>
                  </div>

                  <div class="col-12 col-md-3 mt-2">
                    <label>Visualizzazione</label>
                    <div>
                      <button class="btn btn-sm btn-secondary me-2 mb-2" [class]="{'btn-success': prenotatennisService.adminFilterCalendar.calendar_type == 'standard'}" (click)="prenotatennisService.adminFilterCalendar.calendar_type='standard'; getCalendarData()">
                        <i class="fa fa-calendar"></i> Standard
                      </button>
                      <button class="btn btn-sm btn-secondary me-2 mb-2" [class]="{'btn-success': prenotatennisService.adminFilterCalendar.calendar_type == 'details'}" (click)="prenotatennisService.adminFilterCalendar.calendar_type='details'; getCalendarData()">
                        <i class="fa fa-list"></i> Dettaglio
                      </button>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>-->

      <!--*******************************-->
      <!--**** ALERTS *******************-->
      <!--*******************************-->
      <div class="alert alert-danger mt-4 mb-3" *ngIf="checkSeason.season == 'n.a.'">
        <h3>ATTENZIONE</h3>
        La data di oggi non è identificata come Estate o Inverno.<br />
        Entra nella gestione Estate/Inverno delle impostazioni e verifica la validità dei periodi.
      </div>

      <!--*******************************-->
      <!--**** CALENDAR VIEW ************-->
      <!--*******************************-->
      <!--<div class="direction-arrows mt-2 d-none d-lg-flex justify-content-end">
        <span class="fas fa-circle-chevron-left pointer me-2" (click)="scrollElement('day-list-wrapper', 'left', 300)"></span>
        <span class="fas fa-circle-chevron-right pointer" (click)="scrollElement('day-list-wrapper', 'right', 300)"></span>
      </div>-->

      <div class="row">
        <div class="col-12 col-lg-2 mb-2">
          <div class="d-flex align-items-sm-center flex-wrap align-items-baseline">
            <div class="mt-2 flex-fill">
              <label class="d-none d-sm-block">Numero di giorni</label>
              <input type="number" class="form-control" placeholder="10" [(ngModel)]="prenotatennisService.adminFilterCalendar.day_number">
            </div>
            <div class="mt-2 ms-1 ms-lg-0 flex-fill">
              <label class="d-none d-sm-block"l>Data partenza</label>
              <input type="date" class="form-control" placeholder="10" [(ngModel)]="prenotatennisService.adminFilterCalendar.start_date">
            </div>
            <div class="mt-2 flex-fill ms-1 text-center">
              <button class="btn btn-sm btn-primary align-middle" (click)="getCalendarDaysData(true)">
                <i class="fa fa-refresh d-md-none"></i> <span class="d-none d-sm-block">IMPOSTA</span>
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-10 mb-2">
          <div id="day-list-wrapper" class="day-list-wrapper d-flex">
            <div class="day-calendar-wrapper me-1 pointer" *ngFor="let calendarDay of aCalendarDays">
              <div class="alert alert-primary text-center" [ngClass]="calendarDay.club_closure?'alert-light':calendarDay.play_date==prenotatennisService.adminFilterCalendar.selected_date?'alert-warning':'alert-primary'" (click)="selectDayToView(calendarDay)">
                {{ getDayDescription(calendarDay.play_date) }}
                <hr>
                <h2>{{calendarDay.play_date | date: 'dd'}}</h2>
                <div>{{calendarDay.play_date | date: 'MMMM' : 'it-IT'}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <ng-container class="col-12" *ngFor="let pgCalendar of aCalendarData">
          <ng-container>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <button class="btn btn-sm btn-secondary me-2 mb-2" [class]="{'btn-success': prenotatennisService.adminFilterCalendar.calendar_type == 'standard'}" (click)="prenotatennisService.adminFilterCalendar.calendar_type='standard'; getCalendarData()">
                  <i class="fa fa-calendar"></i> Standard
                </button>
                <button class="btn btn-sm btn-secondary me-2 mb-2" [class]="{'btn-success': prenotatennisService.adminFilterCalendar.calendar_type == 'details'}" (click)="prenotatennisService.adminFilterCalendar.calendar_type='details'; getCalendarData()">
                  <i class="fa fa-list"></i> Dettaglio
                </button>
              </div>
              <h3>{{ getDayDescription(prenotatennisService.adminFilterCalendar.selected_date) }} {{prenotatennisService.adminFilterCalendar.selected_date | date: 'dd/MM/yyyy'}}</h3>
              <div class="direction-arrows d-none d-lg-flex">
                <span class="fas fa-circle-chevron-left pointer me-2" (click)="scrollElement('playground-list-wrapper', 'left', 300)"></span>
                <span class="fas fa-circle-chevron-right pointer" (click)="scrollElement('playground-list-wrapper', 'right', 300)"></span>
              </div>
            </div>

            <!-- Standard VIEW -->
            <div class="col-12" *ngIf="prenotatennisService.adminFilterCalendar.calendar_type == 'standard'">
              <div id="playground-list-wrapper" class="playground-list-wrapper row flex-nowrap gx-1">
                <div *ngFor="let pg of pgCalendar.playground" class="playground-wrapper col-3 me-1 mb-3">
                  <div class="card-header border p-2">
                    <div class="icon-wrapper">
                      <span>{{pg.pg_name}} </span>
                      <span class="ms-2 small text-muted">{{pg.pg_sport}}</span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                      <span class="badge bg-warning" *ngIf="pg.pg_reserved">Riservato</span>
                      <div class="d-flex flex-grow-1 justify-content-end">
                        <button class="btn btn-sm btn-primary me-1" [ngbTooltip]="tipBookAllBooking" (click)="newAllDayBooking(pg)"><i class="fa fa-hand-sparkles"></i></button>
                        <button class="btn btn-sm btn-primary" (click)="newMultipleBooking(pg.pg_id, pg.pg_name, pg.date)" [ngbTooltip]="tipMoreBooking"><i class="fa fa-layer-group"></i></button>
                      </div>
                      <ng-template #tipMoreBooking>
                        Prenotazioni multiple <br />{{pg.pg_name}}
                      </ng-template>
                      <ng-template #tipBookAllBooking>
                        Prenota tutte le ore del campo per la segreteria
                      </ng-template>
                    </div>
                  </div>
                  <div class="d-flex flex-row flex-wrap p-2 border" *ngIf="prenotatennisService.adminFilterCalendar.calendar_type == 'standard'">
                    <div class="btn w-100 flex-grow-1 btn-sm mt-2" *ngFor="let av_hour of pg.available_hour"
                         [class]="{'border-danger': av_hour.booked.teacher, 'btn-danger': av_hour.close, 'btn-success': av_hour.booked.id_book == 0, 'btn-primary': av_hour.booked.id_book !== 0 && (av_hour.confimed_player == pg.pg_sport_player || av_hour.confimed_player == pg.pg_sport_min_player), 'btn-warning': av_hour.booked.id_book !== 0 && (av_hour.confimed_player !== pg.pg_sport_player && av_hour.confimed_player !== pg.pg_sport_min_player)}"
                         (click)="newBooking(pg.date, pg.pg_id, pg.pg_name, av_hour)"
                         [ngbTooltip]="tipContent">
                      <ng-template #tipContent>
                        <div *ngIf="av_hour.close">
                          Ora non disponibile
                        </div>
                        <div>Dalle {{av_hour.start}} alle {{av_hour.end}}</div>
                        <div *ngIf="av_hour.booked.id_book !== 0">
                          {{av_hour.booked.user_name}}
                          <div *ngIf="prenotatennisService.settingsData.version_level== 'delux'">{{av_hour.booked.price}}€</div>
                        </div>
                      </ng-template>
                      {{av_hour.start}}
                      <i class="fa fa-money-bill" [class]="{'pay-success': av_hour.booked.price == av_hour.total_quote_payed, 'text-danger': av_hour.booked.price !== av_hour.total_quote_payed}"
                         *ngIf="prenotatennisService.settingsData.version_level== 'delux' && av_hour.booked.id_book !== 0"></i>
                      <div class="small text-center" *ngIf="av_hour.booked.multiple_code">
                        {{av_hour.booked.multiple_code}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Details VIEW -->
            <div class="col-12" *ngIf="prenotatennisService.adminFilterCalendar.calendar_type == 'details'">
              <div>
                <div *ngFor="let pg of pgCalendar.playground" class="mb-3">
                  <div class="card-header border bg-primary p-1" style="--bs-bg-opacity: .1;">
                    <div class="icon-wrapper">
                      <span>{{pg.pg_name}} </span>
                      <span class="ms-2 small text-muted">{{pg.pg_sport}}</span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                      <span class="badge bg-warning" *ngIf="pg.pg_reserved">Riservato</span>
                      <div class="d-flex flex-grow-1 justify-content-end">
                        <button class="btn btn-sm btn-primary me-1" [ngbTooltip]="tipBookAllBooking" (click)="newAllDayBooking(pg)"><i class="fa fa-hand-sparkles"></i></button>
                        <button class="btn btn-sm btn-primary" (click)="newMultipleBooking(pg.pg_id, pg.pg_name, pg.date)" [ngbTooltip]="tipMoreBooking"><i class="fa fa-layer-group"></i></button>
                      </div>
                      <ng-template #tipMoreBooking>
                        Prenotazioni multiple <br />{{pg.pg_name}}
                      </ng-template>
                      <ng-template #tipBookAllBooking>
                        Prenota tutte le ore del campo per la segreteria
                      </ng-template>
                    </div>
                  </div>
                  <!-- Details calendar type -->
                  <div class="col-12" *ngIf="prenotatennisService.adminFilterCalendar.calendar_type == 'details'">
                    <table class="table table-hover table-bordered table-striped">
                      <thead>
                      <tr>
                        <td>Slot</td>
                        <td>Nome</td>
                        <!--<td>Quota</td>-->
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let av_hour of pg.available_hour" (click)="newBooking(pg.date, pg.pg_id, pg.pg_name, av_hour)">
                        <td class="col-3 align-middle">
                          {{av_hour.start}} - {{av_hour.end}}
                          <div class="d-flex flex-wrap">
                            <div class="me-2 mt-1"><i class="fa fa-circle" [class]="{'text-danger': av_hour.close, 'text-success': av_hour.booked.id_book == 0, 'text-primary': av_hour.booked.id_book !== 0 && (av_hour.confimed_player == pg.pg_sport_player || av_hour.confimed_player == pg.pg_sport_min_player), 'text-warning': av_hour.booked.id_book !== 0 && (av_hour.confimed_player !== pg.pg_sport_player && av_hour.confimed_player !== pg.pg_sport_min_player)}"></i></div>
                            <div class="me-2 mt-1">
                              <i class="fa fa-money-bill ms-2"
                                    [class]="{'pay-success': av_hour.booked.price == av_hour.total_quote_payed, 'text-danger': av_hour.booked.price !== av_hour.total_quote_payed}"
                                    *ngIf="prenotatennisService.settingsData.version_level== 'delux' && av_hour.booked.id_book !== 0"></i>
                            </div>
                            <div class="me-2 mt-1">
                              <i class="fa fa-lock text-secondary me-2" *ngIf="!av_hour.booked.public"></i>
                              <i class="fa fa-lock-open text-success me-2" *ngIf="av_hour.booked.public"></i>
                            </div>
                            <div *ngIf="av_hour.booked.teacher" class="small text-success me-2 mt-1">
                              Ora di lezione
                            </div>
                          </div>
                        </td>
                        <td class="col-6">
                          <div *ngFor="let player of av_hour.booked.players">
                            <span class="text-success" *ngIf="prenotatennisService.settingsData.version_level== 'delux'"
                                  [class]="{'text-success': player.quote_payed, 'text-danger': !player.quote_payed}"
                                  [ngbTooltip]="tipQuotePayed"><i class="fa fa-money-bill"></i></span>
                            {{player.surname}} {{player.name}}
                            <ng-template #tipQuotePayed>
                              Quota versata <br />
                              <span *ngIf="player.quote_payed">{{player.quote_payed | number: '1.2-2'}} €</span>
                              <span *ngIf="!player.quote_payed">0.00 €</span>
                            </ng-template>
                          </div>
                        </td>
                        <!--<td class="col-3">
                          <div *ngFor="let player of av_hour.booked.players">
                            <span *ngIf="!player.quote_payed">--.--</span>
                            {{player?.quote_payed | number: '1.2-2'}} €
                          </div>
                        </td>-->
                      </tr>
                      </tbody>

                    </table>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

    </div>
  </div>
</div>
