import { Component, OnInit } from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {memberData} from "../../interfaces/interfaces";


@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss']
})
export class RegisterUserComponent implements OnInit {

  public bLoadingData: boolean = false;
  public oMember: memberData = this.prenotatennisService.getNewMemberData();
  public sCheckPsw: string = "";
  public bCheckPrivacy: boolean = false;

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {}

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  disableSaveButton() {

    if(this.oMember.name == '') return true;
    if(this.oMember.surname == '') return true;
    if(this.oMember.email == '') return true;
    if(this.oMember.psw == '') return true;
    if(this.sCheckPsw == '') return true;
    if(this.oMember.psw !== this.sCheckPsw) return true
    if(!this.bCheckPrivacy) return true;

    return false;

  }

  getGuestToken() {

    this.bLoadingData = true;

    this.prenotatennisService.getGuestTokenDB().subscribe(get_response => {

      console.log(get_response);
      this.saveMemberProfile(get_response.token);

    },
    error => {
      // API call error
      console.log(error);
    });

  }

  saveMemberProfile(guestToken: string) {

    this.prenotatennisService.postInsertUserDB(this.oMember, guestToken, true).subscribe(insert_response => {

        if(insert_response.user && insert_response.user.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile registrare il profilo: ' + insert_response.user.error);
        } else {
          this.openDialog('success', 'Profilo creato con successo, riceverai una email di conferma');
          this.oMember = this.prenotatennisService.getNewMemberData();
          this.activeModal.close('Close click');
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );
  }

  closePupup() {
    this.activeModal.close('Close click')
  }

}
