<app-loader *ngIf="bLoadingData"></app-loader>

<div class="row mt-3">
  <div class="col-12">
    <div class="mt-2 d-flex justify-content-center align-items-center">
      <div class="col-6 p-1">
        <div class="d-grid gap-2">
          <button class="btn btn-success" (click)="openPlayerList()">
            <i class="fa fa-plus"></i> Seleziona giocatore
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 mt-4">

    <table class="table table-hover table-striped table-bordered">
      <thead>
        <tr>
          <th>Giocatore</th>
          <th>Data blocco</th>
          <th>#</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let player of aBlackList">
          <td>{{player.surname}} {{player.name}}</td>
          <td>{{player.date_ins | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
          <td>
            <button class="btn btn-block btn-sm btn-primary" (click)="deleteBlackList(player)">RIABILITA</button>
          </td>
        </tr>
      </tbody>
    </table>


  </div>
</div>
