<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header bg-primary text-white">
  <h4>Gestione servizi aggiuntivi</h4>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12 col-md-6 mt-2">
      <label>Titolo</label>
      <input type="text" class="form-control" placeholder="Titolo breve" [(ngModel)]="oService.title">
    </div>
    <div class="col-12 col-md-6 mt-2">
      <label>Descrizione</label>
      <input type="text" class="form-control" placeholder="Dettaglio servizio" [(ngModel)]="oService.description">
    </div>
    <div class="col-12 col-md-6 mt-4">
      <label>Prezzo (€)</label>
      <input type="number" class="form-control" placeholder="10.50" [(ngModel)]="oService.price">
    </div>
    <div class="col-12 col-md-6 mt-4">
      <h5>Impostazioni</h5>
      <div class="form-check">
        <input class="form-check-input" id="unatantumCheck" type="checkbox" [(ngModel)]="oService.unatantum">
        <label class="form-check-label" for="unatantumCheck">
          Prezzo a partita
          <div>
            <small>Se non attivo il prezzo sarà calcolato per il numero di persone</small>
          </div>
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="statusCheck" [(ngModel)]="oService.status">
        <label class="form-check-label" for="statusCheck">
          Servizio visibile
        </label>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-danger me-4" (click)="deleteServiceConfirm()" *ngIf="oService.id !== 0">Elimina servizio</button>
  <button class="btn btn-success" (click)="saveService()">Salva modifiche</button>
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('')">CHIUDI</button>
</div>
