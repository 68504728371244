<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header bg-primary text-white">
  <h4>Controlli attivi sulle piattaforma</h4>
</div>

<div class="modal-body">

  <!-- Dati sulla versione -->
  <div class="row">
    <div class="col-12 p-2 bg-light">
      <div>
        <strong>Versione attiva:</strong>
        <span class="text-uppercase text-success credits_text ms-1">{{prenotatennisService.settingsData.version_level}}</span>
      </div>
      <div>
        <strong>Tipo selezione ore:</strong>
        <span class="text-uppercase text-success credits_text ms-1">{{prenotatennisService.settingsData.hour_selection}}</span>
      </div>
      <div>
        <strong>Giorni visibili in dashboard per giocatore:</strong>
        <span class="text-uppercase text-success credits_text ms-1">{{prenotatennisService.settingsData.user_day_to_view}}</span>
      </div>
    </div>
  </div>

  <!-- Chiusure segreteria -->
  <div class="row mt-2 pt-3 border-top">

    <div class="col-1">
      <i class="fa fa-check-circle"
         [class]="{'text-success': prenotatennisService.settingsData.check_free_booking_24h, 'text-danger': !prenotatennisService.settingsData.check_free_booking_24h}">
      </i>
    </div>
    <div class="col-11">
      Le prenotazioni fatte entro le 24h sono senza vincoli e sempre permesse
    </div>

    <div class="col-1 mt-1">
      <i class="fa fa-check-circle" [class]="{'text-success': prenotatennisService.settingsData.check_booking_nr, 'text-danger': !prenotatennisService.settingsData.check_booking_nr}"></i>
    </div>
    <div class="col-11 mt-1">
      Un socio nelle date definite e negli orari definiti non può fare più di due prenotazioni in una settimana
    </div>

    <div class="col-1 mt-1">
      <i class="fa fa-check-circle"
         [class]="{'text-success': prenotatennisService.settingsData.check_cert_med, 'text-danger': !prenotatennisService.settingsData.check_cert_med}"></i>
    </div>
    <div class="col-11 mt-1">
      Il socio deve avere il certificato medico valido nella data di gioco per poter prenotare/giocare
    </div>

    <div class="col-1 mt-1">
      <i class="fa fa-check-circle"
         [class]="{'text-success': prenotatennisService.settingsData.check_member_valid, 'text-danger': !prenotatennisService.settingsData.check_member_valid}"></i>
    </div>
    <div class="col-11 mt-1">
      La tessera socio deve essere valida
    </div>

    <div class="col-1 mt-1">
      <i class="fa fa-check-circle"
         [class]="{'text-success': prenotatennisService.settingsData.check_user_delete_booking_24h, 'text-danger': !prenotatennisService.settingsData.check_user_delete_booking_24h}">
      </i>
    </div>
    <div class="col-11 mt-1">
      Le prenotazioni non possono essere annullate dal profilo socio nelle 24h prima ma solo chiamando la segreteria
    </div>

    <div class="col-1 mt-1">
      <i class="fa fa-check-circle"
         [class]="{'text-success': prenotatennisService.settingsData.user_register_free, 'text-danger': !prenotatennisService.settingsData.user_register_free}">
      </i>
    </div>
    <div class="col-11 mt-1">
      Gli utenti possono registrarsi in autonomia inserendo i dati necessari
    </div>

    <div class="col-1 mt-1">
      <i class="fa fa-check-circle"
         [class]="{'text-success': prenotatennisService.settingsData.block_booking_same_day, 'text-danger': !prenotatennisService.settingsData.block_booking_same_day}">
      </i>
    </div>
    <div class="col-11 mt-1">
      Le prenotazioni sono ammesse solo dal giorno successivo
    </div>

    <!--<div class="col-1 mt-1">
      <i class="fa fa-check-circle"
         [class]="{'text-success': oSettings.hide_booking_price, 'text-danger': !oSettings.hide_booking_price}">
      </i>
    </div>
    <div class="col-11 mt-1">
      Il prezzo viene nascosto in fase di prenotazione
    </div>-->

    <div class="col-1 mt-1">
      <i class="fa fa-check-circle"
         [class]="{'text-success': prenotatennisService.settingsData.check_48h, 'text-danger': !prenotatennisService.settingsData.check_48h}">
      </i>
    </div>
    <div class="col-11 mt-1">
      Una prenotazione che non ha un partner di gioco entro le {{prenotatennisService.settingsData.check_48h_value}}h prima viene annullata in automatico dal sistema
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('')">CHIUDI</button>
</div>
