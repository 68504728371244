import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {
  blacklistData,
  bookingAllDayPLaygroundData,
  bookingData,
  bookingMultipleData,
  bookingPlayerData,
  bookingServiceData,
  bookingShareMailData,
  closureData, logData,
  managementSettingsData,
  memberData,
  moreServiceData,
  moreServicePlaygroundData,
  paymentData,
  playgrondPriceData,
  playgroundData,
  rechargeData,
  userData
} from "../interfaces/interfaces";
import {ApiService} from "../services/api.service";
import {map} from "rxjs/operators";
import {DatePipe, formatDate} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class PrenotatennisService {

  public settingsData: any = null;
  public userData: userData = this.getNewUserData();
  public bLoggedIn: boolean = false;
  public oBookSelected: any = null;
  public oBookIDSelected: number = 0;
  public oBooking: bookingData = this.getNewBookingData();

  public adminFilterCalendar: any = {
    day_number: 30,
    day_of_week: 10,
    start_date: formatDate(new Date(), 'yyyy-MM-dd', 'en_US'),
    selected_date: formatDate(new Date(), 'yyyy-MM-dd', 'en_US'),
    calendar_type: 'standard'
  }

  public memberFilterBooking: any = {
    date_from: "",
    date_to: ""
  }

  constructor(private apiService: ApiService, ) { }

  setSettings(oSettings: any) {
    // Set member filter date
    let from: Date = new Date();
    let to: Date = new Date();

    to.setDate(from.getDate() + 30);

    this.memberFilterBooking.date_from = from.toISOString().slice(0, 10);
    this.memberFilterBooking.date_to = to.toISOString().slice(0, 10);

    this.settingsData = oSettings;
    this.getUserLoginData ();
  }

  getUserLoginData () {

    const sUserData = localStorage.getItem('prenotatennis_userinfo');
    if (sUserData) {

      this.userData = JSON.parse(sUserData);

      this.getUserStatusDB(this.userData.token, this.userData.id).subscribe(get_response => {

          if(get_response.usr && get_response.usr.error) {
            //Error
            this.userData = this.getNewUserData();
            this.bLoggedIn = false;
            this.getUserLogoutData ();
          } else {
            if(get_response.usr.status == 1) {
              this.bLoggedIn = true;
              this.userData = get_response.usr.data;
              this.setUserLoginData (this.userData);
            }
            if(get_response.usr.status == 0) {
              this.userData = this.getNewUserData();
              this.bLoggedIn = false;
              this.getUserLogoutData ();
            }
          }

        },
        error => {
          // API call error
          this.bLoggedIn = false;
          this.userData = this.getNewUserData();
          console.log(error);
          this.getUserLogoutData ();
        });


    }

  }

  setUserLoginData (oUserData: any) {
    this.userData = oUserData;
    localStorage.setItem('prenotatennis_userinfo', JSON.stringify(this.userData));
  }

  getUserLogoutData () {
    this.userData = this.getNewUserData();
    localStorage.removeItem('prenotatennis_userinfo');
  }

  getUserStatusDB (token: string, user_id?: number): Observable<any> {
    let url = "user_get_status";
    //if(user_id) url = url + "?uid=" + user_id;
    if(user_id) url = url + "/" + user_id;

    return this.apiService.get(url, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getWeekDayDescription(value: number){
    let aWeek = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
    return aWeek[value];
  }

  getNewUserData () {

    let objUser = {
      id: 0,
      name: '',
      surname: '',
      nickname: '',
      profile_image: null,
      member_code: '',
      member_expired: false,
      medical_expired: false,
      member_expiry: new Date(),
      medical_expiry: new Date(),
      medical_day_diff: 0,
      member_day_diff: 0,
      email: '',
      phone: '',
      username: '',
      token: '',
      status: 1,
      level: '',
      game_level: 0,
      credits: 0
    }

    return objUser;

  }

  getNewPlayerData () {

    let objUser = {
      id: 0,
      name: '',
      surname: '',
      nickname: '',
      profile_image: '',
      member_code: '',
      member_expiry: '',
      member_expired: false,
      medical_expiry: '',
      medical_expired: false,
      email: '',
      status: 0,
      level: '',
      game_level: 0,
    }

    return objUser;

  }

  getNewMemberData() {

    let todayDate: any = new DatePipe('en-EN').transform(Date.now(), 'yyyy-MM-dd');

    let oMember = {
      id: 0,
      name: '',
      surname: '',
      nickname: '',
      profile_image: null,
      fiscal_code: '',
      birthdate: todayDate.toString(),
      member_code: '',
      member_expiry: todayDate.toString(),
      medical_expiry: todayDate.toString(),
      address: '',
      address_nr: '',
      municipality: '--',
      zip: '',
      province: '--',
      vat: '',
      email: '',
      phone: '',
      username: '',
      psw: '',
      token: '',
      status: 1,
      level: 'member',
      game_level: 0,
      credits: 0,
      whatsapp: false
    }

    return oMember;

  }

  getNewPlayground() {

    let oPlayground = {
      id: 0,
      name: '',
      description: '',
      start_booking: '00:00',
      end_booking: '00:00',
      gap: 30,
      start_close: '00:00',
      end_close: '00:00',
      id_sport: 0,
      reserved: false,
      status: true,
      booking_nr: 0
    }

    return oPlayground;

  }

  getNewMoreService() {

    let oService = {
      id: 0,
      title: '',
      description: '',
      price: 0,
      unatantum: true,
      status: true,
      active: false,
      id_activation: 0
    }

    return oService;

  }

  getNewPGPrice(pg_hour_start: string, pg_hour_end: string, id_pg: number) {

    let oList = {

      id: 0,
      id_playground: id_pg,
      season: '',
      hour_start: pg_hour_start,
      hour_end: pg_hour_end,
      price: 0,
      price_double: 0
    }

    return oList;

  }

  getNewClosureData() {

    let obj = {
      id: 0,
      date: '',
      status: true
    }

    return obj;

  }

  getNewSettingsData() {

    let obj = {
      id: 0,
      constraints_date_start: '',
      constraints_date_end: '',
      constraints_hour_start: '',
      constraints_hour_end: '',
      constraints_days: '',
      winter_start: '',
      winter_end: '',
      summer_start: '',
      summer_end: '',
      secretary_closed_week_days: '',
      secretary_hour_start: '',
      secretary_hour_end: '',
      closed_days: '',
    }

    return obj;

  }

  getNewCalendarBookedHour() {

    let obj = {
      booking_code: '',
      id_book: 0,
      id_user: 0,
      user_name: '',
      multiple_code: '',
      note: '',
      player_nr: 0,
      teacher: false,
      price: 0,
    }

    return obj;

  }

  getNewBookingData() {

    let obj = {
      id: 0,
      code: '',
      id_user: 0,
      id_playground: 0,
      id_sport: 0,
      public: false,
      playground_name: '',
      player_nr: 0,
      date_play: '',
      hour_start: '',
      hour_end: '',
      price: 0,
      note: '',
      status: true,
      confirmed: 0,
      multiple_code: '',
      teacher: false,
    }

    return obj;

  }

  getNewBookingMultipleData() {

    let obj = {
      id: 0,
      code: "",
      id_playground: 0,
      playground_name: "",
      from_dt: "",
      to_dt: "",
      start_hour: "",
      end_hour: "",
      day_of_week: 0,
      aPLayers: [],
      admin_user: false,
      notes: ""
    }

    return obj;

  }

  getNewBookingAllDayata() {

    let obj = {
      id: 0,
      id_playground: 0,
      playground_name: "",
      id_user: 0,
      play_date: "",
      hours: [],
      notes: ""
    }

    return obj;

  }

  getNewBookingPlayerData(id_booking: number, id_player: number, status: string) {

    let obj = {
      id: 0,
      id_booking: id_booking,
      id_user: id_player,
      status: status,
      player_game_level: 0,
      email: ''
    }

    return obj;

  }

  getNewBookingServiceData(id_booking: number, id_service: number) {

    let obj = {
      id: 0,
      id_booking: id_booking,
      id_service: id_service,
      price: 0,
      unatantum: 1,
      quantity: 1,
      booking_active: false,
      booking_qnt: 1
    }

    return obj;

  }

  getNewCheckUserBookingData() {

    let obj: any = {
      block: {
        medical_expiration: false,
        member_expiration: false,
        nr_booking_week: false,
        same_day: false
      },
      free: {
        free_booking_24h: false
      },
      secretary: {
        secretary_closed: false
      }
    }

    return obj;

  }

  getNewPaymentData(id_booking: number) {

    let obj = {
      id: 0,
      id_booking: id_booking,
      id_user: 0,
      payment_method: "CREDITS",
      quote: 0,
      total: 0,
    }

    return obj;

  }

  getNewRechargeData(id_creditbox: number) {

    let obj: any = {
      id: 0,
      code: '',
      id_creditbox: id_creditbox,
      credit: 0,
      price: 0,
      status: 'INS',
      txn_id: '',
    }

    return obj;

  }

  getNewLogData(section: string, description: string) {

    let obj: any = {
      id: 0,
      id_user: this.userData.id,
      section: section,
      description: description
    }

    return obj;

  }

  /*****************************************/
  /******* API CALLS GET *******************/
  /*****************************************/

  getMunicipalitiesDB(prov_code: string, token: string): Observable<any>{

    //let apiUrl = "municipality_get_list.php?cp=" + prov_code;
    let apiUrl = "municipality_get_list/" + prov_code;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getRecoveryPasswordDB(username:string): Observable<any>{

    return this.apiService.get("user_recovery_password/?id=" + username, '').pipe(
      map(data => {
        return data;
      })
    );
  }

  getGuestTokenDB(): Observable<any>{
    return this.apiService.get('user_get_guest_token', '').pipe(
      map(data => {
        return data;
      })
    );
  }

  getCheckBookingDateDB(playDate: any, playHour: string, token: string): Observable<any>{

    let apiUrl = "check_booking_constraints" + "/" + playDate + "/" + playHour;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getCheckSeasonDB(token: string): Observable<any>{

    let apiUrl = "check_season";

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getUserListDB(token: string, user_id?: number): Observable<any>{

    let apiUrl: string = "user_get_list/";
    if(user_id) apiUrl = apiUrl + user_id;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getExportUserListDB(token: string): Observable<any>{
    return this.apiService.get('user_export_info', token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getCalendarDaysListDB(start_date: string, days_nr: number, token: string): Observable<any>{

    let apiUrl: string = "dashboard_get_days";
    apiUrl = apiUrl +"/" + start_date + "/" + days_nr.toString();

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getCalendarPublicDaysListDB(start_date: string, days_nr: number, token: string): Observable<any>{

    let apiUrl: string = "dashboard_public_get_days";
    apiUrl = apiUrl +"/" + start_date + "/" + days_nr.toString();

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getCalendarListDB(start_date: string, token: string): Observable<any>{

    let apiUrl: string = "dashboard_get_data";
    apiUrl = apiUrl +"/" + start_date;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getCalendarPublicListDB(start_date: string, token: string): Observable<any>{

    let apiUrl: string = "dashboard_public_get_data";
    apiUrl = apiUrl +"/" + start_date;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getPlaygroundListDB(filter: boolean, token: string, playground_id?: number): Observable<any>{

    let apiUrl: string = "playground_get_list/" + filter + "/";
    if(playground_id) apiUrl = apiUrl + playground_id;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getMoreServiceListDB(filter: boolean, token: string, service_id?: number): Observable<any>{

    let apiUrl: string = "service_get_list/" + filter + "/";
    if(service_id) apiUrl = apiUrl + service_id;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getMoreServicePGListDB(id_playground: number, token: string): Observable<any>{

    let apiUrl: string = "playground_service_get_list/" + id_playground;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getPGPriceListDB(id_playground: number, token: string): Observable<any>{

    let apiUrl: string = "pricelist_get_list/" + id_playground;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getBookingPriceDB(id_playground: number, id_book: number, season: string, sh: string, eh: string, token: string): Observable<any>{

    let apiUrl: string = "pricelist_get_booking_cost/" + id_playground;
    apiUrl = apiUrl + "/" + id_book;
    apiUrl = apiUrl + "/" + season;
    apiUrl = apiUrl + "/" + sh;
    apiUrl = apiUrl + "/" + eh;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getClosureListDB(token: string): Observable<any>{

    let apiUrl: string = "closure_get_list";

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getManagebleSettingsDB(token: string): Observable<any>{

    let apiUrl: string = "settings_get_list";

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getBookingListDB(from: Date, to: Date, token: string, id_user?: number): Observable<any>{

    let apiUrl: string = "booking_get_list/" + from.toISOString() + "/" + to.toISOString() + "/";
    if (id_user) apiUrl = apiUrl + id_user;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getBookingDetailsDB(id_book: number, token: string): Observable<any>{

    let apiUrl: string = "booking_get_details/" + id_book;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getBookingAvailableHoursDB(date_play: string, hour_play: string, pg_id: number, token: string): Observable<any>{

    let apiUrl: string = "booking_get_available_hours/" + date_play + "/" + hour_play + "/" + pg_id;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getBookingAvailableUserDB(date_play: string, token: string): Observable<any>{

    let apiUrl: string = "user_get_list_booking/" + date_play;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getBookingPlayerDB(id_booking: number, token: string): Observable<any>{

    let apiUrl: string = "booking_player_get_list/" + id_booking;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getBookingServiceDB(id_booking: number, token: string): Observable<any>{

    let apiUrl: string = "booking_service_get_list/" + id_booking;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getBookingPaymentDB(id_booking: number, token: string): Observable<any>{

    let apiUrl: string = "payment_get_list/" + id_booking;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getMultipleBookingListDB(code: string, token: string): Observable<any>{

    let apiUrl: string = "booking_multiple_get_list/" + code;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getSharedBookingListDB(from: Date, to: Date, token: string, id_user?: number): Observable<any>{

    let apiUrl: string = "booking_share_list/" + from.toISOString() + "/" + to.toISOString() + "/";
    if (id_user) apiUrl = apiUrl + id_user;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      })
    );
  }

  getCreditBoxListDB(status: boolean, token: string): Observable<any>{

    let apiUrl: string = "credit_box_get_list/";
    if(status) apiUrl = apiUrl + "true";

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      }));
  }

  getRechargeListDB(token: string): Observable<any>{

    let apiUrl: string = "recharge_get_list";

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      }));
  }

  getBlackListListDB(id_user: number, token: string): Observable<any>{

    let apiUrl: string = "blacklist_get_list/" + id_user;

    return this.apiService.get(apiUrl, token).pipe(
      map(data => {
        return data;
      }));
  }

  /*****************************************/
  /******* API CALLS POST ******************/
  /*****************************************/

  postLoginUserDB(oUserLoginData: any): Observable<any>{

    return this.apiService.post('login', '', oUserLoginData).pipe(
      map(data => {
        return data;
      })
    );
  }

  postInsertUserDB(oMember: memberData, token: string, guest?:boolean): Observable<any>{

    let apiUrl: string = "user_insert/";
    if (guest) apiUrl = apiUrl + "true";

    return this.apiService.post(apiUrl, token, oMember).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUpdateUserDB(oMember: memberData, token: string): Observable<any>{

    let apiUrl: string = "user_update";

    return this.apiService.post(apiUrl, token, oMember).pipe(
      map(data => {
        return data;
      })
    );
  }

  postDeleteUserDB(oMember: memberData, token: string): Observable<any>{

    let apiUrl: string = "user_delete";

    return this.apiService.post(apiUrl, token, oMember).pipe(
      map(data => {
        return data;
      })
    );
  }

  postChangePswUserDB(oMember: memberData, token: string): Observable<any>{

    let apiUrl: string = "user_change_password";

    return this.apiService.post(apiUrl, token, oMember).pipe(
      map(data => {
        return data;
      })
    );
  }

  postInsertPlaygroundDB(oPlayground: playgroundData, token: string): Observable<any>{

    let apiUrl: string = "playground_insert";

    return this.apiService.post(apiUrl, token, oPlayground).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUpdatePlaygroundDB(oPlayground: playgroundData, token: string): Observable<any>{

    let apiUrl: string = "playground_update";

    return this.apiService.post(apiUrl, token, oPlayground).pipe(
      map(data => {
        return data;
      })
    );
  }

  postDeletePlaygroundDB(oPlayground: playgroundData, token: string): Observable<any>{

    let apiUrl: string = "playground_delete";

    return this.apiService.post(apiUrl, token, oPlayground).pipe(
      map(data => {
        return data;
      })
    );
  }

  postInsertServiceDB(oService: moreServiceData, token: string): Observable<any>{

    let apiUrl: string = "service_insert";

    return this.apiService.post(apiUrl, token, oService).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUpdateServiceDB(oService: moreServiceData, token: string): Observable<any>{

    let apiUrl: string = "service_update";

    return this.apiService.post(apiUrl, token, oService).pipe(
      map(data => {
        return data;
      })
    );
  }

  postDeleteServiceDB(oService: moreServiceData, token: string): Observable<any>{

    let apiUrl: string = "service_delete";

    return this.apiService.post(apiUrl, token, oService).pipe(
      map(data => {
        return data;
      })
    );
  }

  postInsertPGServiceDB(oService: moreServicePlaygroundData, token: string): Observable<any>{

    let apiUrl: string = "playground_service_insert";

    return this.apiService.post(apiUrl, token, oService).pipe(
      map(data => {
        return data;
      })
    );
  }

  postDeletePGServiceDB(oService: moreServicePlaygroundData, token: string): Observable<any>{

    let apiUrl: string = "playground_service_delete";

    return this.apiService.post(apiUrl, token, oService).pipe(
      map(data => {
        return data;
      })
    );
  }

  postInsertPGPriceDB(oPrice: playgrondPriceData, token: string): Observable<any>{

    let apiUrl: string = "pricelist_insert";

    return this.apiService.post(apiUrl, token, oPrice).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUpdatePGPriceDB(oPrice: playgrondPriceData, token: string): Observable<any>{

    let apiUrl: string = "pricelist_update";

    return this.apiService.post(apiUrl, token, oPrice).pipe(
      map(data => {
        return data;
      })
    );
  }

  postDeletePGPriceDB(oPrice: playgrondPriceData, token: string): Observable<any>{

    let apiUrl: string = "pricelist_delete";

    return this.apiService.post(apiUrl, token, oPrice).pipe(
      map(data => {
        return data;
      })
    );
  }

  postInsertClosureDB(oClosure: closureData, token: string): Observable<any>{

    let apiUrl: string = "closure_insert";

    return this.apiService.post(apiUrl, token, oClosure).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUpdateClosureDB(oClosure: closureData, token: string): Observable<any>{

    let apiUrl: string = "closure_update";

    return this.apiService.post(apiUrl, token, oClosure).pipe(
      map(data => {
        return data;
      })
    );
  }

  postDeleteClosureDB(oClosure: closureData, token: string): Observable<any>{

    let apiUrl: string = "closure_delete";

    return this.apiService.post(apiUrl, token, oClosure).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUpdateSettingsDB(oSettings: managementSettingsData, token: string): Observable<any>{

    let apiUrl: string = "settings_update";

    return this.apiService.post(apiUrl, token, oSettings).pipe(
      map(data => {
        return data;
      })
    );
  }

  postInsertBookingDB(oBooking: bookingData, token: string): Observable<any>{

    let apiUrl: string = "booking_insert";

    return this.apiService.post(apiUrl, token, oBooking).pipe(
      map(data => {
        return data;
      })
    );
  }

  postInsertMultipleBookingDB(oMultipleBooking: bookingMultipleData, token: string): Observable<any>{

    let apiUrl: string = "booking_multiple_insert";

    return this.apiService.post(apiUrl, token, oMultipleBooking).pipe(
      map(data => {
        return data;
      })
    );
  }

  postInsertAllDayPlaygroundBookingDB(oAllDayBooking: bookingAllDayPLaygroundData, token: string): Observable<any>{

    let apiUrl: string = "booking_playground_hour_insert";

    return this.apiService.post(apiUrl, token, oAllDayBooking).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUpdateBookingDB(oBooking: bookingData, send_mail: boolean, token: string): Observable<any>{

    let apiUrl: string = "booking_update/" + send_mail;

    return this.apiService.post(apiUrl, token, oBooking).pipe(
      map(data => {
        return data;
      })
    );
  }

  postDeleteBookingDB(id_book: number, code_book: string, token: string): Observable<any>{
    // Set id_book to zero if you want to delete all multiple code booking
    // Set code_book to zero if you want to delete the single book

    let obj: any = {
      id: id_book,
      code: code_book
    }

    let apiUrl: string = "booking_delete";

    return this.apiService.post(apiUrl, token, obj).pipe(
      map(data => {
        return data;
      })
    );
  }

  postInsertBookingPlayerDB(oBPlayer: bookingPlayerData, token: string): Observable<any>{

    let apiUrl: string = "booking_player_insert";

    return this.apiService.post(apiUrl, token, oBPlayer).pipe(
      map(data => {
        return data;
      })
    );
  }

  postUpdateBookingPlayerDB(oBPlayer: bookingPlayerData, token: string): Observable<any>{

    let apiUrl: string = "booking_player_update";

    return this.apiService.post(apiUrl, token, oBPlayer).pipe(
      map(data => {
        return data;
      })
    );
  }

  postDeleteBookingPlayerDB(oBPlayer: bookingPlayerData, token: string): Observable<any>{

    let apiUrl: string = "booking_player_delete";

    return this.apiService.post(apiUrl, token, oBPlayer).pipe(
      map(data => {
        return data;
      })
    );
  }

  postInsertBookingServiceDB(oBService: bookingServiceData, token: string): Observable<any>{

    let apiUrl: string = "booking_service_insert";

    return this.apiService.post(apiUrl, token, oBService).pipe(
      map(data => {
        return data;
      })
    );
  }

  postDeleteBookingServiceDB(oBService: bookingServiceData, token: string): Observable<any>{

    let apiUrl: string = "booking_service_delete";

    return this.apiService.post(apiUrl, token, oBService).pipe(
      map(data => {
        return data;
      })
    );
  }

  postInsertBookingPaymentDB(oPayment: paymentData, token: string): Observable<any>{

    let apiUrl: string = "payment_insert";

    return this.apiService.post(apiUrl, token, oPayment).pipe(
      map(data => {
        return data;
      })
    );
  }

  postDeleteBookingPaymentDB(oPayment: paymentData, token: string): Observable<any>{

    let apiUrl: string = "payment_delete";

    return this.apiService.post(apiUrl, token, oPayment).pipe(
      map(data => {
        return data;
      })
    );
  }

  postShareBookingEmailDB(oEmail: bookingShareMailData, token: string): Observable<any>{

    let apiUrl: string = "booking_share_send_mail";

    return this.apiService.post(apiUrl, token, oEmail).pipe(
      map(data => {
        return data;
      })
    );
  }

  postInsertRechargeOrderDB(oRecharge: rechargeData, token: string): Observable<any>{

    let apiUrl: string = "recharge_insert";

    return this.apiService.post(apiUrl, token, oRecharge).pipe(
      map(data => {
        return data;
      })
    );
  }

  postInsertBlackListDB(oBlacklist: blacklistData, token: string): Observable<any>{

    let apiUrl: string = "blacklist_insert";

    return this.apiService.post(apiUrl, token, oBlacklist).pipe(
      map(data => {
        return data;
      })
    );
  }

  postDeleteBlackListDB(oBlacklist: blacklistData, token: string): Observable<any>{

    let apiUrl: string = "blacklist_delete";

    return this.apiService.post(apiUrl, token, oBlacklist).pipe(
      map(data => {
        return data;
      })
    );
  }

}
