<div class="row">
  <div class="col-12 mt-3 text-center">
    <h3>La tua black list</h3>
    <div>
      I giocatori nella tua black list non potranno vedere le tue prenotazioni condivise.
    </div>
  </div>
  <div class="col-12 mt-3">
    <app-blacklist-edit [id_user]="prenotatennisService.userData.id"></app-blacklist-edit>
  </div>
</div>

<div class="pb-5">
  <!-- Space for menu bug fix -->
</div>

