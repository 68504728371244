

<section id="loginComponents" *ngIf="!prenotatennisService.bLoggedIn">
  <app-login></app-login>
  <!--<a href='https://wa.me/0393493430248?text=prenotazione%20CODICE%20del%2007/12/2022%20dalle%2009:00'>WhatsApp</a>-->
</section>

<section id="calendarSection" *ngIf="prenotatennisService.bLoggedIn">
  <app-calendar *ngIf="prenotatennisService.userData.level == 'admin'"></app-calendar>
  <app-calendar-member *ngIf="prenotatennisService.userData.level == 'member'"></app-calendar-member>
</section>

