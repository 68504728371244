import { Component, OnInit } from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {
  bookingAllDayPLaygroundData,
  bookingMultipleData,
  calendarAvailableHourData,
  calendarData, calendarDaysData, calendarPlaygroundData,
  checkSeason
} from "../../interfaces/interfaces";
import {Router} from "@angular/router";
import {BookingMultipleComponent} from "../booking-multiple/booking-multiple.component";
import {BookingAlldayComponent} from "../booking-allday/booking-allday.component";

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})

export class CalendarComponent implements OnInit {

  public bLoadingData: boolean = false;
  public aCalendarData: Array<calendarData> = [];
  public aCalendarDays: Array<calendarDaysData> = [];
  public checkSeason: checkSeason = {season: ''};

  public selectedDay: any

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              private router: Router) { }

  ngOnInit(): void {
    //this.filter.start_date = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    this.prenotatennisService.oBookSelected = null;
    //this.prenotatennisService.adminFilterCalendar.start_date = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    this.getCalendarDaysData();
    if(this.prenotatennisService.settingsData.version_level == 'delux') this.getCheckSeason();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getCalendarDaysData(setSelectedDate?: boolean) {

    this.bLoadingData = true;
    if(setSelectedDate) this.prenotatennisService.adminFilterCalendar.selected_date = this.prenotatennisService.adminFilterCalendar.start_date;

    this.prenotatennisService.getCalendarDaysListDB(this.prenotatennisService.adminFilterCalendar.start_date, this.prenotatennisService.adminFilterCalendar.day_number, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.dashboard && get_response.dashboard.error) {
          //Error
          this.aCalendarDays = [];
          this.openDialog('danger', 'Attenzione, impossibile caricare il calendario' + ': ' + get_response.dashboard.error);
        } else {

          this.aCalendarDays = get_response.dashboard;
          /*this.aCalendarDays.map(x=> {
              x.play_date_format = new Date(x.play_date);
              x.play_date = x.play_date_format.toISOString();
            }
          );*/
          this.getCalendarData();

        }

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  getCalendarData() {

    this.bLoadingData = true;

    this.prenotatennisService.getCalendarListDB(this.prenotatennisService.adminFilterCalendar.selected_date, this.prenotatennisService.userData.token).subscribe(get_response => {

      if(get_response.dashboard && get_response.dashboard.error) {
        //Error
        this.aCalendarData = [];
        this.openDialog('danger', 'Attenzione, impossibile caricare il calendario' + ': ' + get_response.dashboard.error);
      } else {
        this.aCalendarData = get_response.dashboard;

        this.aCalendarData.map(x=> {
          x.playground.map(y => {
            let date_format: Date = new Date(y.date);
            y.date = date_format.toISOString()
          });
        });

      }

      this.bLoadingData = false;

    },
    error => {
      // API call error
      console.log(error);
      this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
      this.bLoadingData = false;
    });

  }

  getCheckSeason() {

    this.prenotatennisService.getCheckSeasonDB(this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.check && get_response.check.error) {
          //Error
          this.openDialog('danger', 'Attenzione, controllo stagionalità non effettuato' + ': ' + get_response.check.error);
        } else {
          this.checkSeason = get_response.check;
        }

      },
      error => {
        // API call error
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
      });

  }

  getDayDescription(play_date: string) {
    let pd: Date = new Date(play_date);
    return this.prenotatennisService.getWeekDayDescription(pd.getDay());
  }

  newBooking(selectedDay: string, selectedPG: number, PG_name: string, selectedHour: calendarAvailableHourData){

    let canViewBooking: boolean = false;

    if(this.prenotatennisService.userData.level == 'admin') canViewBooking = true;
    if(this.prenotatennisService.userData.level !== 'admin' && selectedHour.booked.id_book !== 0 && selectedHour.booked.id_user == this.prenotatennisService.userData.id) canViewBooking = true;
    if(this.prenotatennisService.userData.level !== 'admin' && selectedHour.booked.id_book == 0) canViewBooking = true;

    if(!selectedHour.close && canViewBooking) {

      this.prenotatennisService.oBookSelected = {
        day: selectedDay,
        pg: selectedPG,
        pg_name: PG_name,
        hour: selectedHour
      }
      this.prenotatennisService.oBookIDSelected = 0;

      this.router.navigate(['prenotazione']);

    }

  }

  newMultipleBooking(id_playground: number, pg_name: string, data_play: string) {

    let dayOfWeek: number = 0;
    dayOfWeek = new Date(data_play).getDay();

    let oMultipleBooking: bookingMultipleData = this.prenotatennisService.getNewBookingMultipleData();
    oMultipleBooking.id_playground = id_playground;
    oMultipleBooking.day_of_week = dayOfWeek;
    oMultipleBooking.playground_name = pg_name;
    oMultipleBooking.from_dt = data_play;
    oMultipleBooking.code = "";

    const modalRef = this.modalService.open(BookingMultipleComponent, { size: 'xl' });
    modalRef.componentInstance.oMultipleBooking = oMultipleBooking;
    modalRef.componentInstance.saveAnswer.subscribe((confirmation: boolean) => {

      if(confirmation) {
        this.getCalendarData();
      }

    });

  }

  newAllDayBooking(pg: calendarPlaygroundData) {

    let calendarAvailableHours: Array<calendarAvailableHourData> = pg.available_hour;

    calendarAvailableHours = calendarAvailableHours.filter(x => x.booked.id_book == 0 && !x.close);

    let oAllDayBooking: bookingAllDayPLaygroundData = {
      id: 0,
      id_playground: pg.pg_id,
      playground_name: pg.pg_name,
      id_user: this.prenotatennisService.userData.id,
      play_date: pg.date,
      hours: calendarAvailableHours,
      notes: ""
    }

    const modalRef = this.modalService.open(BookingAlldayComponent, { size: 'xl' });
    modalRef.componentInstance.oAllDayBooking = oAllDayBooking;
    modalRef.componentInstance.saveAnswer.subscribe((confirmation: boolean) => {

      if(confirmation) {
        this.getCalendarData();
      }

    });

  }

  scrollElement(element:string, direction:string, amount:number){

    const el = document.getElementById(element)
    if(direction=='right') el!.scrollLeft += amount;
    if(direction=='left') el!.scrollLeft -= amount;
  }

  selectDayToView(calendarDay: calendarDaysData) {
    if(calendarDay.club_closure) return;
    this.prenotatennisService.adminFilterCalendar.selected_date = calendarDay.play_date;
    this.getCalendarData();
  }

}
