<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header bg-primary text-white">
  <h4>Aggiungi un giocatore alla partita</h4>
</div>

<div class="modal-body">

  <section *ngIf="!prenotatennisService.settingsData.list_players">
    <div class="row">
      <div class="col-12">
        <label>Cerca giocatore</label>
        <div><span class="small text-muted">Digita almeno 3 caratteri</span></div>
        <input type="text" class="form-control mt-3" placeholder="Inizia a digitare il nome" [(ngModel)]="filterValue" (keyup)="filterList()">
      </div>
    </div>
    <div class="list-group">
      <div class="list-group-item list-group-item-action pointer" *ngFor="let player of aAvailableUserFilter" (click)="selectPlayer(player.id)">
        <div class="d-flex align-items-center">
          <div class="prev-prof-img-small col-2" [ngStyle]="{'background-image' : 'url(data:image/jpeg;base64,'+ player.profile_image +')'}"></div>
          <div class="me-1 col-10">
            {{player.surname}} {{player.name}}
            <span class="small text-muted">{{getLevelDescription(player.game_level)}}</span>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="list-group mb-5" *ngIf="prenotatennisService.settingsData.list_players">
    <div class="row">
      <div class="col-12">
        <label>Seleziona un giocatore</label>

        <div class="mt-3">
          <button class="btn-xl btn-block bg-white border p-3 all-round dropdown-toggle dropdown-select" role="button" id="dropdownPLayerList" data-bs-toggle="dropdown" aria-expanded="false">
            <span>Seleziona un giocatore</span>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownPLayerList">
            <div class="row">
              <div class="col-10">
                <input type="text" class="form-control ms-2" placeholder="Cerca" [(ngModel)]="filterValue" (keyup)="filterDDLList()">
              </div>
            </div>

            <div class="px-2 py-2 bg-white" *ngFor="let player of aAvailableUserFilter">
              <div class="d-flex player-hover p-1 pointer" (click)="selectPlayer(player.id)">
                <div class="prev-prof-img-small me-2" [ngStyle]="{'background-image' : 'url(data:image/jpeg;base64,'+ player.profile_image +')'}"></div>
                <div class="me-1 flex-fill">
                  {{player.surname}} {{player.name}}
                  <div>
                    <span class="small text-muted">{{getLevelDescription(player.game_level)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>

      </div>
    </div>
  </div>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('')">CHIUDI</button>
</div>
