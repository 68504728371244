import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {bookingPlayerData, playerData} from "../../interfaces/interfaces";

@Component({
  selector: 'app-player-list',
  templateUrl: './player-list.component.html',
  styleUrls: ['./player-list.component.scss']
})
export class PlayerListComponent implements OnInit {

  public bLoadingData: boolean = false;

  public aAvailableUser: Array<playerData> = [];
  public aAvailableUserFilter: Array<playerData> = [];
  public filterValue: string = "";
  public playerSelected: playerData = this.prenotatennisService.getNewPlayerData();

  @Input() date_play: string = "";
  @Output() playerIDAnswer = new EventEmitter<number>();

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.getBookingAvailableUser();
    console.log(this.prenotatennisService.settingsData);
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getBookingAvailableUser() {

    this.bLoadingData = true;

    this.prenotatennisService.getBookingAvailableUserDB(this.date_play, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.users && get_response.users.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare la lista utenti' + ': ' + get_response.users.error);
          this.bLoadingData = false;
        } else {
          this.aAvailableUser = get_response.users;
          if(this.prenotatennisService.settingsData.list_players) this.aAvailableUserFilter = this.aAvailableUser;
          // if check member code expiration
          if (this.prenotatennisService.settingsData.check_member_valid) this.aAvailableUser = this.aAvailableUser.filter(player => !player.member_expired);
          if (this.prenotatennisService.settingsData.check_cert_med) this.aAvailableUser = this.aAvailableUser.filter(player => !player.medical_expired);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore server imprevisto: ' + error);
      });

  }

  getLevelDescription(player_level: number) {
    let findLevel: Array<any> = [];
    findLevel = this.prenotatennisService.settingsData.player_levels.filter((x: any) => x.id === player_level);
    if(findLevel.length > 0) return findLevel[0].description;
    return "n.a."
  }

  filterList() {

    this.aAvailableUserFilter = [];
    if(this.filterValue && this.filterValue.length >= 3) {
      this.aAvailableUserFilter = this.aAvailableUser.filter(x => x.name.toLowerCase().indexOf(this.filterValue.toLowerCase()) !== -1 || x.surname.toLowerCase().indexOf(this.filterValue.toLowerCase()) !== -1);
    }

  }

  filterDDLList() {

    this.aAvailableUserFilter = this.aAvailableUser;
    if(this.filterValue && this.filterValue.length > 0) {
      this.aAvailableUserFilter = this.aAvailableUser.filter(x => x.name.toLowerCase().indexOf(this.filterValue.toLowerCase()) !== -1 || x.surname.toLowerCase().indexOf(this.filterValue.toLowerCase()) !== -1);
    }

  }

  selectPlayer(id_player: number){
    this.filterValue = "";
    this.aAvailableUserFilter = [];
    if(this.prenotatennisService.settingsData.list_players) this.aAvailableUserFilter = this.aAvailableUser;
    this.playerIDAnswer.emit(id_player);
  }

}
