<div class="modal-header bg-primary text-white">
  <h4 class="modal-title">Recupera password di accesso</h4>
  <button type="button" class="btn btn-default" aria-label="Close" (click)="closePupup()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p class="text-justify">
    Inserisci l'indirizzo e-mail utilizzato per la registrazione.
  </p>
  <div>
    <input type="text" class="form-control" [(ngModel)]="UserNameRecovery" placeholder="Indirizzo e-mail">
  </div>
  <div class="text-center mt-2">
    <button class="btn btn-primary btn-lg" (click)="getRecoveryPassword()">RECUPERA</button>
    <div class="alert alert-warning mt-1" *ngIf="bLoadingData">
      <i class="fa fa-spinner fa-spin"></i> Recupero password in corso...
    </div>
  </div>
  <div class="alert alert-info mt-3">
    Se non ricordi l'indirizzo e-mail utilizzato per la registrazione contatta la segreteria.
    <div class="mt-1">
      <strong><i class="fa fa-envelope"></i> </strong> {{prenotatennisService.settingsData.club_mail}}
    </div>
    <div class="mt-1">
      <strong><i class="fa fa-phone"></i></strong> {{prenotatennisService.settingsData.club_phone}}
    </div>
  </div>

</div>
<div class="modal-footer">
  <!--<button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Annulla</button>-->
  <button type="button" class="btn btn-secondary" (click)="closePupup()">CHIUDI</button>
</div>
