import { Component, OnInit } from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {AlertDialogComponent} from "../../components/alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-blacklist',
  templateUrl: './blacklist.component.html',
  styleUrls: ['./blacklist.component.scss']
})
export class BlacklistComponent implements OnInit {

  constructor(public prenotatennisService: PrenotatennisService,
              private modalService: NgbModal, private router: Router) { }

  ngOnInit(): void {
    if(this.prenotatennisService.userData && this.prenotatennisService.userData.id == 0) this.router.navigate(['home']);
  }

}
