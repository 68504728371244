import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {memberData} from "../../interfaces/interfaces";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {NgbModal, NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {LogService} from "../../services/log.service";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  @Input() id_user: number = 0; // 0 => edit profile
  @Input() edit_type: string = ''; // profile | gest
  @Output() saveAnswer = new EventEmitter<boolean>();

  public oMember: memberData = this.prenotatennisService.getNewMemberData();
  public bLoadingData: boolean = false;
  public sNewPsw: string = "";
  public sReplyNewPsw: string = "";
  public aMunicipalities: Array<any> = [];

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal, public activeModal: NgbActiveModal,
              private logService: LogService) { }

  ngOnInit(): void {
    if(this.id_user == 0) {
      this.oMember = this.prenotatennisService.getNewMemberData();
    }
    if(this.id_user !== 0) {
      this.oMember.id = this.id_user;
      this.getUserProfile();
    }

  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getUserProfile() {

    this.bLoadingData = true;

    this.prenotatennisService.getUserListDB(this.prenotatennisService.userData.token, this.oMember.id).subscribe(get_response => {

        if(get_response.user && get_response.user.error) {
          //Error
          this.prenotatennisService.bLoggedIn = false;
          this.openDialog('danger', 'Attenzione, impossibile caricare il tuo profilo' + ': ' + get_response.user.error);
        } else {

          this.oMember = get_response.users[0];
          this.loadMunicipality();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  loadMunicipality() {

    this.prenotatennisService.getMunicipalitiesDB(this.oMember.province, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.municipality && get_response.municipality.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i comuni' + ': ' + get_response.municipality.error);
        } else {

          this.aMunicipalities = get_response.municipality;
        }

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
      });

  }

  checkUserConsistency() {

    if(this.oMember.name == '') return true;
    if(this.oMember.surname == '') return true;
    if(this.oMember.email == '') return true;
    if(this.oMember.game_level == 0) return true;
    if(this.oMember.username == '') return true;
    if(this.oMember.member_expiry == '') return true;

    return false;

  }

  saveProfile() {
    if(this.oMember.id == 0) this.insertUser();
    if(this.oMember.id !== 0) this.updateUser();
  }

  insertUser() {

    this.bLoadingData = true;

    this.prenotatennisService.postInsertUserDB(this.oMember, this.prenotatennisService.userData.token).subscribe(insert_response => {

        if(insert_response.user && insert_response.user.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il profilo: ' + JSON.stringify(insert_response.user.error));
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("user profile", "Insert error: " + JSON.stringify(insert_response.user.error)));
        } else {
          this.openDialog('success', 'Profilo inserito con successo!');
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("user profile", "Insert success - ID user: " + insert_response.user.id));
          this.oMember.id = insert_response.user.id;
          this.getUserProfile();
          this.saveAnswer.emit(true);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.logService.callInsertLog(this.prenotatennisService.getNewLogData("user profile", "Insert error - server call: " + JSON.stringify(error)));
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  updateUser() {

    this.prenotatennisService.postUpdateUserDB(this.oMember, this.prenotatennisService.userData.token).subscribe(update_response => {

        if(update_response.user && update_response.user.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il profilo: ' + update_response.user.error);
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("user profile", "Update error - user: " + JSON.stringify(this.oMember)));
        } else {
          this.openDialog('success', 'Profilo modificato con successo!');
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("user profile", "Update success - user: " + JSON.stringify(this.oMember)));
          this.prenotatennisService.getUserLoginData();
          this.saveAnswer.emit(true);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.logService.callInsertLog(this.prenotatennisService.getNewLogData("user profile", "Update error - server call: " + error));
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );
  }

  deleteUser() {

    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = 'confirm';
    modalRef.componentInstance.message = "Attenzione, si sta per eliminare il profilo utente, procedere?";

    modalRef.componentInstance.questionAnswer.subscribe((receivedEntry: boolean) => {
      if(receivedEntry) {

        this.bLoadingData = true;

        this.prenotatennisService.postDeleteUserDB(this.oMember, this.prenotatennisService.userData.token).subscribe(update_response => {

            if(update_response.user && update_response.user.error) {
              //Error
              this.openDialog('danger', 'Attenzione, impossibile eliminare il profilo: ' + update_response.user.error);
              this.logService.callInsertLog(this.prenotatennisService.getNewLogData("user profile", "Delete error ID user: " + this.oMember.id + " - Error: " + update_response.user.error));
            } else {
              this.openDialog('success', 'Profilo eliminato con successo!');
              this.logService.callInsertLog(this.prenotatennisService.getNewLogData("user profile", "Delete success user: " + JSON.stringify(this.oMember)));
              this.saveAnswer.emit(true);
              this.activeModal.close('');
            }

            this.bLoadingData = false;

          },
          error => {
            // API call error
            console.log(error);
            this.logService.callInsertLog(this.prenotatennisService.getNewLogData("user profile", "Delete error: " + error));
            this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
            this.bLoadingData = false;
          }
        );

      }
    });

  }

  changePassword() {

    this.bLoadingData = true;
    this.oMember.psw = this.sNewPsw;

    this.prenotatennisService.postChangePswUserDB(this.oMember, this.prenotatennisService.userData.token).subscribe(update_response => {

      console.log(update_response);
        if(update_response.user && update_response.user.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile modificare la password: ' + update_response.user.error);
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("user profile", "Change password error ID " + this.oMember.id + ": " + update_response.user.error));
        } else {
          this.openDialog('success', 'Password modificata con successo!');
          this.logService.callInsertLog(this.prenotatennisService.getNewLogData("user profile", "Change password ID " + this.oMember.id));
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.logService.callInsertLog(this.prenotatennisService.getNewLogData("user profile", "Change password server error ID " + this.oMember.id + ": " + error));
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  /*******************************/
  /* CONVERT IMAGE TO BASE 64 ****/
  /*******************************/

  public picked(event: any) {

    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {

      this.resizeImage(fileList[0], 600, 600).then(blob => {
        //You can upload the resized image: doUpload(blob)
        // document.getElementById('img').src = URL.createObjectURL(blob);
        this.handleInputChange(blob);

      }, err => {
        console.error("Photo error", err);
      });

    } else {
      alert('No file selected');
    }

  }

  handleInputChange(files: any) {
    const file = files;
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onloadend = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e: any) {
    const reader = e.target;
    const base64result = reader.result.substr(reader.result.indexOf(',') + 1);
    this.oMember.profile_image = base64result;
  }

  resizeImage(file:File, maxWidth:number, maxHeight:number):Promise<Blob> {
    return new Promise((resolve: any, reject: any) => {
      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        let width = image.width;
        let height = image.height;

        if (width <= maxWidth && height <= maxHeight) {
          resolve(file);
        }

        let newWidth;
        let newHeight;

        if (width > height) {
          newHeight = height * (maxWidth / width);
          newWidth = maxWidth;
        } else {
          newWidth = width * (maxHeight / height);
          newHeight = maxHeight;
        }

        let canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        let context: any = canvas.getContext('2d');

        context.drawImage(image, 0, 0, newWidth, newHeight);

        canvas.toBlob(resolve, file.type);
      };
      image.onerror = reject;
    });
  }

}
