import { Component, OnInit } from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {userData} from "../../interfaces/interfaces";
import {AlertDialogComponent} from "../../components/alert-dialog/alert-dialog.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UserProfileComponent} from "../../components/user-profile/user-profile.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  public bLoadingData: boolean = false;
  public aUsers: Array<userData> = [];
  public oFilter: any = {
    code: "",
    name: "",
    email: "",
    cert_med_expired: false,
    member_expired: false,
    status_active: false,
    status_close: false,
    user_count: 0
  }

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              private router: Router, ) { }

  ngOnInit(): void {
    if(this.prenotatennisService.userData.id == 0) this.router.navigate(['home']);
    if(this.prenotatennisService.userData.id !== 0) this.getUsersList();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getUsersList() {

    this.bLoadingData = true;

    this.prenotatennisService.getUserListDB(this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.user && get_response.user.error) {
          //Error
          this.bLoadingData = false;
          if(get_response.booking.error == 'Token not found') this.router.navigate(['home']);
          if(get_response.booking.error !== 'Token not found') {
            this.openDialog('danger', 'Attenzione, impossibile caricare i dati utenti' + ': ' + get_response.user.error);
          }

        } else {

          this.aUsers = get_response.users;
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  filterList(aoUser: Array<userData>) {

    if (aoUser && aoUser.length > 0) {
      if (this.oFilter.name !== '') aoUser = aoUser.filter(x => x.name.toLowerCase().indexOf(this.oFilter.name.toLowerCase()) !== -1 || x.surname.toLowerCase().indexOf(this.oFilter.name.toLowerCase()) !== -1);
      if (this.oFilter.code !== '') aoUser = aoUser.filter(x => x.member_code.toLowerCase().indexOf(this.oFilter.code.toLowerCase()) !== -1);
      if (this.oFilter.email !== '') aoUser = aoUser.filter(x => x.email.toLowerCase().indexOf(this.oFilter.email.toLowerCase()) !== -1);
      if(this.oFilter.cert_med_expired) aoUser = aoUser.filter(x => x.medical_day_diff <= 0);
      if(this.oFilter.member_expired) aoUser = aoUser.filter(x => x.member_day_diff <= 0);
      if(this.oFilter.status_active) aoUser = aoUser.filter(x => x.status);
      if(this.oFilter.status_close) aoUser = aoUser.filter(x => !x.status);
    }

    this.oFilter.user_count = aoUser.length;
    return aoUser;

  }

  editUser(id_user: number) {
    const modalRef = this.modalService.open(UserProfileComponent, { size: 'xl' });
    modalRef.componentInstance.id_user = id_user;
    modalRef.componentInstance.edit_type = 'gest';
    modalRef.componentInstance.saveAnswer.subscribe((confirmation: boolean) => {

      if(confirmation) {
        this.getUsersList();
      }
    });
  }

  exportPartnerList() {

    this.bLoadingData = true;

    this.prenotatennisService.getExportUserListDB(this.prenotatennisService.userData.token).subscribe(export_response => {

        if(export_response.export && export_response.export.error) {
          //Error
          this.openDialog('danger', 'Attenzione: impossibile scaricare la lista dei soci: ' + export_response.export.error);
        } else {

          const blob = new Blob([export_response.export.csv_string], { type: 'application/octet-stream'});
          const a = document.createElement('a');
          a.setAttribute('href', URL.createObjectURL(blob));
          a.setAttribute('download', 'prenota_tennis_players_list.csv');
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione: errore imprevisto nel caricamento dei dati');
        this.bLoadingData = false;
      });

  }

}
