<app-loader *ngIf="bLoadingData"></app-loader>

<div class="row mt-2 mb-3">
  <div class="col-12 text-center">
    <div class="d-flex flex-row justify-content-center flex-wrap">
      <div class="asd_box_command text-center me-2" *ngFor="let cb of aCreditBox">
        <h3 class="text-success fs-2 fw-bold">{{cb.credit}}</h3>
        <div class="small text-muted mb-2">{{cb.description}}</div>
        <span class="text-primary fs-4 fw-bold">{{cb.price | number: '1.2-2'}} €</span>
        <div class="p-3 me-auto mt-auto">
          <button class="btn btn-success" (click)="sendRequestPayPal(cb)">ACQUISTA ORA</button>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center mt-2 mb-3">
    <img class="pp_logo" src="./assets/img/paypal-logo.png" alt="Pagamenti sicuri con PayPal">
  </div>
  <div class="col-12 mt-3">
    <div class="text-center alert alert-info" *ngIf="bLoadingRecharge">
      Caricamento dei dati in corso...
    </div>
    <div class="alert alert-secondary text-end">
      <span class="fw-bold fs-6">Totale ricariche: </span>
      <span class="text-success fw-bold">{{iRechargeSum | number: '1.2-2'}}</span> €
    </div>

    <table class="table table-bordered table-striped table-hover">
      <thead>
        <tr>
          <td>Codice</td>
          <td>Crediti</td>
          <td>Prezzo</td>
          <td class="text-center">Status</td>
          <td>Info</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let recharge of aRechargeList">
          <td>{{recharge.code}}</td>
          <td>{{recharge.credit}}</td>
          <td>{{recharge.price | number: '1.2-2'}}</td>
          <td class="text-center">
            <span class="text-danger" *ngIf="recharge.status == 'ERR'">Pagamento in errore</span>
            <i class="fa fa-triangle-exclamation text-warning" *ngIf="recharge.status == 'INS'"></i>
            <i class="fa fa-check-circle text-success" *ngIf="recharge.status == 'OK'"></i>
          </td>
          <td>
            <div class="small">Data ins.: {{recharge.date_ins | date: 'dd/MM/yyyy HH:mm:ss'}}</div>
            <div class="small">Ultima mod.: {{recharge.date_upd | date: 'dd/MM/yyyy HH:mm:ss'}}</div>
            <div class="small" *ngIf="recharge.txn_id">
              Codice transazione PayPal: {{recharge.txn_id}}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="pb-5">
  <!-- Space for menu bug fix -->
</div>




