<app-loader *ngIf="bLoadingData"></app-loader>
  <div class="row my-3">
    <div class="col-12 col-md-4 d-flex m-auto">
      <button class="btn flex-grow-1 btn-success me-2" (click)="editUser(0)"><span class="fa fa-plus me-1"></span>Nuovo Utente</button>
      <button class="btn btn-secondary" (click)="exportPartnerList()"><i class="fa fa-download"></i> Scarica lista</button>
    </div>
    <div class="col-12 mt-3">
      <div class="accordion" id="">
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOne">
            <button class="accordion-button collapsed rounded" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
              <span class="fas fa-filter me-1"></span>Filtri
            </button>
          </h2>
          <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
              <div class="row">
                <div class="col-12 col-md-4 mt-2">
                  <label>Tessera</label>
                  <input type="text" class="form-control" [(ngModel)]="oFilter.code" placeholder="Cerca tessera">
                </div>
                <div class="col-12 col-md-4 mt-2">
                  <label>Cognome / Nome</label>
                  <input type="text" class="form-control" [(ngModel)]="oFilter.name" placeholder="Cerca Nome/Cognome">
                </div>
                <div class="col-12 col-md-4 mt-2">
                  <label>Email</label>
                  <input type="text" class="form-control" [(ngModel)]="oFilter.email" placeholder="Cerca Email">
                </div>

                <div class="col-12 col-md-3 mt-2">
                  <label>Profilo attivo</label>
                  <div>
                    <button class="btn btn-sm" [class]="{'btn-warning': !oFilter.status_active, 'btn-success': oFilter.status_active}" (click)="oFilter.status_active = !oFilter.status_active">ATTIVA</button>
                  </div>
                </div>

                <div class="col-12 col-md-3 mt-2">
                  <label>Profilo bloccato</label>
                  <div>
                    <button class="btn btn-sm" [class]="{'btn-warning': !oFilter.status_close, 'btn-success': oFilter.status_close}" (click)="oFilter.status_close = !oFilter.status_close">ATTIVA</button>
                  </div>
                </div>

                <div class="col-12 col-md-3 mt-2" *ngIf="prenotatennisService.settingsData.check_cert_med">
                  <label>Cert. medico scaduto</label>
                  <div>
                    <button class="btn btn-sm" [class]="{'btn-warning': !oFilter.cert_med_expired, 'btn-success': oFilter.cert_med_expired}" (click)="oFilter.cert_med_expired = !oFilter.cert_med_expired">ATTIVA</button>
                  </div>
                </div>
                <div class="col-12 col-md-3 mt-2" *ngIf="prenotatennisService.settingsData.check_member_valid">
                  <label>Tessera scaduta</label>
                  <div>
                    <button class="btn btn-sm" [class]="{'btn-warning': !oFilter.member_expired, 'btn-success': oFilter.member_expired}" (click)="oFilter.member_expired = !oFilter.member_expired">ATTIVA</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3 pb-5">
    <div class="col-12 table-responsive">

      <div class="mt-1 p-2 mb-3 text-muted text-end border">
        Totale <span *ngIf="!bLoadingData">{{oFilter.user_count}}</span>
      </div>

      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Codice</th>
            <th>Cognome / Nome</th>
            <th>Email</th>
            <th *ngIf="prenotatennisService.settingsData.check_member_valid">Tessera</th>
            <th *ngIf="prenotatennisService.settingsData.check_cert_med">Cert. medico</th>
            <th>Status</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of filterList(aUsers)">
            <td>{{user.member_code}}</td>
            <td>{{user.surname}} {{user.name}}</td>
            <td>{{user.email}}</td>
            <td *ngIf="prenotatennisService.settingsData.check_member_valid">
              <span class="badge date" *ngIf="prenotatennisService.settingsData.check_member_valid">
                <strong>{{user.member_expiry | date: 'dd/MM/yyyy'}}</strong>
              </span>
              <span class="badge"
                    [class]="{'bg-success': user.member_day_diff > 30,
                              'bg-warning': user.member_day_diff <= 30 && user.member_day_diff > 0,
                              'bg-danger': user.member_day_diff <= 0
                              }">{{user.member_day_diff}}
                </span>
            </td>
            <td *ngIf="prenotatennisService.settingsData.check_cert_med">
              <span class="badge date">{{user.medical_expiry | date: 'dd/MM/yyyy'}}</span>
              <span class="badge"
                    [class]="{'bg-success': user.medical_day_diff > 30,
                              'bg-warning': user.medical_day_diff <= 30 && user.medical_day_diff > 0,
                              'bg-danger': user.medical_day_diff <= 0
                              }">{{user.medical_day_diff}}
                </span>
            </td>
            <td class="text-center">
              <i class="fa" [class]="user.status ? 'fa-circle-check text-success':'fa-ban text-danger'"></i>
            </td>
            <td>
              <div class="pointer" (click)="editUser(user.id)"><span class="fas fa-edit"></span></div>
            </td>
          </tr>
        </tbody>
      </table>

      <!--<ngx-datatable
        class="bootstrap mobile-table"
        [rows]="filterList(aUsers)"
        [loadingIndicator]="bLoadingData"
        [columnMode]="'flex'"
        [headerHeight]="50"
        [footerHeight] = "50"
        rowHeight="auto"
        [reorderable]="true"
        [limit]="20"
      >

        <ngx-datatable-column prop="member_code" name="Codice" [flexGrow]="0.5"></ngx-datatable-column>
        <ngx-datatable-column name="Nome" prop="surname" [flexGrow]="1">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.surname}} {{row.name}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column prop="email" name="E-mail" [flexGrow]="1"></ngx-datatable-column>

        <ngx-datatable-column prop="member_day_diff" [sortable]="true" [flexGrow]="1" *ngIf="prenotatennisService.settingsData.check_member_valid">
          <ng-template ngx-datatable-header-template>
            <span>Tessera</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="badge date text-black"><strong>{{row.member_expiry | date: 'dd/MM/yyyy'}}</strong></span>
            <span class="badge"
                  [class]="{'bg-success': row.member_day_diff > 30,
                              'bg-warning': row.member_day_diff <= 30 && row.member_day_diff > 0,
                              'bg-danger': row.member_day_diff <= 0
                              }">{{row.member_day_diff}}
              </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="medical_day_diff" [sortable]="true" [flexGrow]="1" *ngIf="prenotatennisService.settingsData.check_cert_med">
          <ng-template ngx-datatable-header-template>
            <span>Cert. medico</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="badge date text-black">{{row.medical_expiry | date: 'dd/MM/yyyy'}}</span>
            <span class="badge"
                  [class]="{'bg-success': row.medical_day_diff > 30,
                              'bg-warning': row.medical_day_diff <= 30 && row.medical_day_diff > 0,
                              'bg-danger': row.medical_day_diff <= 0
                              }">{{row.medical_day_diff}}
              </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="0.5">
          <ng-template ngx-datatable-header-template>
            <span>#</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="" (click)="editUser(row.id)"><span class="fas fa-edit"></span></div>
          </ng-template>
        </ngx-datatable-column>

      </ngx-datatable>

    </div>
  </div>

  <div class="pb-5">
    Space for menu bug fix
  </div>
    -->
    </div>
  </div>
