import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {playgrondPriceData} from "../../interfaces/interfaces";
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-price-list-edit',
  templateUrl: './price-list-edit.component.html',
  styleUrls: ['./price-list-edit.component.scss']
})
export class PriceListEditComponent implements OnInit {

  @Input() id_playground: number = 0;
  @Input() start_hour: string = "";
  @Input() end_hour: string = "";

  public oPrice: playgrondPriceData = this.prenotatennisService.getNewPGPrice(this.start_hour, this.end_hour, this.id_playground);
  public bLoadingData: boolean = false;
  public bOpenEdit: boolean = false;

  public aPrice: Array<playgrondPriceData> = [];
  public aPriceWinter: Array<playgrondPriceData> = [];
  public aPriceSummer: Array<playgrondPriceData> = [];

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal) { }

  ngOnInit(): void {
    if(this.id_playground !== 0) this.getPriceListData();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getPriceListData() {

    this.bLoadingData = true;

    this.prenotatennisService.getPGPriceListDB(this.id_playground, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.list && get_response.list.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare il listino prezzi' + ': ' + get_response.list.error);
        } else {
          this.aPrice = get_response.list;
          this.aPriceWinter = this.aPrice.filter(x => x.season == 'winter');
          this.aPriceSummer = this.aPrice.filter(x => x.season == 'summer');
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  addNewPrice(season: string) {

    this.bOpenEdit = true;
    this.oPrice = this.prenotatennisService.getNewPGPrice(this.start_hour, this.end_hour, this.id_playground);
    this.oPrice.season = season;

  }

  editPrice(oPriceRow: playgrondPriceData) {

    this.bOpenEdit = true;
    this.oPrice = oPriceRow;

  }

  savePrice() {
    if(this.oPrice.id == 0) this.insertPGPrice();
    if(this.oPrice.id !== 0) this.updatePGPrice();
  }

  insertPGPrice(){

    this.bLoadingData = true;

    this.prenotatennisService.postInsertPGPriceDB(this.oPrice, this.prenotatennisService.userData.token).subscribe(insert_response => {

        if(insert_response.list && insert_response.list.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il prezzo: ' + insert_response.list.error);
          this.bLoadingData = false;
        } else {

          this.closePrice();
          this.getPriceListData();
        }

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );


  }

  updatePGPrice(){

    this.bLoadingData = true;

    this.prenotatennisService.postUpdatePGPriceDB(this.oPrice, this.prenotatennisService.userData.token).subscribe(update_response => {

        if(update_response.list && update_response.list.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il prezzo: ' + update_response.list.error);
          this.bLoadingData = false;
        } else {
          this.openDialog('success', 'Prezzo modificato con successo');
          this.closePrice();
          this.getPriceListData();
        }

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  deletePGPrice(oPriceData: playgrondPriceData){

    this.bLoadingData = true;

    this.prenotatennisService.postDeletePGPriceDB(oPriceData, this.prenotatennisService.userData.token).subscribe(update_response => {

        if(update_response.list && update_response.list.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il prezzo: ' + update_response.list.error);
          this.bLoadingData = false;
        } else {
          this.openDialog('success', 'Prezzo eliminato con successo');
          this.getPriceListData();
        }

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  closePrice() {
    this.bOpenEdit = false;
    this.oPrice = this.prenotatennisService.getNewPGPrice(this.start_hour, this.end_hour, this.id_playground);
  }

}
