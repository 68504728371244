
<app-loader *ngIf="bLoadingData"></app-loader>

<section id="loginForm" *ngIf="!prenotatennisService.bLoggedIn">
  <div class="container d-flex  align-items-center flex-column justify-content-center pt-3">
    <div class="login-box flex-grow-1 border bg-grey p-4 shadow-sm d-flex flex-column align-items-center rounded">
      <form>
        <div class="mb-4">
          <label class="mb-1" for="userName">E-mail</label>
          <input type="text" class="form-control" (keyup.enter)="login()" [(ngModel)]="oUserLogin.user_name" id="userName" name="userName" aria-describedby="userName" placeholder="E-mail">
        </div>
        <div class="mb-4">
          <label class="mb-1" for="password">Password</label>
          <input type="password" [(ngModel)]="oUserLogin.password" (keyup.enter)="login()" name="password" class="form-control" id="password" placeholder="Password">
        </div>
        <div class="d-grid gap-2">
          <button class="btn btn-primary" type="button" (click)="login()">ENTRA</button>
        </div>
        <div class="mt-4">
          <div class="link-primary pointer" (click)="recoveryPassword()">Password dimenticata?</div>
        </div>
        <div class="mt-1" *ngIf="prenotatennisService.settingsData.user_register_free">
          Non sei registrato? <span class="link-primary pointer" (click)="registerNewUser()">Registrati ora!</span>
        </div>
      </form>
    </div>

    <!--footer mobile-->
    <div class="footer-wrapper mt-5 col-12">
      <app-footer></app-footer>
    </div>

  </div>
</section>
