import { Component, OnInit } from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {AlertDialogComponent} from "../../components/alert-dialog/alert-dialog.component";
import {bookingData, bookingPlayerData, bookingShareMailData} from "../../interfaces/interfaces";

@Component({
  selector: 'app-shared-booking',
  templateUrl: './shared-booking.component.html',
  styleUrls: ['./shared-booking.component.scss']
})
export class SharedBookingComponent implements OnInit {

  public bLoadingData: boolean = false;
  public aBookingList: Array<bookingData> = [];
  public aDateList: Array<string> = [];
  public selectedDay: string = new Date().toISOString().slice(0, 10);

  public filter: any = {
    date_from: "",
    date_to: "",
    game_level: true
  }

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              private router: Router) { }

  ngOnInit(): void {
    if(this.prenotatennisService.userData && this.prenotatennisService.userData.id == 0) this.router.navigate(['home']);
    if(this.prenotatennisService.userData && this.prenotatennisService.userData.id !== 0) {
      let from: Date = new Date();
      let to: Date = new Date();

      to.setDate(from.getDate() + 10);

      this.filter.date_from = from.toISOString().slice(0, 10);
      this.filter.date_to = to.toISOString().slice(0, 10);

      // list of available date
      while(from <= to){
        this.aDateList.push(from.toISOString().slice(0, 10));
        from.setDate(from.getDate() + 1);
      }

      this.getShareBookingListData();
    }
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getDayDescription(play_date: string) {
    let pd: Date = new Date(play_date);
    return this.prenotatennisService.getWeekDayDescription(pd.getDay());
  }

  getPlayLevelDescription(user_play_level: number){
    let player: Array<any> = [];
    player = this.prenotatennisService.settingsData.player_levels.filter((x: any)=> x.id == user_play_level);
    if(player.length > 0) return player[0].description;
    return "n.a.";
  }

  getSportIco(sport_id: number){
    return this.prenotatennisService.settingsData.sports.filter((x:any)=>x.id == sport_id)[0].icon;
  }

  selectFilterDay(date_play: string){
    this.selectedDay = date_play;
    this.filter.date_from = date_play;
    this.getShareBookingListData();
  }

  getShareBookingListData() {

    this.bLoadingData = true;
    let from: Date = new Date(this.filter.date_from);
    let to: Date = new Date(this.filter.date_to);

    this.prenotatennisService.getSharedBookingListDB(from, to, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.booking && get_response.booking.error) {
          //Error
          this.bLoadingData = false;
          if(get_response.booking.error == 'Token not found') this.router.navigate(['home']);
          if(get_response.booking.error !== 'Token not found') {
            this.openDialog('danger', 'Attenzione, impossibile la lista delle prenotazioni condivise' + ': ' + get_response.booking.error);
          }
          this.aBookingList = [];
        } else {
          this.aBookingList = get_response.booking;

          this.aBookingList = this.aBookingList.filter(x => x.id_user !== this.prenotatennisService.userData.id);

          this.aBookingList.map(book => {
            book.user_booked = false;
            let find: Array<any> = [];
            if(book.players) find = book.players.filter((x: any) => x.id == this.prenotatennisService.userData.id);
            if(find.length > 0) book.user_booked = true;
            return book;
          });

        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        this.aBookingList = [];
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  insertCandidature(book: bookingData){
    const modalRef = this.modalService.open(AlertDialogComponent, { size: "xl" });
    modalRef.componentInstance.type = "confirm";
    modalRef.componentInstance.message = "Stai per aggiungerti alla partita, procedere?";

    modalRef.componentInstance.questionAnswer.subscribe((reciveEntry: boolean) => {
      if(reciveEntry){
        this.insertPlayerToBooking(book);
      }
    });
  }

  insertPlayerToBooking(book: bookingData){

    this.bLoadingData = true;

    let oBookingPlayer: bookingPlayerData = this.prenotatennisService.getNewBookingPlayerData(book.id, this.prenotatennisService.userData.id, "CONFIRMED");

    this.prenotatennisService.postInsertBookingPlayerDB(oBookingPlayer, this.prenotatennisService.userData.token).subscribe(insert_response => {

        if(insert_response.booking && insert_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile inviare la tua richiesta: ' + insert_response.booking.error);
        } else {
          this.openDialog('success', "Ti sei aggiunto alla partita con successo! Buona giocata!");
          this.getShareBookingListData();
          //this.sendMail('INS', book);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
      }
    );

  }

  sendMail(type: string, book: bookingData) {

    this.bLoadingData = true;

    let oMail: bookingShareMailData = {
      dest_mail: book.user_email ? book.user_email : '',
      owner_name: book.name ? book.name : '',
      player_surname: this.prenotatennisService.userData.surname,
      player_name: this.prenotatennisService.userData.name,
      player_game_level: this.getPlayLevelDescription(this.prenotatennisService.userData.game_level),
      data_play: book.date_play,
      hour_start: book.hour_start,
      hour_end: book.hour_end,
      playground_name: book.playground_name,
      type: type
    }

    this.prenotatennisService.postShareBookingEmailDB(oMail, this.prenotatennisService.userData.token).subscribe(insert_response => {

        if(insert_response.booking && insert_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile inviare la mail automatica: ' + insert_response.booking.error);
        } else {
          console.log(insert_response);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
      }
    );

  }

  filterBooking(aBook: Array<bookingData>){
    if(this.filter.game_level) aBook = aBook.filter(x => x.user_game_level == this.prenotatennisService.userData.game_level);
    return aBook;

  }

  scrollElement(element:string, direction:string, amount:number){

    const el = document.getElementById(element)
    if(direction=='right') el!.scrollLeft += amount;
    if(direction=='left') el!.scrollLeft -= amount;
  }

}
