import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PrenotatennisService} from "../../services/prenotatennis.service";

@Component({
  selector: 'app-share-booking-fast-confirm',
  templateUrl: './share-booking-fast-confirm.component.html',
  styleUrls: ['./share-booking-fast-confirm.component.scss']
})
export class ShareBookingFastConfirmComponent implements OnInit {

  constructor(public prenotatennisService: PrenotatennisService, private route: ActivatedRoute,
              private router: Router,) { }

  ngOnInit(): void {
    if(this.prenotatennisService.userData.id == 0) this.router.navigate(['home']);
    if(this.prenotatennisService.userData.id !== 0){
      this.route.params.subscribe((routeParams: any) => {
        console.log(routeParams.type);
        console.log(routeParams.id_bookplayer);
      });
    }


    // TODO: controllare che la prenotazione sia dell'utente loggato

  }

}
