<div class="modal-header">
</div>
<div class="modal-body">

  <div class="d-flex justify-content-center align-items-center flex-column py-3">
    <ng-container *ngIf="type=='danger'">
      <div class="fas fa-times-circle text-danger fa-3x mb-3" ></div>
      <div class="text-center">{{message}}</div>
    </ng-container>

    <ng-container *ngIf="type=='warning'">
      <div class="fas fa-exclamation-triangle text-warning fa-3x mb-3" ></div>
      <div class="text-center">{{message}}</div>
    </ng-container>

    <ng-container *ngIf="type=='success'">
      <div class="fas fa-check-circle text-success fa-3x mb-3" ></div>
      <div class="text-center">{{message}}</div>
    </ng-container>

    <ng-container *ngIf="type=='confirm'">
      <div class="fas fa-question-circle text-info fa-3x my-2"></div>
      <div class="text-center">{{message}}</div>
    </ng-container>

  </div>
</div>
<div class="modal-footer">
  <div class="d-flex justify-content-end" *ngIf="type=='confirm'">
    <button type="button" class="btn btn-success me-2" (click)="getConfirmResponse(true)">CONFERMA</button>
    <button type="button" class="btn btn-secondary" (click)="getConfirmResponse(false)">ANNULLA</button>
  </div>
  <button type="button" class="btn btn-secondary" *ngIf="type!='confirm'" (click)="activeModal.close('Close click')">CHIUDI</button>
</div>


