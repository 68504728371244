<app-loader *ngIf="bLoadingData"></app-loader>

<div class="row my-3">
  <div class="col-12 col-md-3 m-auto d-flex">
    <button class="btn flex-grow-1 btn-success" (click)="editPlayground(0)"><span class="fas fa-plus me-1"></span>Nuovo campo</button>
  </div>
</div>

<div class="row mt-4 pb-5">
  <div class=" col-12 col-md-4 pointer" *ngFor="let pg of aPlayground">
    <div class="alert text-center" (click)="editPlayground(pg.id)" [class]="{'alert-success': pg.status, 'alert-danger': !pg.status, 'alert-warning': pg.reserved}">
      <h4>
        <img [src]="'assets/img/sport-ico/'+pg.sport_icon" class="img-small"> {{pg.name}}
      </h4>
      <div>
        Dalle {{pg.start_booking}} alle {{pg.end_booking}}
      </div>
      <div class="small">
        {{pg.description}}
      </div>
      <span class="small" *ngIf="pg.reserved">Riservato</span>
    </div>
  </div>
</div>

<div class="pb-5">
  <!-- Space for menu bug fix -->
</div>
