<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header bg-primary text-white">
  <h4>Profilo Utente</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>

<div class="modal-body">

<div class="row">

  <div class="col-12 d-flex my-3 text-center save-btn-wrapper">
    <button class="btn flex-grow-1 btn-success" (click)="saveProfile()" [disabled]="checkUserConsistency()"><i class="fa fa-save"></i> Salva il profilo</button>
  </div>

  <div class="col-12 col-md-4 mt-3">
    <div class="form-group">
      <label class="mb-1">Nome (*)</label>
      <input type="text" class="form-control" placeholder="nome" [(ngModel)]="oMember.name">
    </div>
  </div>
  <div class="col-12 col-md-4 mt-3">
    <div class="form-group">
      <label class="mb-1">Cognome (*)</label>
      <input type="text" class="form-control" placeholder="cognome" [(ngModel)]="oMember.surname">
    </div>
  </div>
  <div class="col-12 col-md-4 mt-3">
    <div class="form-group">
      <label class="mb-1">Codice Fiscale</label>
      <input type="text" class="form-control" placeholder="codice fiscale" [(ngModel)]="oMember.fiscal_code">
    </div>
  </div>
  <div class="col-12 col-md-4 mt-3">
    <label>Provincia</label>
    <select class="form-control" [(ngModel)]="oMember.province" (change)="loadMunicipality()">
      <option value="--">-- Seleziona provincia --</option>
      <option *ngFor="let prov of prenotatennisService.settingsData.provinces" [value]="prov.provincia_code">{{prov.provincia_desc}}</option>
    </select>
  </div>
  <div class="col-12 col-md-4 mt-3">
    <label>Comune</label>
    <select class="form-control" [(ngModel)]="oMember.municipality">
      <option value="--">-- Seleziona comune --</option>
      <option *ngFor="let munic of aMunicipalities" [value]="munic.cod_comune">{{munic.comune_desc}}</option>
    </select>
  </div>
  <div class="col-12 col-md-4 mt-3">
    <label>CAP</label>
    <input type="text" class="form-control" placeholder="CAP / ZIP Code" [(ngModel)]="oMember.zip">
  </div>
  <div class="col-12 col-md-4 mt-3">
    <label>Indirizzo</label>
    <input type="text" class="form-control" placeholder="via/Piazza" [(ngModel)]="oMember.address">
  </div>
  <div class="col-12 col-md-4 mt-3">
    <label>Nr. Civico</label>
    <input type="text" class="form-control" placeholder="via/Piazza" [(ngModel)]="oMember.address_nr">
  </div>

  <div class="col-12 col-md-4 mt-3">
    <div class="form-group">
      <label class="mb-1">E-mail (*)</label>
      <input type="text" class="form-control" placeholder="email" [(ngModel)]="oMember.email">
    </div>
  </div>
  <div class="col-12 col-md-4 mt-3">
    <div class="form-group">
      <label class="mb-1">Telefono</label>
      <input type="text" class="form-control" placeholder="recapito telefonico" [(ngModel)]="oMember.phone">
    </div>
  </div>
  <div class="col-12 col-md-4 mt-3">
    <div class="form-group">
      <label class="mb-1">Data Nascita</label>
      <input type="date" class="form-control" placeholder="data di nascita" [(ngModel)]="oMember.birthdate">
    </div>
  </div>
  <div class="col-12 col-md-4 mt-3">
    <div class="form-group">
      <label class="mb-1">Livello di gioco (*)</label>
      <select class="form-control" [(ngModel)]="oMember.game_level">
        <option value="0">-- Seleziona Livello di gioco --</option>
        <option *ngFor="let level of prenotatennisService.settingsData.player_levels" [value]="level.id">{{level.description}}</option>
      </select>
    </div>
  </div>

  <div class="col-12 mt-3 mb-3">
    <div class="p-3 border bg-light">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="whatsappConfirm" [(ngModel)]="oMember.whatsapp">
        <label class="form-check-label" for="whatsappConfirm">
          Acconsente agli altri giocatori di inviare messaggi WhatsApp
        </label>
      </div>
      <div class="small mt-2">
        Attivando questa opzione acconsenti che altri giocatori del circolo possano contattarti mediante WhatsApp.
      </div>
    </div>
  </div>

  <div class="col-12 col-lg-6 mt-3" *ngIf="edit_type == 'profile'">
    <div class="alert alert-primary">
      <div><strong>Nome utente:</strong> {{oMember.username}}</div>
      <div>
        <strong>Nickname:</strong>
        <input type="text" class="form-control" [(ngModel)]="oMember.nickname">
      </div>
      <div><strong>Codice socio:</strong> {{oMember.member_code}} <span *ngIf="!oMember.member_code">n.a.</span></div>
      <div *ngIf="prenotatennisService.settingsData.version_level == 'delux'">
        <strong>Crediti:</strong> <span *ngIf="prenotatennisService.userData.level !== 'admin'"> {{oMember.credits | number: '1.2-2'}}</span>
        <input type="text" class="form-control" *ngIf="prenotatennisService.userData.level == 'admin'" [(ngModel)]="oMember.credits">
      </div>
      <div *ngIf="prenotatennisService.settingsData.check_member_valid"><strong *ngIf="oMember.member_expiry">Scadenza tessera:</strong> {{oMember.member_expiry | date: 'dd/MM/yyyy'}}</div>
      <div *ngIf="prenotatennisService.settingsData.check_cert_med"><strong *ngIf="oMember.medical_expiry">Scadenza cert. medico:</strong> {{oMember.medical_expiry | date: 'dd/MM/yyyy'}}</div>
      <hr />
      <div><strong>Data registrazione:</strong> {{oMember.data_ins | date: 'dd/MM/yyyy HH:mm:ss'}}</div>
      <div><strong>Data ultima mod.:</strong> {{oMember.data_last_mod | date: 'dd/MM/yyyy HH:mm:ss'}}</div>
    </div>
  </div>
  <div class="col-12 col-lg-6 mt-3" *ngIf="edit_type == 'gest'">
    <div class="alert alert-primary">
      <div>
        <label>Nome utente: (*)</label>
        <input type="text" class="form-control" placeholder="nome utente / login" [(ngModel)]="oMember.username">
      </div>
      <div>
        <label>Nickname:</label>
        <input type="text" class="form-control" placeholder="soprannome" [(ngModel)]="oMember.nickname">
      </div>
      <div>
        <label>Codice socio:</label>
        <input type="text" class="form-control" placeholder="Codice tessera" [(ngModel)]="oMember.member_code">
      </div>
      <div *ngIf="prenotatennisService.settingsData.check_member_valid">
        <label>Scadenza tessera: (*)</label>
        <input type="date" class="form-control" placeholder="Codice tessera" [(ngModel)]="oMember.member_expiry">
      </div>
      <div *ngIf="prenotatennisService.settingsData.check_cert_med">
        <label>Scadenza cert. medico:</label>
        <input type="date" class="form-control" placeholder="Codice tessera" [(ngModel)]="oMember.medical_expiry">
      </div>
      <div *ngIf="prenotatennisService.settingsData.version_level == 'delux'">
        <label>Crediti:</label>
        <input type="number" class="form-control" [(ngModel)]="oMember.credits">
      </div>
      <div>
        <label>Status:</label>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="usrStatusview" [(ngModel)]="oMember.status">
          <label class="form-check-label" for="usrStatusview">
            Attiva il profilo socio
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6 mt-3" *ngIf="oMember.id !== 0">

    <div class="mb-4">
      <label>Immagine Profilo</label>
      <div class="d-flex align-items-center ">
        <div class="prev-prof-img"  [ngStyle]="{'background-image' : 'url(data:image/jpeg;base64,'+ oMember.profile_image +')'}"></div>
        <div>
          <input type="file" class="form-control" id="" (change)="picked($event)">
        </div>
      </div>
    </div>

    <h4>Cambio password</h4>
    <input type="password" class="form-control mt-2" placeholder="Nuova password" [(ngModel)]="sNewPsw">
    <input type="password" class="form-control mt-4" placeholder="Ripeti nuova password" [(ngModel)]="sReplyNewPsw">
    <!--<password-strength-meter [password]="sNewPsw"></password-strength-meter>-->
    <button class="btn btn-success mt-4" (click)="changePassword()" [disabled]="sNewPsw !== sReplyNewPsw || sNewPsw == ''">CAMBIA PASSWORD</button>
  </div>

</div>

</div>

<div class="modal-footer">
  <button class="btn btn-danger me-4" (click)="deleteUser()" *ngIf="prenotatennisService.userData.level == 'admin' && prenotatennisService.userData.id !== id_user">Elimina Utente</button>
  <button class="btn btn-success" [disabled]="checkUserConsistency()" (click)="saveProfile()">Salva</button>
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('')">CHIUDI</button>
</div>
