
<div class="row mt-3 mb-4 pb-5">
  <div class="col-12 col-md-6 pointer mt-2 text-center">
    <div class="p-1 mb-3 text-center border rounded" (click)="editClosure()">
      <i class="fa fa-lock fa-3x mt-3"></i>
      <h5 class="mt-3">Chiusure</h5>
      <p>
        Chiusure del centro sportivo e indisponibilità segreteria
      </p>
    </div>
  </div>
  <div class="col-12 col-md-6 pointer mt-2 text-center">
    <div class="p-1 mb-3 text-center border rounded" (click)="editSeasons()">
      <i class="fa fa-cloud fa-3x mt-3"></i>
      <h5 class="mt-3">Stagioni</h5>
      <p>
        Definizione date della stagione invernale ed estiva
      </p>
    </div>
  </div>
  <div class="col-12 col-md-6 pointer mt-2 text-center">

    <div class="p-1 mb-3 text-center border rounded" (click)="editConstraints()">
      <i class="fa fa-gear fa-3x mt-3"></i>
      <h5 class="mt-3">Vincoli</h5>
      <p>
        Imposta i parametri dei vincoli sulle prenotazioni
      </p>
    </div>
  </div>
  <div class="col-12 col-md-6 pointer mt-2 text-center">

    <div class="p-1 mb-3 text-center border rounded" (click)="showConstraints()">
      <i class="fa fa-sliders fa-3x mt-3"></i>
      <h5 class="mt-3">Controlli</h5>
      <p>
        Visualizza i controlli di funzionalità attivi sulla piattaforma
      </p>
    </div>
  </div>
</div>

<div class="pb-5">
  <!-- Space for menu bug fix -->
</div>
