import { Component, OnInit } from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SettingsClosureComponent} from "../../components/settings-closure/settings-closure.component";
import {SettingsSeasonComponent} from "../../components/settings-season/settings-season.component";
import {SettingsConstraintsComponent} from "../../components/settings-constraints/settings-constraints.component";
import {
  SettingsActiveConstraintsComponent
} from "../../components/settings-active-constraints/settings-active-constraints.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              private router: Router,) { }

  ngOnInit(): void {
    if(this.prenotatennisService.userData.id == 0) this.router.navigate(['home']);
  }

  editClosure() {
    const modalRef = this.modalService.open(SettingsClosureComponent, { size: 'xl' });
    modalRef.componentInstance.saveAnswer.subscribe((confirmation: boolean) => {

      if(confirmation) {
        modalRef.close();
      }

    });

  }

  editSeasons() {
    const modalRef = this.modalService.open(SettingsSeasonComponent, { size: 'xl' });
    modalRef.componentInstance.saveAnswer.subscribe((confirmation: boolean) => {

      if(confirmation) {
        modalRef.close();
      }

    });
  }

  editConstraints() {

    const modalRef = this.modalService.open(SettingsConstraintsComponent, { size: 'xl' });
    modalRef.componentInstance.saveAnswer.subscribe((confirmation: boolean) => {
      if(confirmation) {
        modalRef.close();
      }
    });

  }

  showConstraints() {

    const modalRef = this.modalService.open(SettingsActiveConstraintsComponent, { size: 'xl' });
    modalRef.componentInstance.saveAnswer.subscribe((confirmation: boolean) => {
      if(confirmation) {
        modalRef.close();
      }
    });

  }

}
