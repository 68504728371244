import { Component, OnInit } from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {bookingData} from "../../interfaces/interfaces";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-booking-list',
  templateUrl: './booking-list.component.html',
  styleUrls: ['./booking-list.component.scss']
})
export class BookingListComponent implements OnInit {

  public bLoadingData: boolean = false;

  public filter: any = {
    code: ''
  }

  public aBookingList: Array<bookingData> = [];

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              private router: Router) { }

  ngOnInit(): void {
    if(this.prenotatennisService.userData && this.prenotatennisService.userData.id == 0) this.router.navigate(['home']);
    if(this.prenotatennisService.userData && this.prenotatennisService.userData.id !== 0) this.getBookingListData();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getBookingListData() {

    this.bLoadingData = true;
    let from: Date = new Date(this.prenotatennisService.memberFilterBooking.date_from);
    let to: Date = new Date(this.prenotatennisService.memberFilterBooking.date_to);

    if(to < from){
      to.setDate(from.getDate() + 30);
      this.prenotatennisService.memberFilterBooking.date_to = to.toISOString().slice(0, 10);
    }

    this.prenotatennisService.getBookingListDB(from, to, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.booking && get_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i dati del campo' + ': ' + get_response.booking.error);
          this.aBookingList = [];
        } else {
          this.aBookingList = get_response.booking;

          this.aBookingList.map(book => {
            if(Number(book.id_user) !== Number(this.prenotatennisService.userData.id)) book.id_guest = this.prenotatennisService.userData.id;
            return book;
          })

        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        this.aBookingList = [];
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  getSportInfo(id_sport: number) {

    return this.prenotatennisService.settingsData.sports.filter((x: any) => x.id == id_sport)[0].icon;
  }

  gotoPayments(book: bookingData) {
    this.prenotatennisService.oBooking = book;
    this.router.navigate(['/paga-prenotazione', {id: book.id}]);
  }

  gotoBookDetails(book: bookingData){
    this.prenotatennisService.oBookSelected = null;
    this.prenotatennisService.oBookIDSelected = book.id;
    this.router.navigate(['prenotazione']);
  }

  check24h(date: string, start_hour: string) {

    let play_date = new Date(date + "T" + start_hour + ".00");
    let nowDate: Date = new Date();
    let diff = (play_date.getTime() - nowDate.getTime()) / 1000;
    diff /= (60 * 60);

    if(diff > 0){
      if(this.prenotatennisService.settingsData.check_user_delete_booking_24h && diff <= 24) return false;
      return true;
    } else {
      return false;
    };

  }

  checkPayBook(book: bookingData) {

    // Check App Version
    if(this.prenotatennisService.settingsData.version_level !== 'delux') return false;
    // Check Guest user
    if(book.id_guest && book.player_status !== 'CONFIRMED') return false;
    // Check quote payed
    if(book.price == book.quote_payed) return false;
    return true;

  }

  confirmDeleteBooking(id_book: number){

    const modalRef = this.modalService.open(AlertDialogComponent, { size: "xl" });
    modalRef.componentInstance.type = "confirm";
    modalRef.componentInstance.message = "Attenzione, annullando la prenotazione l'ora di gioco tornerà a disposizione per gli altri giocatori. Procedere?";

    modalRef.componentInstance.questionAnswer.subscribe((reciveEntry: boolean) => {
      if(reciveEntry){
        this.deleteBooking(id_book);
      }
    });

  }

  deleteBooking(id_book: number) {

    this.bLoadingData = true;

    this.prenotatennisService.postDeleteBookingDB(id_book, '', this.prenotatennisService.userData.token).subscribe(update_response => {

        if(update_response.booking && update_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile eliminare la prenotazione: ' + update_response.booking.error);
          this.bLoadingData = false;
        } else {
          // All ok, redirect to home
          this.bLoadingData = false;
          this.getBookingListData();
          this.prenotatennisService.getUserLoginData ();
        }

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
      }
    );

  }

  filterList(aBook: Array<bookingData>) {

    if(this.filter.code) return aBook.filter(x => x.code.toLowerCase().indexOf(this.filter.code.toLowerCase()) !== -1);
    return aBook;

  }

}
