<app-loader *ngIf="bLoadingData"></app-loader>

<div class="row border bg-light" *ngIf="bOpenEdit">

  <div class="col-12 text-center">
    <h5>
      <span *ngIf="oPrice.season == 'winter'">INVERNO</span>
      <span *ngIf="oPrice.season == 'summer'">ESTATE</span>
    </h5>
  </div>

  <div class="col-12 col-md-3 mt-2">
    <label>Prezzo ora di gioco (60 min)</label>
    <input type="number" class="form-control" placeholder="20.50" [(ngModel)]="oPrice.price">
  </div>
  <!--<div class="col-12 col-md-3 mt-2">
    <label>Prezzo doppio</label>
    <input type="number" class="form-control" placeholder="20.50" [(ngModel)]="oPrice.price_double">
  </div>-->
  <div class="col-12 col-md-3 mt-2">
    <label>Ora inizio</label>
    <input type="time" class="form-control" [(ngModel)]="oPrice.hour_start">
  </div>
  <div class="col-12 col-md-3 mt-2">
    <label>Ora fine</label>
    <input type="time" class="form-control" [(ngModel)]="oPrice.hour_end">
  </div>
  <div class="col-12 text-center mt-2 mb-3">
    <button class="btn btn-xs btn-danger me-2" (click)="closePrice()">Chiudi</button>
    <button class="btn btn-xs btn-success" (click)="savePrice()">Salva</button>
  </div>

</div>

<div class="row">
  <div class="col-12 col-md-6 mt-3 p-2 border">
    <div class="float-end">
      <button class="btn btn-sm btn-success" (click)="addNewPrice('winter')"><i class="fa fa-plus-circle"></i></button>
    </div>
    <h5>Inverno</h5>
    <div class="small">
      {{prenotatennisService.settingsData?.constraints.winter_start | date: 'dd/MM/yyyy'}} -
      {{prenotatennisService.settingsData.constraints.winter_end | date: 'dd/MM/yyyy'}}
    </div>

    <table class="table table-bordered table-striped table-hover mt-2">
      <thead>
      <tr>
        <th>Orario</th>
        <th>Prezzo (h)</th>
        <!--<th>Prezzo doppio</th>-->
        <th>#</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let priceW of aPriceWinter">
        <td>{{priceW.hour_start}} - {{priceW.hour_end}}</td>
        <td>{{priceW.price}}</td>
        <!--<td>{{priceW.price_double}}</td>-->
        <td>
          <button class="btn btn-info btn-sm me-2" (click)="editPrice(priceW)"><i class="fa fa-pencil"></i></button>
          <button class="btn btn-danger btn-sm" (click)="deletePGPrice(priceW)"><i class="fa fa-trash"></i></button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="col-12 col-md-6 mt-3 p-2 border">
    <div class="float-end">
      <button class="btn btn-sm btn-success" (click)="addNewPrice('summer')"><i class="fa fa-plus-circle"></i></button>
    </div>
    <h5>Estate</h5>
    <div class="small">
      {{prenotatennisService.settingsData.constraints.summer_start | date: 'dd/MM/yyyy'}} -
      {{prenotatennisService.settingsData.constraints.summer_end | date: 'dd/MM/yyyy'}}
    </div>
    <table class="table table-bordered table-striped table-hover mt-2">
      <thead>
        <tr>
          <th>Orario</th>
          <th>Prezzo</th>
          <!--<th>Prezzo doppio</th>-->
          <th>#</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let priceS of aPriceSummer">
          <td>{{priceS.hour_start}} - {{priceS.hour_end}}</td>
          <td>{{priceS.price}}</td>
          <!--<td>{{priceS.price_double}}</td>-->
          <td>
            <button class="btn btn-info btn-sm me-2" (click)="editPrice(priceS)"><i class="fa fa-pencil"></i></button>
            <button class="btn btn-danger btn-sm" (click)="deletePGPrice(priceS)"><i class="fa fa-trash"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


