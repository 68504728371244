import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {bookingAllDayPLaygroundData, bookingMultipleData} from "../../interfaces/interfaces";
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-booking-allday',
  templateUrl: './booking-allday.component.html',
  styleUrls: ['./booking-allday.component.scss']
})
export class BookingAlldayComponent implements OnInit {

  @Input() oAllDayBooking: bookingAllDayPLaygroundData =  this.prenotatennisService.getNewBookingAllDayata();
  @Output() saveAnswer = new EventEmitter<boolean>();

  public bLoadingData: boolean = false;
  public oFilter: any = {
    hour_start: '',
    hour_end: ''
  };

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    console.log(this.oAllDayBooking);
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  runAllDayBooking() {

    this.bLoadingData = true;

    this.prenotatennisService.postInsertAllDayPlaygroundBookingDB(this.oAllDayBooking, this.prenotatennisService.userData.token).subscribe(insert_response => {

        if(insert_response.booking && insert_response.booking.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile inserire la prenotazione multipla: ' + insert_response.booking.error);
        } else {
          this.saveAnswer.emit(true);
          this.activeModal.close();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
      }
    );



  }

}
