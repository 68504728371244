import { Component, OnInit } from '@angular/core';
import {PrenotatennisService} from "../../services/prenotatennis.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {creditBox, rechargeData} from "../../interfaces/interfaces";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-buy-credit',
  templateUrl: './buy-credit.component.html',
  styleUrls: ['./buy-credit.component.scss']
})
export class BuyCreditComponent implements OnInit {

  public bLoadingData: boolean = false;
  public bLoadingRecharge: boolean = false;
  public aCreditBox: Array<creditBox> = [];
  public aRechargeList: Array<rechargeData> = [];
  public iRechargeSum: number = 0;

  constructor(public prenotatennisService: PrenotatennisService, private modalService: NgbModal,
              private router: Router,) { }

  ngOnInit(): void {
    if(this.prenotatennisService.userData && this.prenotatennisService.userData.id == 0) {
      this.router.navigate(['home']);
    } else{
      this.getCreditBoxData();
      this.getRechargeListData();
    }

  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getCreditBoxData() {

    this.bLoadingData = true;

    this.prenotatennisService.getCreditBoxListDB(true, this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.credit_box && get_response.credit_box.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i bx crediti: ' + get_response.credit_box.error);
          this.aCreditBox = [];
        } else {
          this.aCreditBox = get_response.credit_box;
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.aCreditBox = [];
        this.bLoadingData = false;
      });

  }

  getRechargeListData() {

    this.bLoadingRecharge = true;

    this.prenotatennisService.getRechargeListDB( this.prenotatennisService.userData.token).subscribe(get_response => {

        if(get_response.recharge && get_response.recharge.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare la lista delle ricariche: ' + get_response.recharge.error);
          this.aRechargeList = [];
        } else {
          this.aRechargeList = get_response.recharge;

          this.aRechargeList.map(x => {
            x.date_ins = x.date_ins ? x.date_ins.replace(" ", "T"): x.date_ins;
            x.date_upd = x.date_upd ? x.date_upd.replace(" ", "T"): x.date_upd;
            this.iRechargeSum = this.iRechargeSum + x.price;
            return x;
          });

        }

        this.bLoadingRecharge = false;

      },
      error => {
        // API call error
        console.log(error);
        this.aRechargeList = [];
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingRecharge = false;
      });

  }

  sendRequestPayPal(credit_box: creditBox) {

    this.bLoadingData = true;
    let oRecharge: rechargeData = this.prenotatennisService.getNewRechargeData(credit_box.id);
    oRecharge.credit = credit_box.credit;
    oRecharge.price = credit_box.price;

    this.prenotatennisService.postInsertRechargeOrderDB(oRecharge, this.prenotatennisService.userData.token).subscribe(insert_response => {

        if(insert_response.recharge && insert_response.recharge.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile effettuare la ricarica: ' + insert_response.recharge.error);
          this.bLoadingData = false;
        } else {
          this.bLoadingData = false;
          window.location.href= this.prenotatennisService.settingsData.api_url + "send_paypal_form.php?code=" + insert_response.recharge.code + "&id=" + this.prenotatennisService.userData.id;
        }

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );



    //window.location.href= this.prenotatennisService.settingsData.api_url + "send_paypal_form.php?code=" + credit_box + "&id=" + this.prenotatennisService.userData.id;
    //
  }

}
