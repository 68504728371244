<app-loader *ngIf="bLoadingData"></app-loader>

<div class="row mt-2 mb-3">
  <div class="col-12 text-center">
    <i class="fa fa-3x fa-check-circle text-success"></i>
  </div>
  <div class="col-12 mt-3 text-center">
    <div><label>CODICE PRENOTAZIONE: {{oBooking.code}}</label></div>
    <div><label>TOTALE PRENOTAZIONE</label></div>
    <h4 class="text-success">{{playgroundCost?.book_total_to_pay | number: '1.2-2'}} Euro</h4>
    <div class="small text-muted mt-2">
      {{oBooking.date_play | date: 'dd/MM/yyyy'}} dalle {{oBooking.hour_start | timeFormat}} alle {{oBooking.hour_end | timeFormat}}
      <br />
      {{oBooking.playground_name}} - {{oSport?.description}}
    </div>
  </div>

  <section id="paymentInfo">

    <div class="col-12 mt-3 text-center" *ngIf="aPayment.length > 0">
      <label>Pagamenti effettuati</label>
      <table class="table table-striped table-bordered mt-2">
        <thead>
        <tr>
          <th>Chi</th>
          <th>Quota</th>
          <th *ngIf="prenotatennisService.userData.level == 'admin'">#</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let payment of aPayment">
          <td>{{payment.usr_surname}} {{payment.usr_name}}</td>
          <td>
            {{payment.quote | number: '1.2-2' }} Euro
            <div class="small muted">
              <span *ngIf="payment.payment_method == 'CASH'">Contanti in segreteria</span>
              <span *ngIf="payment.payment_method == 'CREDITS'">Crediti</span>
            </div>
          </td>
          <td *ngIf="prenotatennisService.userData.level == 'admin'">
            <button class="btn btn-sm btn-danger" (click)="deletePayment(payment)"><i class="fa fa-trash"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="col-12 mt-3 text-center" *ngIf="aPayment.length > 0">
      <label>TOTALE PAGATO</label>
      <h4 class="text-success">{{quota_payed | number: '1.2-2'}} Euro</h4>
    </div>

    <div class="col-12 mt-3 text-center">
      <label>TOTALE DA PAGARE</label>
      <h4 class="text-success">{{quota_to_pay | number: '1.2-2'}} Euro</h4>

      <div class="alert alert-danger mt-3" *ngIf="prenotatennisService.userData.credits == 0">
        <h4>Attenzione</h4>
        Non hai abbastanza crediti a disposizione per effettuare pagamenti.
      </div>

    </div>

  </section>

  <div class="col-12 mt-3 text-center" *ngIf="prenotatennisService.userData.credits > 0">
    <!--<button class="btn btn-lg btn-success mt-2 me-2" [disabled]="prenotatennisService.userData.credits < playgroundCost?.book_total_to_pay" *ngIf="quota_to_pay >= playgroundCost?.book_total_to_pay" (click)="insertPayment(playgroundCost.book_total_to_pay)">
      PAGA {{playgroundCost?.book_total_to_pay | number: '1.2-2'}} €
      <div class="small text-muted">Tutta la quota</div>
    </button>-->
    <button class="btn btn-lg btn-success mt-2 me-2" [disabled]="prenotatennisService.userData.credits < quota_to_pay" *ngIf="quota_to_pay > 0" (click)="insertPayment(quota_to_pay)">
      PAGA {{quota_to_pay | number: '1.2-2'}} €
      <div class="smaller_txt">Salda</div>
    </button>
    <button class="btn btn-lg btn-success mt-2 me-2" [disabled]="prenotatennisService.userData.credits < quota_min_player_nr" *ngIf="oSport?.player_min_nr > 0 && quota_to_pay >= quota_min_player_nr" (click)="insertPayment(quota_min_player_nr)">
      PAGA {{quota_min_player_nr | number: '1.2-2'}} €
      <div class="smaller_txt">{{oSport.player_min_nr}} giocatori</div>
    </button>
    <button class="btn btn-lg btn-success mt-2 me-2" [disabled]="prenotatennisService.userData.credits < quota_max_player_nr" *ngIf="oSport?.player_min_nr !== oSport?.player_nr && quota_to_pay >= quota_max_player_nr" (click)="insertPayment(quota_max_player_nr)">
      PAGA {{quota_max_player_nr | number: '1.2-2'}} €
      <div class="smaller_txt">{{oSport.player_nr}} giocatori</div>
    </button>

    <section id="adminAreaForPayment" *ngIf="prenotatennisService.userData.level == 'admin'">
      <hr />
      <h4>Gestione dei pagamenti - segreteria</h4>

      <div class="row mt-3 mb-3">

        <div class="col-12 mt-3 mb-3 alert alert-warning">
          <strong>Attenzione: </strong> i pagamento effettuati in contanti se eliminati verranno poi
          rimborsati sottoforma di crediti in automatico.
        </div>

        <div class="col-6 col-md-3">
          <label>Giocatori</label>
          <select class="form-control" [(ngModel)]="player_selected">
            <option [value]="0">-- Chi paga la quota? --</option>
            <option [value]="prenotatennisService.userData.id">{{prenotatennisService.userData.surname}} {{prenotatennisService.userData.name}}</option>
            <option *ngFor="let player of aBookingPlayers" [value]="player.id_user">
              {{player.player_surname}} {{player.player_name}} [{{player.credits}}]
            </option>
          </select>
        </div>
        <div class="col-6 col-md-3">
          <label>Metodo</label>
          <select class="form-control" [(ngModel)]="player_method">
            <option [value]="'CASH'">Contanti</option>
            <option [value]="'CREDITS'">Crediti</option>
          </select>
        </div>
        <div class="col-6 col-md-3">
          <label>Quota che versa</label>
          <input type="number" class="form-control" [(ngModel)]="player_quote">
        </div>
        <div class="col-6 col-md-3">
          <label>Inserisci</label><br />
          <button class="btn btn-sm btn-primary" (click)="createAdminPayment()">PAGA</button>
        </div>
      </div>
      <hr />
    </section>

    <button class="btn btn-lg btn-primary mt-2 me-2" (click)="closePayment()">CHIUDI</button>
  </div>

</div>
